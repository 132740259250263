.site-footer {
	@extend.section-fullwidth.text-white;

	background: $color-text;
	padding-bottom: 40px;

	a {
		color: white;
	}

	.widget {
		&-title {
			margin-bottom: 1.5em;
		}

		&-second-title {
			margin-bottom: 8px;
		}
	}

	.app-buttons a {
		padding: 0 5px;

		img {
			width: 120px;
		}
	}

	.linked-in-icon {
		width: 24px;
	}

	@media (max-width: $breakpoint-small) {
		text-align: center;
		font-size: 16px;

		.widget {
			margin-bottom: 80px;

			.list-item {
				justify-content: center;
			}

			.list-minimal-icons {
				max-width: 200px;
				margin: 0 auto;

				.list-item {
					justify-content: flex-start;
					text-align: left;
				}
			}
		}
	}
}

.site-copyright {
	margin-top: 120px;
	font-size: 16px;

	span {
		display: inline-block;
		padding: 0 20px;
		border-right: 1px solid $color-border;
		color: $color-border;
		line-height: 1.1;

		&:last-child {
			border-right: 0;
		}
	}

	@media (max-width: $breakpoint-small) {
		margin-top: 40px;
		font-size: 12px;
		color: #777879;
	}
}