/*body {*/
  /*margin: 0;*/
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
    /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
    /*sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",*/
    /*monospace;*/
/*}*/

.features-overlay {
    width: 100%;
    height: 40%;
    position: fixed;
    z-index: 1000;
    background-color:  hsl(0deg 2% 63% / 80%);
    bottom: 0;
    padding: 20px;
    color: #FFFFFF;
}

.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .5);
    z-index: 10;
    overflow: hidden;
}

.spinner {
    width: 60px;
    height: 60px;
    border: 9px solid #F5BA16;
    border-top: 9px solid #fff;
    border-radius: 50%;
    animation: spin 0.9s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.popup.route-failed-popup .popup-box {
    padding: 40px 70px !important;
}

.popup.route-failed-popup .popup-box .popup-title {
    margin-bottom: 0 !important;
}
