%input-group-title {
	line-height: 1.25;
	color: $color-text;
	display: block;
	margin-bottom: 15px;
	font-weight: bold;
	text-align: left;
}

.captcha-group{
	font-size: 20px;
	min-height: 95px;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	flex-basis: 290px;
	margin:42px 16px 0px;
}
// use this everywhere to wrap labels with input fields
.input-group {
	font-size: 20px;
	min-height: 95px;
	display: flex;
	justify-content: flex-end;	
	flex-direction: column;
	flex-grow: 1;
	flex-basis: 290px;

	label,
	.input-group-title {
		@extend %input-group-title;		
	}

	input,
	select,
	textarea {
		border-radius: 5px;
	}

	input,
	select,
	textarea,
	&-radio {
		line-height: 1.3;
		padding: .5em 3em .5em 2em;
		border: 1px solid $color-border;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		background: white;
		width: 100%;
		outline: none;
		font-size: 20px;
		height: 65px; // Equzlie the date type with the rest

		&:hover {
			border-color: darken($color-border, 10%);
		}
	}

	textarea {
		min-height: 150px;
		padding: 1em 2em;
	}

	input[type="button"],
	input[type="submit"] {
		// Equalize left/right compared to input fields
		padding-left: 2em;
		padding-right: 2em;
	}

	// input[type="date"]
	.input-date {
		position: relative;
		input {
			background: url(../images/icon-input-date.svg) no-repeat center right 1em;
			background-size: 1.5em;
			padding-right: 2.25em;

			&::-webkit-inner-spin-button,
			&::-webkit-calendar-picker-indicator {
				display: none;
				-webkit-appearance: none;
			}
		}

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			/* IE10+ CSS styles */
			line-height: 0;
		}

		& > div {
			position: absolute;
			background: white;
			border: 1px solid #CACACA;
			margin-top: 2px;
		}
	}

	input[type="radio"] {
		width: 1em;
		height: 1em;
		border-radius: 50%;
		border: 1px solid $color-text;
		padding: 0;
	}

	input[type="checkbox"] {
		display: none;

		& + label {
			width: 1em;
			height: 1em;
			border-radius: 2px;
			border: 1px solid $color-border;
			padding: 0;	
		}

		& + label.show-invalid {
			border: 1px solid red;
		}

		&:checked + label {
			background: $color-primary url(../images/checkbox-small.svg) no-repeat center center;
			background-size: .6em;
			border-color: $color-primary;
		}
	}

	input[type="button"] {
		background: $color-primary;
	}

	select {
		background: white url(../images/arrow_down.svg) no-repeat center right 2em;
	}

	&-description {
		font-size: 16px;
		color: $color-text-muted;
		margin-top: .25em;
	}

	&-checkbox {
		display: flex;
		margin-bottom: 5px;

		input[type="checkbox"] + label {
			flex: 0 0 1em;
			margin-right: 20px;
			margin-top: 3px;
			cursor: pointer;
		}

		span,
		label {
			font-size: 18px;
			font-weight: normal;
		}
	}

	&-toggle {
		display: flex;
		align-items: center;

		label {
			font-weight: normal;
			margin: 0;
		}

		input[type="checkbox"] + label {
			margin: 3px 20px 0 0;
			cursor: pointer;

			border-radius: 100px;
			width: 50px;
			height: 28px;
			position: relative;
			transition: .3s;

			&:before {
				position: absolute;
				content: '';
				display: block;
				height: 20px;
				width: 20px;
				border-radius: 50%;
				top: 3px;
				left: 3px;
				background: $color-border;
				transition: .3s;
			}
		}

		input[type="checkbox"]:checked + label {
			transition: .3s;
			background: $color-primary;
			border-color: $color-primary;

			&:before {
				transform: translateX(22px);
				transition: .3s;
				background: white;
			}
		}
	}

	&-rememberme {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
	}

	&-radio {
		padding: .25em 2em; // needed due to the images
		display: flex;
		align-items: center;
		cursor: pointer;
		border-radius: 5px;
		margin-bottom: .75em;

		&:last-child {
			margin-bottom: 0;
		}

		input[type="radio"] {
			margin-right: 1em;
			flex-shrink: 0;

			&:checked {
				background: $color-primary;
				box-shadow: inset 0 0 0 4px white;
			}
		}

		&-label {
			flex-grow: 1;
		}
	}

	// Regular input fields need fixed height to look well when next to each other,
	// but this is not needed for the rest.
	&-auto {
		min-height: auto;
	}

	@media (max-width: $breakpoint-medium) {
		$forms-font-size: 14px;

		margin-bottom: 25px;
		min-height: 0;
		font-size: $forms-font-size;

		&:last-child {
			margin-bottom: 0;
		}

		input,
		select,
		textarea {
			height: 45px;
			padding: .5em 1em;
			font-size: $forms-font-size;
		}

		textarea {
			height: auto;
			padding: 1em
		}

		select {
			background-size: $forms-font-size;
			background-position: center right 1em;
		}

		label {
			text-align: center;
		}

		.button {
			font-size: 16px;
		}

		span,
		label {
			font-size: $forms-font-size;
		}

		&-checkbox {
			span,
			label {
				text-align: left;
			}

			input[type=checkbox] + label {
				margin-right: .5em;
			}
		}

		&-radio {
			font-size: $forms-font-size;
			height: 45px;
			padding: .5em 1em;
			margin-bottom: 1.5em;

			img {
				max-width: 45px;
			}
		}

		& &-title {
			font-size: 18px;
			text-align: center;
		}
	}
}

.box-form {
	div.form-error-msg {
		color: red;
		font-size: 30px;
		margin: 20px;
	}	
}

.form-inline {
	max-width: 1300px;
	margin: 0 auto;
	display: flex;
	width: 100%;

	&-2 {
		.input-group {
			flex-basis: 50%;
		}
	}

	&-3 {
		.input-group {
			flex-basis: calc(100% / 3);
		}
	}

	.input-group {

		&-submit {
			flex-grow: 0;

			input[type="button"],
			input[type="submit"] {
				border: none;
			}
		}

		@media (min-width: $breakpoint-medium) {
			select,
			input {
				border-radius: 0;
				border-right: none;
			}

			&:first-of-type {
				input,
				select {
					@media (min-width: $breakpoint-medium) {
						border-top-left-radius: 5px;
						border-bottom-left-radius: 5px;
					}
				}
			}

			&:last-of-type {
				input,
				select {
					border-right: none;

					@media (min-width: $breakpoint-medium) {
						border-top-right-radius: 5px;
						border-bottom-right-radius: 5px;
					}
				}
			}
		}
	}

	input[type="button"] {
		@media (min-width: $breakpoint-medium) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	// As per design, on another page of the multi-step form, there is 
	// single-line input fields for checking car number plate which is
	// very similar to the .form-inline from the homepage
	&-border {
		.input-group {
			input,
			select {
				border: 1px solid $color-border;
				border-right: none;
			}

			&:last-child input,
			&:last-child select {
				border-right: 1px solid $color-border;
			}
		}
	}

	&-full-border {
		.input-group {
			input,
			select {
				border: 1px solid $color-border;
			}

			&:last-child input,
			&:last-child select {
				border-right: 1px solid $color-border;
			}
		}
	}

	@media (max-width: $breakpoint-medium) {
		display: block;

		&-border {
			.input-group {
				input,
				select {
					border: 1px solid $color-border;
				}
			}
		}
	}
}
.delictPayment {
	input[type="text"],
	select#countryCode,
	input[type="button"],
	button[type="submit"] {
		font-size: 24px;
		height: 80px;
	}
	button[disabled] {
		opacity: $button-opacity-disabled;
		cursor: not-allowed;

		&:hover, &:focus {
			background-color: $color-primary;
			color: $color-white;
		}

	}

}

.form-validity {
	input[type="text"],
	select#countryCode,
	input[type="button"],
	button[type="submit"] {
		font-size: 24px;
		height: 80px;
	}
	button[disabled] {
		opacity: $button-opacity-disabled;
		cursor: not-allowed;

		&:hover, &:focus {
			background-color: $color-primary;
			color: $color-white;
		}

	}
	@media (min-width: $breakpoint-small) {
		input[type="text"],
		select#countryCode,
		input[type="button"],
		button[type="submit"] {
			font-size: 24px;
			height: 80px;
		}
	}

	@media (max-width: $breakpoint-small) {
		flex-direction: column;
	}
}

.payroll-datepicker {
	flex-basis: 33.3%;

	.input-date {
		height: 38px !important;
	}
	.input-date::placeholder {
		color: hsl(0,0%,50%);
	}

	& + div.input-group {
		.multi-select-input {
			margin-bottom: 2px;
		}
	}
}

.payroll-datepicker.trialBalance {
	flex-basis: 15%;
	.input-date {
		height: 36px !important;
	}
}

.trialBalance {
	flex-basis: 15%;
}

.reports-customers {

	.react-select__control {
		overflow: auto;
		height: fit-content;
		max-height: 70px;

		.react-select__value-container--has-value {
			height: fit-content;
		}
	}
}

.multiselect-style {
	display: flex;
	flex-basis: 33%;
	flex-grow: 1;

	select {
		height: 38px !important;
	}
}

.report-fields {
	display: flex;
	flex: 1;
	height: 65px;

	& .input-group-checkbox {
		flex-basis: 33%;
		align-items: flex-end;

		.report-checkbox {
			margin-bottom: 5px;
			margin-right: 5px;
	
			& + label {
				display: none;
			}

			& ~ label {
				line-height: initial;
			}
		}
	}

	& .input-group {
		flex-basis: 67%;

		input {
			border-radius: 4px;

			&::placeholder {
				color: $color-text-muted;
			}
		}
	}
}

.currentTime-checkbox {
	margin-top: 10px;

	& ~ label {
		margin-top: 5px;
	}
}
