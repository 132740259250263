// This is for styles specific to ERP app

$erp-row-max-width: 2000px;
$input-height: 36px;

#root {
  height: 100%;
}

.react-datepicker-popper {
  z-index: 100 !important;
  box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.7);
  &>div {
    display: flex;
  }
}

.multi-select-input.searchable .react-select__input {
  margin-top: -10px;
  height: 24px;
}

.css-62g3xt-dummyInput{
  padding: 4px !important;
  height: 1px !important;
}

.css-1hwfws3 {
  background-color: white;
  border-radius: 5px;
}

.css-1g48xl4-IndicatorsContainer{
  background-color: white !important;
  border-radius: 5px;
}

.select2-input-sizes{
  text-align: left;
  font-size: 16px;
}

.multi-select-input {
  height: $input-height;
  
  .react-select__control {
    height: $input-height;
    border-color: $color-border;
    box-shadow: none;
    font-size: 16px;

    .react-select__value-container {
      overflow: inherit;
      height: $input-height;

      .react-select__multi-value {
        margin-top: 3px;
      }
    }
  }

  .react-select__menu {
    z-index: 10;
  }

  .react-select__menu .react-select__option {
    padding: 0 10px;
    height: 24px;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
  }
}

.no-rounded-borders {
  flex-basis: 33%;

  .multi-select-input {

    .react-select__control {
      background: white url(/static/media/arrow_down.b78451a6.svg) no-repeat center right 8px;
      min-height: 36px;
      padding-right: 36px;
      margin-top: 2px;
      border-radius: 0;
      border-right: 0;
    }

    .react-select__indicators {
      display: none;
    }
  }
}

.no-rounded-borders-last {
  .multi-select-input {
    .react-select__control {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid #B3B3B3;
    }
  }
}

.units-multiselect {
  display:flex;
  flex-flow:row;
  align-items: stretch;

  .react-select__control {
    flex: 1 auto;
    align-items: stretch;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 5px;
  }
  
  .react-select__value-container {
    padding: .5em 3em .5em 2em;
    border-radius: 5px;
    outline: none;
  
    .react-select__input input {
      height: initial;
    }
  }

  .react-select__control--is-focused,
  .react-select__control--is-focused:hover {
    border: 1px solid $color-border;
    box-shadow: none;
  }
}

div.app-erp {
  height: 100%;

  .grid-title-standalone{
    text-align: center;
    font-size: 22px;
    background-color: $color-white;
    padding-bottom: 4px;
  }

  .split-layout {
    .field-container {
      padding: 0 20px; 

      .custom-button {
        position: relative;
        padding: 0;
        height: 36px;

        .icon {
          position: absolute;
          left: 10px;
          color: $color-white;
        }

        img {
          width: 28px;
          right: 4px;
          position: absolute;
          top: 7px;
        }
      }
    }
    .field-container.reports {
      @media (max-width: $breakpoint-medium) {
        overflow-x: auto;
        height: 150px;
      }
    }
    .form-inline {
      max-width: 4000px;
    }
  }

  .multistep-picker {
    margin-top: 0;
    padding-top: 120px;
  }

  .row {
    max-width: $erp-row-max-width;
  }

  .site-content {
    height: 100%;

    .react-split {
      height: 100%;
      padding: 0 20px 20px 20px;
    }
  }

  .bank-file-upload {
    width: fit-content;
    margin: 50px auto;
    text-align: center;
  }

  .nosplit {
    padding: 0 20px 20px 20px;
  }

  .gutter {
    background-color: $color-primary;
    cursor: row-resize;
  }

  .site-header.headroom + .site-content {
    margin-top: 0;
    padding-top: 70px;
  }

  .custom-checkbox {
    label {
      font-size: 16px;
      vertical-align: text-bottom;
    }
    input[type=checkbox] + label {
      height: 22px;
      width: 22px;
    }
  }

  h1.site-logo {
    max-height: 38px;
  }

  .site-header-main {
    padding: 0;

    ul.menu-main {
      vertical-align: bottom;
    }
  }

  .aggregated-reports-filters {
    height: auto;
    overflow-y: hidden;
    overflow: visible !important;

    .button-filters-wrapper {
      position: absolute;
      top: 76px;
      right: 40px;
      background: white;
      border-radius: 5px;

      button {
        padding: 10px 20px;
        width: 200px;
      }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles*/
      .field-container .form-inline {
        margin-top: 2px;
      }
    }
  }
}

.form-file-upload {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 65px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid $color-border;;

  &-hidden {
    display: none;
  }
}

.add-file {
  margin-top: 20px;
  text-align: left;
  font-size: 1em;
  line-height: 20px;
  cursor: pointer;

  &:before {
    padding-right: 5px;
    vertical-align: middle;
    line-height: 20px;
  }
}

.file-input-wrapper {
  position: relative;

  .input-group {
    margin-bottom: 0;
  }

  .remove-file {
    position: absolute;
    top: 30px;
    right: 1px;
  }
}

.file-input-label {
  display: block;
  margin-top: 40px;
  margin-bottom: 2px;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.success-message {
  position: absolute;
  top: 0;
  left: 0;

  .popup-wrapper.is-visible {
    width: 100%;
    height: 100%;

    .popup-box {
      position: absolute;
      top: 100px;
      left: 25%;

      @media (max-width: $breakpoint-medium) {
        left: 0;
      }

      .popup-title {
        padding-right: 40px;
      }
    }
  }
}

.partner-pop-up{
  .popup-box {
    width: 95%;
    height: 95%;
  }
}

.clear-background{
  background: white;
  padding-bottom: 15px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: 34px;
  font-weight: bold;
}

.route-map-popup{
  .popup-box {
    width: 95%;
    height: 95%;
    div.popup-title{
      margin-bottom: 5px!important;
    }
    div.route-map-container{
      height: 100%!important;
    }
    .routes.site-content{
      padding-top: 0!important;
      position: relative;
    }
    .collapse-icon, .icon-bars{
      display: none;
    }
    .menu-info-container{
      margin-bottom: 10px;
    }
    .routes .routes-menu{
      height: 100%;
      overflow: unset;
      .info-data{
        position: absolute;
        top: 0px;
        right: 14px;
      }
      .react-datepicker__close-icon{
        display: none;
      }
      .ReactTable .rt-tbody .rt-td input{
        width: 100%;
      }
      .send-segments-button{
        border: none;
        border-radius: 0;
      }
      .no-bottom-border{
        border-bottom: none;
      }
      .no-margin-bottom{
        margin-bottom: 0;
      }
      .no-margin-top{
        margin-top: 0!important;
      }
      .no-y-padding{
        padding-top: 0;
        padding-bottom: 0;
      }
      .input-group.daterange .custom-checkbox{
        display: inline-block;
        width: 90px;
        height: 25px;
      }
      .input-group.daterange .title{
        width: 200px;
      }
      .input-group.daterange.detailedMap .title{
        width: 300px;
      }
      .input-group.coordinates.detailedMap .title{
        width: 300px;
      }
      .input-group.coordinates, .input-group.daterange, .input-group.segment-search{
        display: block;
        text-align: left;
        min-height: 50px;
        margin-bottom: 15px;
        border-bottom: 1px solid lightgray;
        label{
          width: calc(100% - 410px);
          display: inline-block;
          padding-left: 10px;
        }
        .custom-checkbox label{
          height: 22px;
          width: 22px;
          margin-bottom: 0;
        }
        .custom-checkbox .custom-checkbox-label{
          margin-bottom: -3px;
          margin-left: 0;
        }
        input{
          height: 36px;
          width: 170px;
          padding: 5px 10px;
          margin-right: 10px;
          font-size: 19px;
        }
      }
      .input-group.coordinates.detailedMap, .input-group.daterange.detailedMap, .input-group.segment-search.detailedMap{
        border-bottom: none;
      }
      .segments-table .rt-td{
        height: 43px;
        line-height: 43px;
      }
      .date-picker-container .input-group{
        width: 223px;
      }
    }
    .menu-toll-charges{
      height: calc(100% - 166px);
    }
    .menu-toll-charges.small{
      height: calc(100% - 194px);
      .toggle-tabs .tabs-header-item{
        font-size: 17px;
        height: 45px;
      }
      .toggle-tabs{
        height: 100%;
        .tabs-main{
          height: calc(100% - 64px);
          .tabs-item{
            height: 100%;
            padding: 0
          }
        }
      }
    }
    .mapDetailsText{
      font-size: 20px;
    }
    .map-info-panel-charge-object{
      padding: 8px;
      margin-bottom: 3px;
      border: 1px solid lightgray;
      border-radius: 9px;
    }
    .map-info-panel-charge-object:hover{
      background-color: lightgray;
    }
    .map-info-panel-charge-object.selected{
      background-color: rgb(230, 230, 230);
    }
    div.toll-popup-title{
      font-weight: bold;
      text-align: center;
      font-size: 15px;
      margin-bottom: 3px;
    }
    .toll-popup-route-segment-name{
      margin-bottom: 10px;
    }
    .menu-toll-charges.small.search-segments{
      height: calc(100% - 260px);
    }
    .vehicle-info{
      text-align: left;
      .from-to-date-picker{
        position: absolute;
        right: -13px;
        top: 32px;
        input{
          width: 160px;
          margin-left: 10px;
        }
        .icon-search{
          font-size: 21px;
          margin-left: 8px;
        }
      }
    }
    .vehicle-info.hidden-legend{
      margin-bottom: 0;
    }
    .route-map{
      height: 100% !important
    }
    .routes-selection.calendar-only{
      padding-top: 50px;
      .input-group{
        margin-left: 110px;
      }
    }
    .map-legend-container{
      padding : 5px 0 0 700px;
    }
    .map-legend-container.detailedMap{
      padding : 5px 0 0 400px;
    }
    .map-legend-container label{
      float: left;
      margin-right: 10px;
      .icon-cloud-download{
        font-size: 30px;
      }
    }
    .map-legend-container .download-button-container{
      float: right;
    }
    .map-loader{
      z-index: 9999;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: white;
      opacity: 0.5;
      vertical-align: middle;
      padding-top: 20%;
    }
    .add-segment-button{
      border: none;
      background-color: transparent;
    }
    .add-segment-button:hover{
      color: #59b7ff;
    }
  }
}
.home-banner{
  width: 100%;
  margin-bottom : 50px;
  margin-top : 50px; 
}
.home-banner.car-section{
  margin-top : 0; 
}
.popup.popup-erp {
  position: absolute;
  
  .popup-wrapper.is-visible .popup-box {
    padding: 20px;
    box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.7);
    max-width: 95%;

    .panel-edit {
      max-width: 100%;
      width: 970px;
      height: 424px;
      overflow: visible;
    }
  }
  &.scrollable {
    .popup-wrapper.is-visible .popup-box {
      max-height: 95%;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

.popup.popup-erp.partner-pop-up {
  .popup-wrapper.is-visible .popup-box {
    .panel-edit.panel-popup.edit-vehicles.tollPopUp{
      width: 100%;
    }
}
}

.popup.popup-erp.partner-pop-up {
  .popup-wrapper.is-visible .popup-box {
    .panel-edit {
      width: 100%;
      height: 550px;
    }
  }
}

.field-container.partner-popup{
  overflow-y: auto;
  height:100%;
}

.popup.popup-erp {
    .popup-wrapper.is-visible .popup-box {
      .panel-edit.panel-popup.edit-vehicles.tollPopUp{
        height: 85%;
      }
  }
}

@media (max-height: 900px) {
  .field-container.partner-popup{
    overflow-y: auto;
    height:100%;
  }

  .popup.popup-erp {
    .popup-wrapper.is-visible .popup-box {
      .panel-edit.panel-popup.edit-vehicles.tollPopUp{
        height: 85%;
      }
    }
  }
}

@media (max-height: 700px) {
  .field-container.partner-popup{
    overflow-y: auto;
    height: 390px;
  }
  .popup.popup-erp {
    .popup-wrapper.is-visible .popup-box {
      .panel-edit.panel-popup.edit-vehicles.tollPopUp{
        height: 450px;
      }
    }
  }
  .popup.popup-erp.partner-pop-up {
    .popup-wrapper.is-visible .popup-box {
      .panel-edit {
        height: 450px !important;
      }
    }
  }
 }


div.app-erp .react-table-filterable, .popup.popup-erp .react-table-filterable{
  height: 100%;

  .header-container-wrapper {
    position: relative;
  }

  .header-container {
    position: absolute;
    z-index: 1;
    white-space: nowrap;
  }

  .custom-buttons .custom-button-holder button {
    height: 26px;
    vertical-align: top;
    font-size: 16px;
    padding: 2px;
    width: 150px;

    img {
      width: 18px;
      height: 18px;
      margin: 2px;
    }
  }
}

div.delict-search-form-erp {
  display: inline-block;
  margin: 0 30px 0 0;
  width: 30%;
}


div.delict-search-form-erp.searchBtn {
  position: relative;
  top: 53px;
  margin: 0 30px 0 0;
  width: 250px;
}

@media (max-width: $breakpoint-medium) {

  div.delict-search-form-erp {
    width: 100%;
    margin: 0px;
  }

  div.delict-search-form-erp.searchBtn {
    position: relative;
    top: 0;
  }
}

div.delict-search-form-erp .input-group {
  font-size: 16px;

  input, select {
    height: 50px;
  }
}

div.app-erp .panel-edit, .popup.popup-erp .panel-edit {
  height: 100%;
  overflow-y: auto;
  background-color: $color-light;

  div.form-error-msg { 
    font-size: 16px;
  }

  div.form-error-msg-red {
    font-size: 16px;
    margin-top: 20px;
    color: red;
  }

  div.form-success-msg {
    font-size: 16px;
    margin-top: 20px;
    color: green;
  }

  .box-form {
    height: 100%;
  }

  .toggle-tabs-content-scroll{
    overflow: auto;
  }

  div.route-select {
    .toggle-tabs {
      .tabs-header-item.is-current:hover {
        background-color: transparent;
      }
  
      .tabs-header-item.is-current {
        background-color: transparent;
        border-bottom: 3px solid $color-primary;
      }

      .tab-content {
        padding-top: 50px;
        background-color: transparent;
        height: 100%;
      }
    }
  }

  .toggle-tabs {
    height: 100%;
    position: relative;

    .tabs-header-item {
      margin-bottom: 0;
      padding-bottom: 0;
      font-size: 18px;

      .tab-error {
        margin-right: 20px;
        font-size: 16px;
        color: red;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 350px;
        display: inline-block;
      }
    }

    .tabs-header-item.hidden-tab {
      display: none;
    }

    .tabs-header-children {
      cursor: default;

      button {
        cursor: pointer;
      }
    }

    .form-inline {
      margin: 0;
      max-width: $erp-row-max-width;

      .obu-return-checkbox{
        margin-top: 5px;

        & ~ label {
          font-weight: bold;
          font-size: 16px;
          white-space: nowrap;
        }
      }
    }

    .tabs-header-item.is-current:hover {
      background-color: $color-light;
    }

    .tabs-header-item.is-current {
      background-color: $color-light;
      border: 0;
    }

    .tabs-header {
      position: absolute;
      width: 100%;
    }

    .tab-content {
      .header-container {
        background-color: $color-light;
      }
      padding-top: 50px;
      background-color: $color-light;
      height: 100%;
    }

    .tabs-header-children {
      margin-right: 0;
      padding-right: 0;
      max-height: 40px;
    }
  }
  
  .field-container .form-inline {
    margin-bottom: 0;

    .input-group {
      min-height: 70px;
      margin-bottom: 0;

      .inside-button {
        position: absolute;
        height: 30px;
        width: 30px;
        right: 3px;
        bottom: 3px;
        .icon {
          margin-left: -4px;
        }
      }

      label {
        font-size: 16px;
        margin-bottom: 2px;
        padding-right: 5px;
      }
      input, select {
        padding: 8px;
        height: $input-height;
        font-size: 16px;
      }

      select {
        padding-right: 36px;
        background: white url(../images/arrow_down.svg) no-repeat center right 8px;
      }

    }

    .textarea {
      margin-top: 10px;

      textarea {
        overflow-y: auto;
        min-height: 100px;
        padding: 8px;
      }
    }

    .warehouse-link {
      color: $color-primary;
      cursor: pointer;
    }
  }
}

div.app-erp .panel-edit.edit-obu-form {
  overflow: unset;
}

div.app-erp .ReactTable, .popup.popup-erp .ReactTable{
  padding-top: 30px;
  height: 100%;
  background-color: $color-white;
  border: none;

  .rt-table .rt-thead.-header .rt-tr .rt-th {
    padding-top: 10px;
    height: 40px;
  }

  .rt-tbody {
    overflow-y: auto;
    overflow-x: hidden;

    .rt-tr-group {
      max-height: 32px;
      border: 0;
    }

    .rt-tr.-even:not(.-padRow) {
      background-color: $color-light;
    }

    .rt-tr.archive-file.-even:not(.-padRow) {
      background-color: $color-archive-dark;
    }

    .rt-tr.archive-file.-odd:not(.-padRow) {
      background-color: $color-archive-light;
    }

    .rt-tr:not(.-padRow) .rt-td {
      border-right: 1px solid rgba(0,0,0,.05);
    }

    .rt-tr.rt-row-selected {
      background-color: $color-primary-hover !important;
    }

    .rt-td {
      height: 26px;
      padding: 2px 8px;
      border: 0;
    }
  }

  .grid-icon {
    padding-top: 0;
  }

  .grid-icon.icon-loader {
    margin-top: 0;
  }
}
.button-center-submit{
  margin-top: 20px;
  margin-bottom: 20px;
  width: 30%;
}

.button-left-submit{
  margin-top: 20px;
  margin-bottom: 20px;
  width: 30%;
  float: left;
}

.button-right-submit{
  margin-top: 20px;
  margin-bottom: 20px;
  width: 30%;
  float: right;
}

.popup-description{
  margin: 60px;
  fontSize: 20px;
}

.popup-heading {
  display: block;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
  margin: 60px;
  font-size: 22px;
}

.map-content, .panel-popup.route-details {
  display: flex;
  padding: 20px;

  div.left-panel {
    width: 450px;
    padding-right: 20px;
    background: #FFF;

    .button-primary{
      width: 180px;
      height: 40px;
      padding: 6px;
      font-size: 20px;
    }

    .route-pass-summary {
      .message-container {
        font-size: 12px;
        border: 2px solid red;
        border-radius: 5px;
        margin-bottom: 20px;
        padding: 5px;

        a {
          font-size: 16px;
          color: red;
        }

        a:hover {
          text-decoration: underline;
        }

      }

      table {
        font-size: 16px;
        th, td {
          padding: 1px 10px;
        }
        td:last-child {
          text-align: right;
        }
      }
      .summary-header {
        font-size: 16px;
        font-weight: bold;
      }
      .print-btn {
        float: right;
        cursor: pointer;
      }
      .button-container {
        text-align: center;
      }
    }
  }

  @media (max-width: $breakpoint-small) {
    padding: 0;

    div.left-panel {
      padding-right: 0;
      .route-pass-summary table td{
        font-size: 12px;
      }
    }
  }

  .leaflet-container {
    flex: 1;
  }
}

.header-category {
  margin-left: 20px;
  font-weight: bold;
}

.height-auto {
  height: auto !important;
  min-height: 424px;
}

.div-upload-obu {
  padding: 1vw 0 1vw ;
  justify-content: center;
  display: flex;
}

.obu-list-wrapper {
  margin-top: 10px;

  &-label {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
  }

  .obu-list {
    display: flex;
    flex-wrap: wrap;

    &-item {
      display: flex;
      padding: 2px 12px 0;
      border: 1px solid $color-border;
      border-radius: 4px;
      background: white;

      .icon-plus {
        padding-left: 5px;
        transform: rotate(45deg);
        font-size: 12px;
        color: $color-border;
        cursor: pointer;
      }
    }
  }
}

.obu-list-number {
  position: relative;

  input {
    border-right: 1px solid $color-border !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }

  .icon-plus-circle {
    position: absolute;
    top: 39px;
    right: 5px;
    cursor: pointer;
  }
}

.account-close-reasons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
}

.cursor-default {
  cursor: default !important;
}

.account-payments-container {
  min-width: 800px;
  max-height: 700px;
  overflow-y: auto;
}

.trial-balance-custom-buttons {
  display: flex;

  .trial-balance-download-icon {
    position: absolute;
    right: 5px;
  }
}

.edit-deliveries {
  .tab-content {
    .input-group {
      .react-datepicker-wrapper {
        & + input {
          margin-top: -2px;
        }
      }
    }
  }
}

.open-shift-link {
  color: $color-white;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.commissioners-checkbox {
  .input-group-checkbox {
    width: inherit;
    margin-top: 10px;

    .show-commissioners-checkbox {
      margin-top: 5px;

      & + label {
        margin-right: 0;
      }
    }
  }
}

.payment-title{
  margin: 60px;
  font-size: 20px;
}

.show-commissioners-percentages {
  width: 50%;
  height: 90px;
  overflow-y: auto;

  .input-group {
    display: initial;
  }

  .form-inline {
    height: 45px !important;
  }

  .commissioner-name {
    flex-basis: 50%;
    text-align: left;
  }

  .commissioner-percentage {
    width: 50px;
    border-radius: 0 !important;
  }
}

.route-pass-container{
  width: 100%;
  margin-top: 20px;
}

div.delictPaymentText {
  text-align: center;
  padding: 10px;
  font-weight: 600;
  font-size: 20px;
}

div.delictPaymentBtn {
  display: inline-block;

  button.custom-button {
    width: 180px;
    height: 45px;
    font-size: 16px;
  }
}

button.nextToTextField{
  height: 35px;
  margin-top: 35px;
  padding-top: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-inline .input-group:first-of-type input.generate-traker-field{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

div.mass-mailing {
  padding: 0 15px;
  .panel-edit{
    padding: 0;
    overflow: visible;
    .field-container{
      padding: 0 10px;
    }
  }
  .heading-title{
    text-align: center;
  }
  .select-all-checkbox .custom-checkbox{
    display: inline;
  }
  .form-inline{
    max-width: none;
  }
  div.react-table-filterable{
    height: calc(100vh - 400px);
  }
  button .icon.icon-search{ 
    color: white;
    font-size: 17px;
  }
  button .icon.icon-pencil-square-o{
    color: white;
    font-size: 20px;
  }
  .send-mail-button{
    float: right;
  }
}
.popup-mail-completion .popup-box{
  width: 700px;
  .popup-title{
    width: 600px;
    margin: 0 auto 25px;
  }
  .completion-message{
    margin-bottom: 40px;
  }
}

.popup.popup-erp .popup-wrapper.is-visible .popup-box div.mass-mailing-popup{
  width: 100%;
  height: calc(100% - 70px);
  .custom-button span{
    color: white;
    font-size: 23px;
    margin-right: 3px;
    position: relative;
    top: 4px;
  }
  .form-mailing{
    float: left;
    display: block;
    width: 100%;
    max-width: none;
    margin-bottom: 20px;
    padding: 0 18px;
    .mail-subject{
      width: 50%;
    }
  }
  div.form-mailing.test-mail{
    height: 45px;
    .input-group{
      width: 50%;
      display: inline-block;
      float: left;
    }
    .custom-checkbox{
      width: 50%;
      float: right;
      padding-top: 27px;
      text-align: left;
      padding-left: 20px;
    }
    .test-mail-input{
      width: 100%;
      border-right: 1px solid lightgray;
      border-radius: 5px;
    }
  }
  button.button-primary.custom-button{
    float: right;
    margin-right: 20px;
  }
  div.panel-edit{
    width: 100%;
    height: 100%;
  }
  form{
    height: 100%;
  }
  .field-container{
    height: 100%;
  }
  .form-inline.form-mailing{
    position: relative;
    display: block;
    height: calc(100% - 150px);
  }
  .rdw-editor-wrapper{
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-top: 20px;
    height: calc(100% - 92px);
  }
  .rdw-editor-main{
    padding: 20px 15px;
    box-sizing: border-box;
    height: calc(100% - 51px);
  }
}

div.form-inline.form-inline-border.half-size{
  width: 50%;
  display: inline-block;
  float: left;
}

div.form-inline.form-inline-border.half-size.left input{
  width: calc(100% - 5px);
  float: left;
}

div.form-inline.form-inline-border.with-checkbox .input-group:first-of-type {
  padding-right: 10px;
  input{
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-right: 1px solid lightgray;
    padding-right: 3px;
  }
}
div.form-inline.form-inline-border.with-checkbox .custom-checkbox{
  padding-top: 37px;
}

div.vignette-cards-container{
  width: 100%;
}
div.vignette-cart{
  width: 400px;
  border: 1px solid #CACACA;
  padding: 20px;
  border-radius: 5px;
  margin: 0 auto;
  .status-checker{
    margin-bottom: 0;
    margin-top: 0;
  }
}
div.check-vignette.buy-now-link{
  width: 100%;
  a.button-primary{
    margin: 0 auto;
    display: block;
    width: fit-content;
  }
}
.clickable-tooltip{
  position: absolute;
  opacity: 0;
  bottom: -80px;
  display: none;
  transition: opacity 2s;
  .button-primary:hover{
    background-color: #F5BA16;
  }
}

.input-group:hover .clickable-tooltip{
  display: block;
  opacity: 0.8;
}
.input-group:hover  div.clickable-tooltip:hover{
  opacity: 1;
}



.tooltip-button{
  position: absolute;
  opacity: 0;
  bottom: 8px;
  right: 13px;
  display: none;
  .button-primary{
    background-color: transparent;
    width: 38px;
    height: 35px;
    min-width: 0;
    padding: 8px 0 0 0;
    text-align: center;
    color: #666666;
    font-size: 30px;
    a{
      color: black;
    }
  }
  .button-primary:hover{
    background-color: transparent;
    color: #2b2b2b;
  }
}
.input-group:hover .tooltip-button{
  display: block;
  opacity: 0.8;
}
.input-group:hover  div.tooltip-button:hover{
  opacity: 1;
}
.lpn-help-link{
  text-align: center;
  a{
    background: transparent;
    color: #666666;
    padding: 0;
    padding-bottom: 10px;
  }
  a:hover{
    background-color: transparent;
    color: #F5BA16;
  }
}
.lpn-help-link.right-aligned{
  padding-left: 50%;
  text-align: left;
  a{
    padding-left: 0;
  }
}
.lpn-help-link.check-vignette{
  text-align: left;
  padding-left: 510px
}
@media (max-width: 1425px) {
  .lpn-help-link.check-vignette{
    padding-left: 0;
    text-align: center;
  }
}
div.lpn-help-link.routr-pass{
  text-align: left;
  height: 30px;
  line-height: 34px;
  a.button.button-primary{
    width: 100%;
    font-size: 15px;
    padding: 0;
  }
}
.formatted-text.check-vignette p {
  margin-bottom: 5px;
}
.formatted-text.check-vignette h1 {
  text-align: center;
}
.input-group.route-select .tab-content.tab-content-default{
  padding-right: 0;
  padding-left: 0;
}
// SNOW FLAKES _________________________________________________
.snowflake {
  color: #fff;
  font-size: 1.7em;
  font-family: Arial;
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@-webkit-keyframes snowflakes-shake{0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}
/* Demo Purpose Only*/
.demo {
  font-family: 'Raleway', sans-serif;
	color:#fff;
  display: block;
  margin: 0 auto;
  padding: 15px 0;
  text-align: center;
}
.demo a{
  font-family: 'Raleway', sans-serif;
  color: #000;		
}
// SNOW FLAKES _________________________________________________

div.carousel-overlay .heading-cta .vehicle-section{
  padding-bottom: 40px;
  width: 1450px;
  margin: 0 auto;
}
div.bubble-container.home-page{
  display: inline-block;
  height: 250px;
  width: 20%;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  vertical-align: top;
  .bubble{
    width: 245px;
    height: 245px;
    border: solid 5px #F5BA16;
    border-radius: 180px;
    background-color: rgba(255, 255, 255, 0.10);
    margin: 0 auto;
    overflow: hidden;
    transition: width .2s, height .2s, padding-top .2s, margin-top .2s;
  }
  .bubble-title{
    color: white;
    margin-top: 40px;
    font-size: 17px;
  }
  .button.button-primary{
    border-radius: 40px;
    background-color: #F5BA16;
    font-size: 17px;
    margin: 0 auto;
    padding: 10px;
    width: 165px;
    font-weight: 600;
    position: relative;
    z-index: 10;
  }
  .bubble-image-container{
    height: 95px;
  }
  img{
    position: relative;
    height: 105px;
    top: -5px;
  }
  img.map{
    height: 145px;
    top: -26px;
    left: 5px;
  }
  img.tracker{
    height: 125px;
    top: -8px;
    // left: 5px;
  }
  img.tax{
    top: -8px;
    // left: 5px;
  }
  img.vignette{
    height: 170px;
    top: -60px;
    left: 20px;
  }
}

.home-image-container{
  overflow: hidden;
  .dark-screen{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 20, 20, .7)
  }
  .home-image{
    object-fit: cover;
    height: 1000px;
  }
}
@media (min-width: 1460px) {
  div.bubble-container.home-page .bubble:hover{
    width: 265px;
    height: 265px;
    padding-top: 10px;
    margin-top: -10px;
  }
}
@media (max-width: 1460px) {
  div.carousel-overlay .heading-cta .vehicle-section{
    width: 1280px;
  }
}
@media (max-width: 1300px) {
  div.carousel-overlay .heading-cta .vehicle-section{
    width: 900px;
  }
  .home-image-container .home-image, .home-image-container .dark-screen{
    height: 1000px
  }
  div.bubble-container.home-page{
    width: 33%;
  }
  
}
@media (max-width: 1030px) {
  div.carousel-overlay .heading-cta .vehicle-section{
    width: 600px;
  }
  .home-image-container .home-image, .home-image-container .dark-screen{
    height: 1300px
  }
  div.bubble-container.home-page{
    width: 50%;
  }  
}
@media (max-width: 900px) {
  div.carousel-overlay .heading-cta .vehicle-section{
    width: 300px;
  }
  .home-image-container .home-image, .home-image-container .dark-screen{
    height: 1650px
  }
  div.bubble-container.home-page{
    width: 100%;
  }  
}

// \background: #0E4AD4;
// background: -webkit-linear-gradient(bottom, #0E4AD4, #22ED6F);
// background: -moz-linear-gradient(bottom, #0E4AD4, #22ED6F);
// background: linear-gradient(to top, #0E4AD4, #22ED6F);
.invoice-preview {
  display: flex;
  flex-direction:column;
  background-color: #FFFFFF;
  padding: 10px;

  .invoice-header {
    margin-top: 15px;
  }
  .half-split {
    display: flex;
    flex-direction:row;
    margin-top:15px;
  }
  .half-split div.half {
    flex-grow:1;
    flex-shrink:1;
    display: flex;
    flex-direction:column;
    max-width: 50%;

    .field-row {
      display: flex;
      flex-direction:row;
      align-items: center;

      .field-label{
        flex-grow:0;
        flex-shrink: 0;
        width: 35%;
        text-align: right;
        background-color: #ececec;
        padding-right: 5px;
        font-weight: bold;
        height: 100%;
      }
      .field-content{
        flex-grow:1;
        text-align: left;
        padding-left: 5px;
      }
      .summary-label {
        flex-grow:0;
        flex-shrink: 0;
        width: 50%;
        text-align: right;
        padding-right: 5px;
        font-weight: bold;
      }
      .summary-content {
        font-size: 15px;
        flex-grow:1;
        text-align: left;
        padding-left: 5px;

        &.align-right {
          text-align: right;
          padding-right: 5px;
        }
      }
    }
  }
  .invoice-table{
    margin-top: 15px;
    thead {
      border-left: 1px solid #777879;
      tr {
        height: 50px;
        background-color: #d9d9d9;
        padding-top:10px;
        padding-bottom:10px;
        th {
          text-align: center;
        }

        th:nth-child(1) {
          padding-left: 10px !important;
          width: 10%;
        }
        th:nth-child(2) {
          width: 50%;
        }
        th:nth-child(3) {
          width: 10%;
        }
        th:nth-child(4) {
          width: 15%;
        }
        th:nth-child(5) {
          width: 15%;
        }
      }

    }
    td {
      border: 1px solid black;
      text-align: center;

      &:nth-child(1) {
        padding-left: 10px !important;
      }

      &:nth-child(2) {
        text-align: left;
      }

      &:nth-child(3) {
        text-align: right;
      }

      &:nth-child(4) {
        text-align: right;
      }

      &:nth-child(5) {
        padding:1rem;
        text-align: right;
      }
    }
  }
}

.empty-invoice-preview {
  background: #F2F2F2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}