.icon {
	color: $color-text;
	font-size: 24px;
}

.icon-large {
	font-size: 35px;
	margin-right: 30px;
}

.icon-red {
	color: $color-status-inactive;
}

.icon-green {
	color: $color-status-active;
}

.icon-white {
	color: $color-white;
}

.icon-grey {
	color: $color-text;
}

.icon-primary {
	color: $color-primary;
}

.icon-disabled, .icon[disabled] {
	color: rgba(0, 0, 0, 0.2);
}

.icon-filter-obligations {
	position: absolute;
	right: 23px;
	z-index: 2;
	cursor: pointer;
}

.import-csv-button {
	position: absolute;
	right: 5px;
	z-index: 2;
	@media (max-width: $breakpoint-small) {
		position: relative;
		width: 80%;
		margin-left: 10%;
		margin-bottom: 20px;
	}
}

.icon-loader {
	background: url(../images/loader.gif) no-repeat;
	background-size: 20px 20px;
	width: 20px;
	height: 20px;
	margin-top: 8px;
}

.section-heading {
	text-align: center;
	margin-bottom: 50px;

	.heading-title {
		font-size: $font-size-heading;

		&-small {
			font-size: 35px;
		}

		@media (min-width: $breakpoint-medium) {
			&-large {
				font-size: 60px;
			}
		}
	}

	// Because .text-large-underline has to be display: inline, the next element, no matter what
	// must have enough margin-top so that the underline does not go over it.
	.text-large-underline + * {
		margin-top: 30px;
	}

	.heading-undertitle {
		font-weight: normal;
		font-size: 26px;
	}

	.heading-description {
		margin: 60px 0;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.heading-colored {
		color: $color-primary;
	}

	.button {
		font-size: 24px;
	}

	// This for now is only used in the about page. It's just a very big title.
	// Works with one word ideally.
	.heading-about {
		font-size: 86px;
	}

	.heading-cta {
		margin-top: 80px;
	}

	&-icon {
		display: flex;
		width: 100%;

		.heading-icon {
			margin-right: 3em;
			flex: 0 0 140px;

			@media (max-width: $breakpoint-medium) {
				display: none;
			}

			img {
				width: 100%;
			}

			&-large {
				flex: 0 0 200px;
			}
		}

		.heading-main {
			flex-grow: 1;
			text-align: left;
		}
	}

	&-no-mb {
		margin-bottom: 0;
	}

	&-mb-large {
		margin-bottom: 110px;
	}

	&-left {
		text-align: left;
	}

	@media (max-width: $breakpoint-small) {
		.heading-title {
			font-size: 22px;
		}

		.button {
			font-size: 16px;
		}
	}
}

// Seen on the homepage, has a few steps and picker boxes
.multistep {

	// The wrapper element
	&-picker {
		margin-top: 20px;
	}

	&-heading {
		font-size: 25px;
		text-align: center;
		margin-bottom: 50px;
	}

	&-title {
		padding-left: 2em;
		padding-right: 2em;
		font-size: 35px;
	}

	// Automatically numbered boxes
	&-navigation {
		$steps-spacing: 60px;
		$steps-font-size: 19px;
		$steps-width: #{$steps-font-size * 2}; // 2em in px

		margin-bottom: 10px;

		.navigation {
			counter-reset: multistep-nav;

			&-steps {
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&-step-inner {
				background: white;
				text-align: center;
				border-radius: 50%;
				border: 3px solid $color-primary;
				transition: .5s;
				transform: rotate(45deg);
				border-color: $color-primary;
				display: flex;
				align-items: center;
				justify-content: center;

				width: $steps-width;
				height: $steps-width;

				&:hover {
					border-color: $color-primary-hover;
				}

				&:before {
					content: counter(section);
					display: inline-block;
					color: #494949;
					transform: rotate(-45deg);
				}
			}

			&-step {
				counter-increment: section;
				display: inline-block;
				cursor: pointer;
				margin: 0 $steps-spacing;
				font-size: $steps-font-size;
				position: relative;

				&:after {
					content: '';
					display: block;
					height: 2px;
					width: calc(#{$steps-width} + #{$steps-spacing} - 30px);
					left: calc(100% + 20px);
					background: url(../images/dot.svg) repeat-x;
					position: absolute;
					top: 50%;
				}

				&:last-of-type:after {
					display: none;
				}

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}

				.navigation-step-inner {
					border-color: rgba($color-primary, .5);
				}

				&.is-current {
					.navigation-step-inner {
						transform: rotate(45deg);
						border-color: $color-primary;
						//border-radius: 2px; that makes the diamond shape

						&:before {
							transform: rotate(-45deg);
							transition: .5s;
						}
					}
				}

				&.is-current ~ li .navigation-step-inner {
					border-color: $color-light;
					border-radius: 50%;
					transition: .5s;
					transform: rotate(0);

					&:before {
						transform: rotate(0);
						transition: .5s;
					}

					&:hover {
						border-color: #ddd;
					}
				}

				&.is-final .navigation-step-inner:before {
					content: '';
					// left -3px because of the SVG shape
					background: transparent url(../images/check-white-dark.svg) no-repeat left -3px center;
					background-size: contain;
					width: 30px;
					height: 30px;
				}

				@media (max-width: $breakpoint-large) {
					margin: 0 35px;

					&:after {
						width: 40px;
					}
				}

				@media (max-width: $breakpoint-small) {
					margin: 0 15px;

					&:after {
						width: 27px;
						left: 47px;
					}

					&-inner {
						font-size: 15px;
						width: 45px;
						height: 45px;
					}
				}
			}
		}
	}

	// A variation of the picker for transporting
	.transport-picker {

	}

	&-next-wrapper {
		margin-top: 95px;
	}

	@media (max-width: $breakpoint-medium) {
		&-step {
			max-width: 700px;
			margin: 0 auto;
		}

		&-title {
			padding-left: 0;
			padding-right: 0;
			font-size: 18px;
		}

		&-navigation {
			margin-bottom: 30px;
		}

		&-next-wrapper {
			margin-top: 40px;

			.button {
				display: block;
			}
		}
	}
}

// A set of options to select (boxes)
.box-form {
	.box-form-option {
		height: calc(100% - 22px);
	}

	.box-form-section {
		.box-form-header {
			font-size: 20px;
			font-weight: bold;
			padding-top: 42px;
		}

		&:first-of-type {
			.box-form-header {
				padding-top: 0;
			}
		}
	}

	&-border {
		margin-left: auto;
		margin-right: auto;
		max-width: 1400px;
		border: 1px solid $color-border;
		border-radius: 5px;
		// On step 2, this will break the boxes in two lines
		// padding: 2.25em 4em;
		padding: 45px;

		&-narrow {
			max-width: 1100px;
		}
	}

	.form-inline,
	.input-group {
		margin-bottom: 1.25em;
	}

	// When the layout is a bit more complex we need to reset the margins so that
	// they don't stack.
	.form-inline .input-group {
		margin-bottom: 0;
	}

	.form-footer-cta {
		margin-top: 30px;

		.button {
			font-size: 24px;
		}
	}

	@media (max-width: $breakpoint-medium) {
		border: none;
		padding: 0;

		.form-inline .input-group {
			margin-bottom: 15px;
		}
	}
}

.box-solid {
	background: $color-light;
	display: inline-block;
	padding: 1em 3em;
	text-align: center;
	margin: 1em;
	cursor: pointer;
	border-radius: 5px;

	.text-value {
		font-size: 28px;
		font-weight: bold;
		display: block;
		margin-bottom: .25em;
	}

	.text-type {
		display: block;
		font-size: 18px;
	}

	&.is-selected,
	&:hover {
		background: $color-primary;
		color: white;
	}

	@media (max-width: $breakpoint-small) {
		display: block;
	}
}

.box-picker {
	display: flex;
	flex-direction: column;
	border: 1px solid $color-border;
	border-radius: 5px;
	text-align: center;
	padding: 40px;
	cursor: pointer;

	&:hover,
	&.is-selected {
		background: $color-primary;
		color: white;

		img {
			// @TODO: Change, no IE support
			filter: invert(100%);
		}
	}

	&:active {
		transition: .1s;
		transform: scale(.95);
	}

	.option-image-wrapper {
		display: block;
		align-items: center;
		justify-content: center;
	}

	.option-title {
		font-size: 24px;
		font-weight: bold;
		margin: .8em;
		white-space: nowrap;
	}

	.option-description {
		font-size: 18px;
	}

	@media (max-width: $breakpoint-small) {
		.option-image {
			max-width: 100px;
			opacity: .7;
		}

		.option-title {
			margin-bottom: .4em;
		}

		.option-description {
			font-size: 16px;
			line-height: 1.25;
		}
	}
}

// Seen now on step 4 - has information about the items added to the cart
.box-summary {
	&-header {
		display: flex;
		align-items: center;


		img {
			margin-right: 1.5em;
		}
	}

	&-title {
		font-size: 20px;
	}

	&-purchases {
		margin-bottom: 1em;
	}

	&-final {
		font-size: 24px;
		font-weight: bold;
		text-align: right;
		margin-bottom: .5em;
	}

	@media (max-width: $breakpoint-small) {
		padding: 0;

		&-final {
			text-align: center;
		}

		&-header,
		&-purchases {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

.box-summary-text {
	font-size: 24px;
	font-weight: bold;
	text-align: left;
	margin-top: .5em;
}

.payment-info-text {
	font-weight: 400;
	padding-right: 10px;
}

.cart-summary-title {
	display: block;
	width: 100%;
	font-weight: bold;
	cursor: pointer;
	overflow: hidden;
}

.cart-summary-title.delict-cart{
	text-align: left;
}

// Toggele type of elements; we have a few , one is .toggle-tabs, other is .toggle-accordion
.toggle-tabs {
	&.toggle-tabs-clean {
		border: none;

		.tabs-header {
			border: none;
			background: white;
		}

		.tab-content {
			padding: 20px;
		}

		.tab-content-default {
			display: none;
		}

		.tab-content-default.is-current {
			display: block;
		}

		.tabs-header-item {
			font-size: 20px;
			margin-right: 10px;
			flex-grow: 0;

			&.is-wide {
				flex-grow: 1;
				text-align: right;
			}

			&.is-current {
				background: transparent;
				border-bottom: 3px solid $color-primary;
				color: $color-text;

				&:hover {
					background: transparent;
					opacity: .7;
				}
			}

			&:hover {
				background: transparent;
				opacity: .7;
			}
		}

		.tabs-main {
			padding: 0;
			border: none;
		}

		.tabs-item {
			border: 1px solid $color-border;
			border-radius: 5px;
			padding: 45px;
			margin-top: 30px;
		}

		@media (max-width: $breakpoint-medium) {
			.tabs-item {
				padding: 0;
				border: none;
			}

			.tabs-header {
				flex-wrap: nowrap;
				display: flex;
				// quick way to scroll
				max-width: 100%;
				overflow-x: auto;
				box-shadow: inset 0px -3px 0 0px #f2f2f2;
			}

			.tabs-header-item {
				flex-shrink: 0;
				font-size: 16px;
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	.tabs-header {
		display: flex;
		background: $color-light;
		overflow: hidden;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	.tabs-header-item {
		padding: .6em .8em;
		font-weight: bold;
		font-size: 24px;
		flex-grow: 1;
		text-align: center;
		cursor: pointer;

		&:hover {
			background: #f8f8f8;
		}

		&.is-current {
			background: $color-primary;
			color: white;

			&:hover {
				background: $color-primary-hover;
			}
		}
	}

	.tabs-item {
		padding: 60px 80px;
		display: none;

		&.is-current {
			display: block;
		}
	}

	.tabs-item-small-padding {
		padding: 10px 10px !important;
	}

	.tabs-main {
		border: 1px solid $color-border;
		border-top: none;
	}

	.img-text-inline {
		margin-bottom: 30px;
	}

	.table-price-mobile {
		display: none;
	}

	@media (max-width: $breakpoint-medium) {
		.tabs-item {
			padding: 1.5em;
		}
	}

	@media (max-width: $breakpoint-small) {
		.tabs-header {
			display: block;

			&-item {
				font-size: 16px;
			}
		}

		.tabs-main {
			border-bottom: none;
		}

		.tabs-item {
			padding: 0;
		}

		.img-text-inline {
			margin: 0;
			padding: 10px;
			flex-wrap: wrap;
			h3 {
				flex-basis: 100%;
				font-size: 15px;
			}
		}

		.img-text-inline > * {
			flex-basis: 33.333333%;
		}

		.table-price-desktop {
			display: none;
		}

		.table-price-mobile {
			display: table;
		}
	}
}

.toggle-accordion {
	.accordion-item {
		border: 1px solid $color-border;
		background: white;
		display: block;
		margin-bottom: 20px;
		cursor: pointer;
		border-radius: 5px;

		.item-title {
			padding-right: 50px;
		}

		.item-title:after {
			position: absolute;
			top: 50%;
			right: 1.7em;
			transform: translateY(-50%) rotate(0) scale(.5);
			opacity: .5;
			transition: .3s;
			content: '';
			width: 30px;
			height: 30px;
			background: url(../images/plus.svg) no-repeat;
			background-size: contain;
			display: block;
		}

		&:hover {
			border-color: #aaa;
		}

		.item-title:hover:after {
			transform: translateY(-50%) rotate(0) scale(.7);
			opacity: 1;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&.is-expanded {
			.item-content {
				display: block;
			}

			.item-title {
				background: $color-primary;
				color: white;
			}

			.item-title:after {
				opacity: 1;
				height: 2px;
				background: white;
				top: calc(1em + 13px); // 1em as all other + 30(height)/2 - 2(width)
			}
		}
	}

	.item-content {
		display: none;
		padding: 1em 1.5em;
		background: $color-light;

		.formatted-text {
			padding: 0;
		}
	}

	.item-title {
		font-weight: normal;
		padding: 1em 2.5em 1em 1.5em;
		position: relative;
	}

	@media (max-width: $breakpoint-small) {
		.accordion-item {
			&:after {
				transform: translateY(-50%) rotate(0) scale(.5);
				right: .7em;
			}
		}

		.item-title {
			padding: 20px;
			font-size: 14px;
		}

		.item-description {
			font-size: 14px;
		}

		.item-content {
			font-size: 14px;
			padding: 20px;
		}

	}
}

.img-text-inline {
	display: flex;
	align-items: center;
	font-size: 18px;

	img {
		opacity: .9;
		max-width: 80px;
		margin-right: 1.25em;
	}
}

.slider {
	position: relative;

	&-controls {
		position: absolute;
		width: 100%;
		top: 50%;
		display: flex;
		justify-content: space-between;
		pointer-events: none;
		transform: translateY(-50%);

		.slide-right,
		.slide-left {
			background-color: $color-white;
			background-position: center center;
			background-repeat: no-repeat;
			width: 64px;
			height: 64px;
			display: inline-block;
			border: none;
			border-radius: 50%;
			pointer-events: all;
			transition: .2s;

			&:hover {
				opacity: .8;
				transition: .2s;
			}

			&:focus {
				outline: none;
			}
		}

		.slide-right {
			background-image: url(../images/arrow_down.svg);
			transform: rotate(-90deg);
		}

		.slide-left {
			background-image: url(../images/arrow_down.svg);
			transform: rotate(90deg);
		}
	}

	&-logos {
		.slider-items {
			display: flex;
			justify-content: center;
		}

		.slider-item {
			height: 100%;
			background: white;
			border: 1px solid $color-border;
			padding: 30px 40px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin: 0 10px;
			border-radius: 5px;
			transition: .3s;

			&:hover {
				transform: scale(1.05);
				transition: .3s;
			}

			&:active {
				transform: scale(.95);
				transition: .1s;
			}

			img {
				display: block;
			}
		}
	}

	@media (max-width: $breakpoint-small) {
		&-logos {
			.slider-controls {
				display: none;
			}

			.slider-item {
				display: block;
				margin-bottom: 20px;
				text-align: center;

				img {
					display: inline-block;
				}
			}

			.slider-items {
				display: block;
			}
		}
	}
}

.payment {

	&-picker {
		display: flex;
		justify-content: space-between;
	}

	&-summary {
		max-width: 540px;
		width: 100%;
	}

	&-information {
		max-width: 650px;
		width: 100%;

		.input-group {
			margin-bottom: 1.75em;
		}
	}

	@media (max-width: $breakpoint-small) {
		&-picker {
			display: block;
			max-width: 650px;
		}

		&-summary {
			max-width: 100%;
		}
	}
}

.note {
	font-size: 16px;
	padding: 1.5em;
	background: $color-light;
	color: #707070; // Custom color here
	text-align: center;

	&-default {
		background: rgba($color-primary, .2);
	}

	&-large {
		padding: .5em 1.5em .6em;
		font-size: 18px;

		.text-large {
			// Move a bit down to match line-height
			transform: translateY(0.1em);
			display: inline-block;
			margin-left: 1em;

			// And overwrite default styles
			font-size: 24px;
		}
	}

	&-fullwidth {
		width: 100%;
	}
}

.left-align {
	text-align: left;
}

.purchase-success {
	text-align: center;
	//margin-top: 110px;

	.success-title {
		font-size: 34px;
	}

	.success-icon {
		text-align: center;

		img {
			max-width: 170px;
			width: 100%;
			margin-bottom: 10px;
		}
	}

	.success-buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0;
		font-size: 24px;
	}

	.button {
		display: inline-flex;
		align-items: center;
		margin: 1em;
		max-height: 80px;

		&-ghost {
			// Due to the size change, paddings need to change too
			font-size: 18px;
			padding-top: .95em;
			padding-bottom: .95em;
		}

		.icon {
			margin-right: 20px;
			width: 33px; // as per design
		}
	}

	@media (max-width: $breakpoint-small) {
		.success-title {
			font-size: 22px;
			display: block;
			line-height: 1.25;
			margin-bottom: 65px;
		}

		.success-icon {
			img {
				max-width: 100px;
				margin-bottom: 50px;
			}
		}

		.success-buttons {
			display: block;

			.button {

			}

			.icon {
				width: 20px;
			}
		}

		.button {
			margin: 45px 0 0;
			display: block;

			&-ghost {
				font-size: 16px;
			}
		}
	}
}

// @TODO: move this and all other steps in separate files
body.purchase-step-final {
	background: url(../images/road-all.svg) no-repeat bottom center;
	background-size: auto;

	@media (max-width: 1200px) {
		background-size: 120%;
	}

	@media (max-width: $breakpoint-small) {
		background-size: 1200px;
		background-position: bottom 80px center;
		border-bottom: 80px solid $color-text;
	}
}

.status-checker {
	font-weight: bold;
	padding-left: 1.5em;
	position: relative;

	&:before {
		content: '';
		display: inline-block;
		border-radius: 50%;
		height: 1rem;
		width: 1rem;
		background-color: #f0f0f0;
		margin-top: .3em;
		left: 0;
		position: absolute;
	}

	&.status-active:before {
		background: $color-status-active;
	}

	&.status-inactive:before {
		background: $color-status-inactive;
	}

	&.status-pending:before {
		background: $color-status-pending;
	}
}

.data-table {
	width: 100%;
	background: rgba(#F7F7F7, .7);
	border-left: 6px solid $color-primary;
	font-size: 20px;

	// Table settings
	display: flex;
	flex-flow: row wrap;

	div {
		box-sizing: border-box;
	}

	header.data-row {
		background: #F2F2F2;
		font-weight: bold;
	}

	.data-row {
		width: 100%;
		display: flex;
		@media (min-width: $breakpoint-small) {
			padding-left: 5em;
		}
	}

	.data-cell {
		padding: 1em;
	}

	&.columns-3 {
		.data-cell {
			width: calc(100% / 3);
		}
	}
}

.table-actions {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 30px;
	align-items: center;

	.actions-select {
		flex-grow: 0;
		flex-direction: row;
		min-height: 0;

		.button {
			font-size: 20px;
			margin-left: 20px;
			padding: 0.9em 3em;
		}
	}

	@media (max-width: $breakpoint-small) {
		justify-content: center;
		flex-direction: column;

		.actions-select {
			width: 100%;

			.button {
				font-size: 16px;
				padding: .6em 3em;
			}
		}
	}
}

.pagination {
	a {
		display: inline-block;
		padding: .25em;
		color: $color-text;

		&.is-current {
			color: $color-primary;
			text-decoration: underline;
		}
	}
}

.loading-mask {
    width: 100%;
    z-index: 100;
    position: fixed;
    top: 0px;
}

.overlay {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: rgba(0,0,0,0.5);
	z-index: 100;

	.maintenance {
		margin-left: auto;
		margin-right: auto;
		background-color: $color-white;
		width: 50%;
		margin-top: 100px;
		border-radius: 20px;
		padding: 50px;
		text-align: center;
		max-width: 650px;

		@media (max-width: $breakpoint-medium) {
			width: 90%;
			padding-left: 10px;
			padding-right: 10px;
		}

	}
}

.custom-checkbox, .panel-route-pass .custom-checkbox{
	input[type=checkbox] {
		display:none;
	}

	input[type="checkbox"] + label {
		background-image: url(../images/checkmark.svg);
		background-size: contain;
		cursor: pointer;
		height: 30px;
		width: 30px;
		display:inline-block;
		border: none;
	}

	input[type="checkbox"]:checked + label {
		background: unset;
		background-image: url(../images/checkmark-dark.svg);
		background-size: contain;
	}

	.custom-checkbox-label {
		cursor: pointer;
		vertical-align: super;
		margin-left: 10px;
		display: inline-block;
	}
}

.multi-value-editor{
	width: 100%;
	position: relative;

	table {
		margin: 0;

		tr {
			border: 0;

			td{
				padding: 0;

				input {
					width: 100%;
					padding: 0 5px;
					box-sizing: border-box;
					border-width: 1px;
					border-radius: 0;
					font-size: 14px;
				}

				input.fld-invalid {
					border: 1px solid red;
					outline: none;
				}

				input[type=checkbox] {
					width: 20px;
					vertical-align: inherit;
				}

				.icon {
					height: 24px;
					cursor: pointer;
				}
			}
		}


	}

	.editor-dropdown {
		@include custom-dropdown;
		display: none;
		position: absolute;
		table-layout: fixed;

		&-open {
			display: table;
		}

		tr {
			padding: 3px 6px;
			text-overflow: ellipsis;
			overflow: hidden;
			font-size: 14px;
			border-bottom: none;

			td {
				overflow: hidden;
				padding-left: 4px;

				.icon {
					height: 18px;
					width: 18px;
					font-size: 16px;
				}
			}

			td.delete-icon {
				width: 18px;
				padding-left: 0;

				.icon {
					cursor: pointer;
					display: none;
				}
			}
		}

		tr:hover {
			background-color: rgba(0, 0, 0, 0.05);

			td.delete-icon .icon {
				display: block;
			}
		}
		
	}
}

.upload-file-container {
	width: 380px;
	padding: 20px;
	background: $color-white;
	border: 1px solid $color-border;
	border-radius: 4px;

	&.full-width {
		width: 100%;
	}
	.form-error-msg {
		color: $color-status-inactive;
		margin-top: 20px;
	}

	.dropzone {
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		padding: 20px;
		border: 1px solid $color-border;
		border-radius: 4px;

		&-hightlight {
			background: $color-light;
		}

		.file-input {
			display: none;
		}

		.file-upload-icon {
			justify-content: space-around;
			margin-top: 20px;
			font-size: 40px;
		}
	}

	.progress-bar-file {
		padding: 20px 0 0 0;
		overflow : hidden;

		.progress-bar {
			width: 100%;
			height: 8px;
			border-radius: 5px;
			background-color: $color-primary-hover;

			&-disabled {
				background-color: $color-border;
			}

			.progress {
				height: 100%;
				margin: 0;
				border-radius: 5px;
				background-color: $color-primary;
			}
		}
	}

	.actions-wrapper {
		text-align: center;
		padding-top: 20px;

		button {
			margin-right: 0;
		}

		.button-upload-disabled {
			background: $color-border;
			cursor: not-allowed;
		}
	}
}

.map-content {
	.field-container.panel-route-pass {

		@media (min-width: $breakpoint-medium) {
			.custom-checkbox {
				position: absolute; 
				top: 0; 
				right: 0; 
				margin-top: -12px;
			}
		}

		.input-group {
			margin-top: 10px;
			min-height: auto;
			font-size: 16px;

			.chip-label {
				display: block;
			}

			label {
				white-space: nowrap;
				margin-bottom: 2px;
			}

			select {
				padding-left: 10px;
				background: white url(../images/arrow_down.svg) no-repeat center right 1em;
			}

			.chip-wrapper {
				display: flex;

				.chip {
					display: inline-block;
					width: 100%;
					min-width: 0; // fix for Firefox width issue
					height: 40px;
					padding: 8px;
					background: $color-white;
					border: 0;
					border-radius: 0;
					box-shadow: inset 0 0 0 2px $color-primary;
					outline: none;
					font-size: 16px;
					text-align: center;
					cursor: pointer;

					&:first-of-type {
						border-top-left-radius: 5px;
						border-bottom-left-radius: 5px;
					}

					&:last-of-type {
						border-top-right-radius: 5px;
						border-bottom-right-radius: 5px;
					}

					&:not(:first-of-type) {
						margin-left: -2px;
					}

					&:hover {
						background: $color-primary-hover;
						color: $color-white;
					}

					&-selected {
						background: $color-primary;
						color: $color-white;
					}

					img {
						padding: 4px 10px 0 10px;
					}
				}
			}

			.map-dropdown {
				height: 40px;
				box-shadow: inset 0 0 0 2px $color-primary;
				border-radius: 5px;
				border: none;
				font-size: 16px;
			}

			.multi-select-input {

				.react-select__input input {
					height: 1em;
				}

				.react-select__menu {
					box-shadow: inset 0 0 0 2px $color-primary, 2px 2px 8px -1px rgba(0, 0, 0, 0.7);
				}
			
				.react-select__control {
					height: 40px;
					box-shadow: inset 0 0 0 2px $color-primary;
					border-radius: 5px;
					border: none;
					font-size: 16px;
				}

				.react-select__indicators {
					display: none;
				}

				.react-select__control {
					padding-right: 36px;
					background: white url(../images/arrow_down.svg) no-repeat center right 1em;
				}
			}
		}

		div.errorMessageBox {
			text-align: center;
			width: 97%;
			margin: 0 auto;
			border-radius: 3px;
			color: red;
			font-size: 16px;
		}
	}

	.react-datepicker-wrapper {
		width: 100%;

		.routing-time-picker {
			width: 100%;
			height: 40px;
			padding: 10px;
			box-shadow: inset 0 0 0 2px $color-primary;
			border-radius: 5px;
			border: none;
			font-size: 16px;
		}
	}
}

@mixin open-street-map-icon {
	width: 25px;
	height: 25px;
	border-radius: 25px;
	text-align: center;
	background: $color-primary;
	font-size: 18px;
	box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.7);
}

.dnd-sortable-list {
	padding-top: 11px;

	&-item {
		position: relative;
		box-shadow: inset -1px 0 0 $color-primary,
		inset 0 -1px 0 $color-primary,
		inset 1px 0 0 $color-primary;
		cursor: pointer;

		&:first-of-type {
			box-shadow: inset 0 1px 0 $color-primary,
			inset -1px 0 0 $color-primary,
			inset 0 -1px 0 $color-primary,
			inset 1px 0 0 $color-primary;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}

		&:last-of-type {
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
		}
	}

	.sortable-index {
		display: inline-block;
		margin: 5px;
		@include open-street-map-icon;
	}

	.sortable-items-close {
		position: absolute;
		top: 4px;
		right: 5px;
		cursor: pointer;
	}
} 

.map-custom-icon {
	@include open-street-map-icon;
}

.button-primary-disabled,
.button-primary-disabled:hover {
	background: $color-border;
	cursor: not-allowed;
}

.contract-checkbox {
	margin-top: 5px;

	& ~ label {
		display: flex;
	}
}

.back-button {
	position: absolute;
	top: .5em;
	left: .5em;
	display: flex;
	height: 3em;
	opacity: .5;
	transition: .3s;
	background: none;
	border: none;
	font-size: 18px;
	outline: none;
}

.back-button-text {
	margin-top: 2px;
}

.back-button:hover {
	opacity: 1;
	transition: .3s;
}

.payment-summary-title {
	display: block;
	margin-bottom: 15px;
	font-size: 20px;
	font-weight: bold;
	color: $color-text;
}

.all-items-checkbox ~ label {
	position: relative;
	top: -5px;
	left: -30px;
}

.min-amount-payment {
	margin-top: -15px;
	text-align: left;
	color: $color-text;
	font-size: 16px;
}

select::-ms-expand {
	display: none;
}

.transport-picker {
	.multistep-steps {
		margin-bottom: 20px;
	}
}

.border-checkbox-wrapper {
	position: absolute;
	right: 6px;
	top: 5px;
}

.predefined-route-close {
	display: flex;
	align-items: flex-end;
	margin-bottom: 8px;
	margin-left: 4px;
	cursor: pointer;
}

.shifts-checkbox-wrapper {
	.input-group-checkbox {
    width: 100%;
		margin-top: 10px;
    margin-left: 1px;
	}
}

.shifts-details {
	.react-datepicker-wrapper ~ input {
		margin: -1px;
	}
}

.nosplit_vignettes {
	padding: 0 20px 20px 20px;
	text-align: center;
}