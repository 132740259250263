/**
 * The standard section wrapper. Use this with other classes for the base styling.
 * Default paddings are 40 and 80px
 */
.section-fullwidth {
	$border-color: #ddd;
	$section-padding: 40px;

	padding: #{$section-padding} 0;
	position: relative;

	@media (min-width: $breakpoint-small) {
		padding: #{$section-padding} 0;
	}

	&.text-white {
		color: white;

		hr {
			background-color: rgba(white, 0.2);
		}
	}

	&-no-pb {
		padding-bottom: 0;
	}

	&-large-pd {
		padding-top: 120px;
		padding-bottom: 120px;
	}

	@media (max-width: $breakpoint-small) {
		padding: 20px 0;
	}
}

.section-purchase {
	padding: 60px 0 10px 0;
	.section-heading {
		margin-bottom: 35px;
	}

	@media (max-width: $breakpoint-small) {
		.section-heading {
			margin-bottom: 10px;
		}
	}
}

div.input-date > div {
	z-index: 100;
}

.section-check-validity {
	background: $color-light;

	@media (max-width: $breakpoint-small) {
		.section-heading {
			margin-bottom: 30px;
		}
	}
}

.section-registration {
	@extend .section-fullwidth.text-white;
	background: $color-text;

	.list-large-connected {
		margin-bottom: 30px;
	}

	@media (max-width: $breakpoint-small) {
		padding-bottom: 40px;

		.button {
			display: block;
		}

		.list-large-connected {
			margin-bottom: 0;
		}
	}
}

.section-pricing {
	th.tollFirstPricingTableHeader {
		text-align: center;
		padding-bottom: 0;
		padding-right: 15px;
		padding-left: 15px;
	}

	th.tollSecondPricingTableHeader {
		text-align: center;
		 padding: 0px 15px;
	} 

	th.tollThirdPricingTableHeader {
		padding-left: 15px
	}

	th.tollLastPricingTableHeader {
		padding-right: 10px;
		width: 10%;
	}

	td.tollPricingVehicleTitle {
		width: 50%;
	}

	td.tollPricingVehicleEmissionClass {
		width: 24%;
	}

	.table-default-small {
		display: none;
	}
	
	@media (max-width: $breakpoint-medium) {
		.table-default td, .table-default th {
			padding: 5px;
		}
		
		.table-default {
			display: table;
		}
	}
}

.table-faq-lpn {
	width: 40%;
	margin: 0 auto;
	tr {
		th {
			text-align: center;
		}
		td {
			text-align: center;
		}
	}
}

.section-benefits {
	&.section-fullwidth {
		padding: 190px 0;
	}

	// due to design difference, the width must be limited here:
	.section-heading {
		max-width: 600px;
	}

	@media (max-width: $breakpoint-small) {
		&.section-fullwidth {
			font-size: 14px;
			padding: 30px 0;
		}

		// sorry... design
		.section-heading-left {
			text-align: center;
			font-size: 14px;
		}
	}
}

.section-split {

}

.section-partners {
	background: $color-light;
}

// A nice interactive separator you can place everywhere
.section-road-separator {
	height: 116px;
	overflow: hidden;
	position: relative;

	img {
		max-width: 100%;

		// Let's make it centered
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0;
	}

	@media (max-width: $breakpoint-medium) {
		img {
			max-width: 120%;
		}
	}

	@media (max-width: $breakpoint-small) {
		img {
			max-width: 150%;
			// left: 0;
			// transform: translateX(0);
		}
	}
}

.section-faq {
	font-size: 20px;

	@media (max-width: $breakpoint-small) {
		.section-heading-left {
			text-align: center;	
		}

		.text-large {
			font-size: 16px;
			text-align: center;
			margin: 10px 0;
		}

		.toggle-accordion {
			margin-top: 20px;
		}
	}
}

.section-wide-note {
	background: $color-primary;
	padding: 40px 0;

	.note-huge {
		display: flex;
		align-items: center;
		justify-content: center;

		.note-image {
			margin-right: 20px;
		}

		.note-title {
			font-size: 65px;
		}
	}

	@media (max-width: $breakpoint-medium) {
		.note-huge {
			.note-title {
				font-size: 48px;
			}

			.note-image {
				width: 56px;
			}
		}
	}

	@media (max-width: $breakpoint-small) {
		padding: 10px 5px;

		.note-huge {
			.note-title {
				font-size: 16px;
			}

			.note-image {
				width: 36px;
			}
		}
	}
}

.section-background {
	background-size: cover;
	background-position: center center;

	&-overlay {
		@extend .section-fullwidth.text-white;
		position: relative;

		&:before {
			height: 100%;
			width: 100%;
			position: absolute;
			content: '';
			display: block;
			background: rgba($color-text, .9);
			z-index: 0;
			top: 0;
			left: 0;
		}

		& > * {
			z-index: 1;
			position: relative;
		}
	}

	&-overlay-light {
		@extend .section-background-overlay;

		&:before {
			background: rgba($color-text, .6);
		}
	}
}

.section-empty {
	&.section-background {
		padding-bottom: 45%;
	}
}

.section-validity {
	// Design specific styles for the comp of validity
	padding: 0 0 60px 0;
	.box-form {
		min-height: 265px;
		display: flex;
		flex-direction: column;
		align-items: center;

		input,
		select {
			border: none;
		}
	}

	.status-checker {
		margin-bottom: 2em;
		margin-top: 1.5em;
	}
}

.section-intro {
	@extend .section-fullwidth.text-white;
	@extend .section-background-overlay-light;

	// Since it's most often after the header, it needs more spacing on top.
	// Modify this through JS if needed with new class.
	padding-top: 160px;
	padding-bottom: 50px;

	// Just here wa want it always yellow
	.heading-subtitle {
		color: $color-primary;
	}
}

.section-dark {
	@extend .section-fullwidth.text-white;
	background: $color-text;
}

.section-measure {
	@media (max-width: $breakpoint-small) {
		.section-heading-left {
			text-align: center;
		}

		.button {
			display: block;
		}

		img {
			max-width: 140px;
		}
	}
}

.section-error-page {
	text-align: center;
	padding-top: 200px;

	.error-title {
		font-size: 180px;
		margin-bottom: 18px;
	}

	.error-subtitle {
		font-size: 40px;
	}

	.error-buttons {
		max-width: 1000px;
		margin: 70px auto 0;

		.button {
			margin: 25px;
		}
	}

	@media (max-width: $breakpoint-small) {
		padding-top: 80px;

		.error-buttons {
			margin-top: 40px;

			.button {
				margin: 10px;
				display: block;
			}
		}
	}
}

.section-404-image {
	img {
		width: 50%;
		display: block;
	}

	@media (max-width: $breakpoint-small) {
		img {
			width: 85%;
		}
	}
}

.section-contacts {
	.box-form {
		margin-top: 40px;
	}
}

.section-slider {
	position: relative;
}

.section-intro-slider {
	display: flex;
	align-items: flex-end;
	min-height: calc(50vh + 300px);
	padding-bottom: 160px;

	.row {
		// Needed due to the flex wrapper
		width: 100%;
	}

	.heading-title {
		font-size: 86px;
	}

	.heading-subtitle {
		font-weight: normal;
		margin-top: 20px;
	}

	.heading-cta {
		.button, a {
			max-width: 500px;
			margin: 0 30px;
			width: 100%;
			text-align: center;

			&-ghost {
				color: white;
			}
		}
	}

	@media (max-width: 1160px) {
		.heading-cta {
			.button, a {
				margin: 30px;
				width: auto;
			}
		}	
	}

	@media (max-width: $breakpoint-small) {
		padding-bottom: 80px;
		min-height: calc(50vh + 150px);

		.section-heading {
			.heading-title {
				font-size: 42px;
			}

			.heading-cta {
				margin-top: 30px;

				.button, a  {
					width: 100%;
					margin: 10px 0;
				}
			}
		}
	}
}

.section-slider-navigation {
	position: absolute;
	bottom: 100px;
	left: 50%;
	transform: translateX(-50%);

	.slider-dot {
		display: inline-block;
		height: 15px;
		width: 15px;
		background: white;
		margin: 10px;
		opacity: .4;
		border-radius: 50%;
		cursor: pointer;
		transition: .3s;

		&.is-current {
			background: $color-primary;
		}

		&:hover {
			transform: scale(1.3);
			opacity: 1;
			transition: .3s;
		}
	}

	@media (max-width: $breakpoint-small) {
		bottom: 50px;
	}
}

.section-route-pass {
	.map-content {
		height: auto;
	}
}

.section-buyng-vignette-steps {
	.buying-vignette-steps-image {
		display: flex;
    justify-content: space-around;
		width: 100%;
	}

	.no-bullets li {
		margin-left: 0;
		list-style-type: none;
	}
}

.steps-image-receipt {
	display: inline-block;
	width: 30%;
	vertical-align: top
}

div.documents-text-center {
	font-size: 20px;
	div {
		margin: 0 auto;
		width: 500px;
	}
	p {
		margin: 5px;
	}
	p:hover {
		text-decoration: underline;
	  }
}

@media (max-width: $breakpoint-small) {
	div.documents-text-center {
		font-size: 16px;
		div {
			width: 350px;
		}
	}
}

.price-container-end {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.price-value {
	margin-right: 4px;
}

.price-container-start {
	display: flex;
	justify-content: flex-start;
}

.span-right {
	display: inline-block;
	width: 100px;
	text-align: right;
	margin-right: 4px;
}

.span-left {
	display: inline-block;
	width: 100px;
	text-align: left;
}