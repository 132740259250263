// Site header global styles (everything inside .site-header)
// While you can nest with &- it's easier to read when components are separate.

// The moment we hide the forecast widget (going down)
$breakpoint-header-forecast: 1400px;

$breakpoint-header-menu-large: 1500px;  // We break the main menu down a bit from it's large desktop state
$breakpoint-header-menu-medium: 1280px; // This time the menu goes out
$breakpoint-header-time: 1100px; 		// The time again goes small
$breakpoint-header-widgets: 850px; 		// Hide the top black bar

// Other variables just for the header:
$header-icon-padding: 20px;
$header-icon-mobile-size: 22px;
$header-mobile-height: 60px;

// mixins for reusing styles in header dropdowns
%mixin-dropdown {
	position: absolute;
	top: 100%;
	width: 280px;
	background: $color-header-dropdown;
	right: 0;
	opacity: 0;
	transition: .3s;
	transform: scaleY(0);
	pointer-events: none;
	transform-origin: top center;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	box-shadow: 0 3px 6px rgba(0,0,0,.16);
	min-width: 310px;
	padding: 15px 0;
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
}

%mixin-dropdown-hover {
	opacity: 1;
	transition: .3s;
	transform: scaleY(1);
	transform-origin: top center;
	pointer-events: all;		
}

%mixin-dropdown-item {
	display: block;
	cursor: pointer;
	padding: .8em 1.5em;	
	color: $color-text;
	&:hover {
		background: rgba($color-text-muted, .1);
	}	
}

.site-header {
	position: relative;
	// height: $layout-header-height;
	z-index: 10;

	&-simple {
		.site-header-main {
			background: none;
		}

		.site-header-inner {
			justify-content: space-between;
		}

		.site-login .menu-item a {
			color: $color-text;
		}

		.site-logo {
			background-image: url(../images/logo-color.svg);
		}
	}

	&-mobilenav {
		display: none;
	}
	
	&.headroom {
		width: 100%;
		transition: .5s;
		position: fixed;
		top: 0;

		.popup-cart {
			position: absolute;
			top: 89px;
			left: 0;
			z-index: 10;
			width: 400px;
			padding: 20px;
			background: white;
			border: 1px solid #CACACA;
			border-top: none;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.7);

			.cart-buttons {
				display: flex;
				justify-content: space-between;
			}

			@media (max-width: $breakpoint-header-menu-large) {
				left: calc(50% - 250px);
			}
			@media (max-width: $breakpoint-mobile) {
				left: calc(50% - 200px)
			}
			@media (max-width: $breakpoint-header-menu-medium) {
				top: 59px
			}
		}
		+ .site-content {
			padding-top: 60px;
			@media (min-width: $breakpoint-header-menu-medium) {
				padding-top: 120px;
			}
		}
		
		&.headroom--not-top {
			+ .site-content {
				@media (min-width: $breakpoint-header-menu-medium) {
					padding-top: 80px;
				}
			}
		}

		+ div.site-content.home{
			padding-top: 0;
		}
		
		@media (min-width: $breakpoint-header-menu-medium) {
			&.headroom--not-top {
				&.headroom {
					transform: translateY(-40px);
					transition: .5s;

					.popup-cart {
						transform: translateY(-20px);
						transition: .5s;
					}
				}
				.site-header-inner {
					transform: translateY(20px);				
					transition: .5s;
				}
			}
		}
	}
}

@media (min-width: $breakpoint-header-menu-medium) {
	&.headroom--not-top {
		&.headroom {
			transform: translateY(-40px);
			transition: .5s;
		}
		.site-header-inner {
			transform: translateY(20px);
			transition: .5s;
		}
	}
}

.site-branding {
	display: inline-block;
}

.site-header-widgets {
	background: black;
	color: white;
	font-size: 16px;
	display: flex;
	align-items: center;
	padding: 0;
	flex-grow: 1;
	position: relative;
	z-index: 90;

	&.is-hidden {
		display: none;
	}

	@at-root .site-header-widget-inner {
		display: flex;	
		align-items: center;
	}

	@at-root .site-header-widget {
		border-right: 1px solid rgba(white, .3);
		padding: 10px 0;
		flex-grow: 1;
		display: flex;	
		justify-content: center;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
			border-right: none;
		}
	}

	.widget-label {
		margin: 0 20px;
		white-space: nowrap;

		&:last-child {
			margin-right: 0;
		}

		&:first-child {
			margin-left: 0;
		}
	}

	// IN here we pretty much toggle the visibility of the long
	// or the short time string.
	.widget-date {
		.widget-date-short {
			display: none;
		}

		// Show the long one
		@media (min-width: 1700px) {
			.widget-date-short {
				display: inline-block;
			}
		}

		// Show the short one
		@media (max-width: 1700px) and (min-width: $breakpoint-header-forecast) {
			max-width: 180px;

			.widget-date-label {
				display: none;
			}

			.widget-date-short {
				display: inline-block;
			}
		}

		@media (max-width: $breakpoint-header-time) {
			flex-grow: 0;
			padding-right: 20px;

			.widget-date-label {
				display: none;
			}

			.widget-date-short {
				display: inline-block;
			}
		}
	}

	.weather-forecast {
		display: flex;
		align-content: center;
		min-width: 130px;

		.forecast-number {
			font-size: 26px;
			margin-left: 20px;
			margin-right: 10px;
			line-height: 1.3;
		}

		.forecast-icon {
			max-height: 44px;
		}

		// Manual values below as they are strictly related to the
		// content of the header. You can do this with JS and width math if needed.
		@media (max-width: $breakpoint-header-forecast) {
			display: none;
		}
	}

	.widget-forecast {
		// As least important one, it goes out first.
		@media (max-width: $breakpoint-header-forecast) {
			display: none;
		}
	}

	.widget-controller {
		width: 120px;
		flex-grow: 0;
		line-height: 18px;
		display: flex;
		align-items: center;
		padding-left: 20px;
		padding-right: 20px;

		// Fixing image to look nice :3
		img {
			height: 7px;
			width: 12px;
		}

		&-label {
			text-align: center;
			white-space: normal;
		}
	}

	.form-inline {
		display: flex;
	}

	// Form element stylings
	input[type="text"],
	select {
		background: #494949;
		border-radius: 5px;
		max-width: 100px;
		width: 100%;
		color: white;
		border: none;
		padding: 5px;
		margin: 0 5px;

		&::-webkit-input-placeholder {
			color: #777;
		}

		&::-moz-placeholder {
			color: #777;
		}

		&:-ms-input-placeholder {
			color: #777;
		}

		&:-moz-placeholder {
			color: #777;
		}
	}

	select {
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;

		background-image: url(../images/arrow_down_white.svg);
		background-repeat: no-repeat;
		background-position: right 10px center;
		background-size: 12px;

		&.form-select-wider {
			width: 145px;

		}
	}

	input[type="submit"] {
		@extend .button;
		@extend .button-primary;

		min-width: 50px;
		padding: 0;

		background-image: url(../images/arrow_right_white.svg);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 10px;
	}

	@media (max-width: $breakpoint-header-widgets) {
		display: none;
	}
}

.loader-image-on-button {
	width:18px;
	height:18px;
	margin-left:8px;
	position:absolute
}

.loader-image-on-refresh-button {
	width:18px;
	height:18px;
}

.loader-image-inside-input {
	width:18px;
	height:18px;
	margin-top: 18px;
}

.input-container {
	position: relative;
}

.icon-container {
	position: absolute;
	right: 10px;
	top: calc(50% - 10px);
}

.payment-button-yes{
	margin-top:20px;
	margin-bottom: 20px;
	width: 30%;
	float: left;
}

.payment-button-no{
	margin-top:20px;
	margin-bottom: 20px;
	width: 30%;
	float: right;
}

.site-header-main {
	background: rgba(50, 50, 50, 0.7);
	padding: 30px 0;
	transition: .5s;
	z-index: 80;

	%header-small {
		transform: translateY(-40px);

		.site-header-inner {
			transform: translateY(20px);		
		}
	}

	&.is-small {
		@extend %header-small;
	}

	.site-header-inner {
		display: flex;
		align-items: center;
		min-height: 60px;
		transition: .5s;
	}

	.menu-icon {
		display: block;
		width: #{$header-icon-padding + $header-icon-mobile-size};
		height: #{$header-icon-padding + $header-icon-mobile-size};
		padding: 10px;

		&-nav {
			margin-left: 8px;
		}
	}

	.menu-icon.menu-icon-lang {
		padding: 0;
		height: auto;
		margin-top: 10px;
		margin-left: 10px;
	}

	@at-root .site-navigation {
		flex-grow: 1;
		white-space: nowrap;

		.menu-icon {
			display: none;

			&-nav {
				cursor: pointer;
			}
		}

		.menu-item {
			display: inline-block;

			a {
				color: white;
				// padding: 1em 1.5em;
				padding: .8em 1em;;
				display: inline-block;
			}
		}

		// @media(max-width: $breakpoint-huge) {
		// 	.menu-item {
		// 		a {
		// 			padding: .8em 1em;
		// 		}
		// 	}
		// }

		@media (max-width: $breakpoint-header-menu-medium) {
			flex-grow: 0;

			.menu-main {
				display: none;
			}

			.menu-icon {
				display: inline-block;
			}
		}
	}

	@at-root .site-login {
		.menu-item {
			display: inline-block;

			a, div {
				color: white;
				padding: .8em 1.5em;
				display: inline-block;
			}
		}

		.menu-icon-profile {
			display: none;
		}

		.menu-icon {
			display: none;
		}

		@media (max-width: $breakpoint-header-menu-large) {
			.menu-links {
				display: none;
			}

			.menu-icon {
				display: inline-block;
			}
		}
	}

	@at-root .site-logo {
		text-indent: -9999px;
		// background: url(../images/logo_digitoll_color.svg) no-repeat center left;
		background: url(../images/logo_digitoll_color.svg) no-repeat center left;
		background-size: contain;
		max-height: 60px;
		margin-right: 10px;

		a {
			display: block;
			height: 62px;
			width: 220px;
		}

		@media (max-width: $breakpoint-header-menu-medium) {
			flex-grow: 1;

			a {
				height: 30px;
				width: 105px;
			}
		}
	}

	@at-root .menu-shopping-cart {
		margin-right: 20px;
		position: relative;

		&.menu-shopping-cart-hover {
			cursor: pointer;
		}

		.icon-cart {
			padding-left: 25px;
			margin-top: 12px;
			font-size: 30px;
		}

		@media(max-width: $breakpoint-huge) {
			margin-right: 20px;
		}

		@media (max-width: $breakpoint-header-menu-large) {
			.shopping-cart-icon {
				height: $header-icon-mobile-size;
				width: $header-icon-mobile-size;
			}

			.shopping-cart-items {
				font-size: 13px;
			}
		}
	}

	.dropdown-container:hover .menu-dropdown {
		@extend %mixin-dropdown-hover;
	}

	.dropdown-container {
		position: relative;
		transform: translateY(1px);
		.menu-dropdown {
			@extend %mixin-dropdown;
			.menu-item {
				@extend %mixin-dropdown-item;
			}
		}

		span {
			padding: 1em;
			color: white;
			cursor: default;
		}

		ul a {
			color: $color-text;
		}
	}

	@at-root .menu-language-picker {
		margin-right: 20px;
		position: relative;
		transform: translateY(1px);
		padding: #{$header-icon-padding - 1px}; // increase clickable area

		.language-current {
			cursor: pointer;
		}

		&.menu-language-picker-hover .language-dropdown {
			@extend %mixin-dropdown-hover;
		}

		.language-current,
		.language-select {
			text-indent: -9999px;
			display: block;
			height: 22px;
			width: 45px;
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
		}

		.language-select {
			margin-right: 10px;
		}

		.language-dropdown {
			@extend %mixin-dropdown;

			li {
				@extend %mixin-dropdown-item;
			}

			div {
				display: flex;
				align-items: center;
				width: 100%;
				cursor: pointer;
			}
		}

		@media(max-width: $breakpoint-huge) {
			margin-right: 0;
		}

		@media (max-width: $breakpoint-header-menu-large) {
			order: 2;
		}

		@media (max-width: $breakpoint-header-menu-medium) {
			display: none;
		}
	}

	@media (max-width: $breakpoint-header-menu-medium) {
		padding: 0;

		// Quickt setup the position of the 4 items in the menu:
		.site-logo {
			order: 0;
		}

		.site-navigation {
			order: 3;
		}

		.menu-shopping-cart {
			order: 1;
		}

		.site-login {
			order: 2;
		}
	}
}

.shopping-cart-items {
	width: 1.3em;
	height: 1.3em;
	line-height: 1.5em;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	background: $color-primary;
	border-radius: 50%;
	display: block;
	position: absolute;
	top: 10px;
	right: -5px;
}

.shopping-cart-section {
	top: -5px;
	left: 100%;
}

.site-header-mobilenav {
	position: absolute;
	top: $header-mobile-height;
	width: 100%;
	background: rgba(black, .9);
	max-height: 85vh;
	overflow-y: scroll;

	.menu-mobile .menu-item-submenu {
		text-align: right;
	}

	.menu-item {
		border-bottom: 1px solid rgba(white, .2);
		text-align: center;
		height: 60px;

		&:last-child {
			border-bottom: none;
		}

		a, div {
			padding: 1em 1.5em;
			display: block;
			color: white;
		}

		img.language-select {
			margin-top: 5px;
			float: left;
		}

		div.language-text {
			color: $color-white;
			padding-top: 18px;
		}

	}

	@media (max-width: $breakpoint-header-menu-medium) {
		&.is-visible {
			display: block;
		}
	}
}

nav.site-navigation-dropdown {

	@media (max-width: $breakpoint-header-menu-medium) {
		.menu-main {
			display: none;
		}
	}

	ul ul {
		min-width: 200px;
		display: block;
		opacity: 0;
		visibility: hidden;
		background: $color-text-muted; 
		padding: 0;
		position: absolute; 
		top: 100%;
		-webkit-transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
		-moz-transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
		-o-transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
		-ms-transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
		transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
	}

	ul li:hover {
		background: $color-header-hover;
		& > ul {
			opacity: 1;
			visibility: visible;
		}
	}

	ul {
		list-style: none;
		position: relative;
		display: inline-table;
	}

	ul:after {
		content: ""; 
		clear: both; 
		display: block;
	}

	ul li {
		float: left;
	}

	ul li ul{
		box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.7);
	}
	
	ul li a, ul li span {
		cursor: pointer;
		display: block; 
		padding: 12px 20px;
		color: $color-white; 
		text-decoration: none;
		outline: 0;
	}

	ul ul li {
		float: none; 
		position: relative;
	}

	ul ul li a, ul ul li span {
		padding: 15px 40px;
		color: $color-white;
	}	

	ul ul li a:hover, ul ul li span:hover {
		background: $color-header-hover;
	}

	ul ul ul {
		position: absolute; 
		left: 100%; 
		top:0;
	}

}