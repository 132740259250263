// Global settings
$global-layout-gutter: 20px;
$global-font-size: 18px;
$global-width: 1200px;


// Standart set media queries
$breakpoint-mobile: 480px;
$breakpoint-small: 	640px;
$breakpoint-medium: 960px;
$breakpoint-large: 	1200px;
$breakpoint-huge: 	1800px; // 1640px - row


// Layout
$layout-header-height: 70px;


// Colors:
$color-white: #FFFFFF;
$color-primary: #F5BA16;
$color-primary-hover: #FFD677;
$color-secondary: #a7a7a7;

$color-header: rgba(black, .6);
$color-header-solid: #666768;
$color-header-hover: #555657;
$color-text: #494949;
$color-text-muted: #777879;
$color-border: #CACACA;
$color-light: #F2F2F2;
$color-archive-light: #f5f5d7;
$color-archive-dark: #f6e4c7;

$color-status-active: #53D409;
$color-status-inactive: #FF6262;
$color-status-pending: #FFD800;

$color-header-dropdown: $color-white;

// Fonts
$font-serif: "Palatino Linotype", "Book Antiqua", Palatino, Georgia, "Times New Roman", serif;
$font-sans: Lato;
$font-heading: $font-sans;

$font-size-heading: 50px;