@font-face {
	font-family: 'Lato';
	font-weight: normal;
	src: url('../fonts/Lato-Regular.woff') format('woff'),
	url('../fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Lato';
	font-weight: bold;
	src: url('../fonts/Lato-Bold.woff') format('woff'),
	url('../fonts/Lato-Bold.ttf') format('truetype');
}