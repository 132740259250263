.popup-buttons button.button, .popup-buttons a.button {
	display: inline-block;
	margin: 5px;
	width: 100%;
	
	@media (min-width: $breakpoint-small) {
		width: 45%;
		max-width: 220px;
		min-width: 120px;
	}
}

.button {
	display: inline-block;
	border-radius: 5px;
	color: white;
	background-color: #333;
	border: none;
	padding: .7em 3em .8em;
	line-height: 1.3;

	&-secondary {
		background-color: $color-secondary;
	}

	&-narrow {
		padding-left: 1em;
		padding-right: 1em;
	}

	&-primary {
		background-color: $color-primary;
		transition: .3s;

		&.button-ghost {
			box-shadow: inset 0 0 0 2px $color-primary;
			color: $color-text;

			&:hover {
				box-shadow: inset 0 0 0 2px $color-primary-hover;
				background: transparent;
			}
		}
		&.nowrap {
			white-space: nowrap;
		}

		&:hover {
			background-color: $color-primary-hover;			
			transition: .3s;
		}
	}

	&-large {
		font-size: 24px;
	}

	&-ghost {
		background: transparent;
	}

	@media (max-width: $breakpoint-small) {
		font-size: 16px;

		&-large {
			font-size: 16px;
		}
	}
}

.submit-button-center {
	display: flex;
	justify-content: center;
}

.icon-button {
	background: transparent;
	border: none;
	cursor: pointer;
	height: 30px;

	img {
		width: 24px;
		height: 24px;
	}
}

.icon-button[disabled] {
	opacity: 0.3;
}

.icon-deliveries {
	position: absolute;
	right: 55px;
	z-index: 2;
	top: 72px;
	cursor: pointer;
	font-size: 30px !important;
}

.custom-button-holder {

	button.custom-button {
		padding: 10px;
		width: 250px;
		height: 50px;
		font-size: 20px;
		display: inline-block;

		@media (max-width: $breakpoint-small) {
			width: 100%;
		}

		img {
			width: 32px;
			float: right;
			margin-top: -3px;
		}
	}	

}

