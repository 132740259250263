/**
 * Return proper color depending on background.
 *
 * @param  {color} $color The background color
 * @param  {color} $light The text if BG is light
 * @param  {color} $dark  The text if BG is dark
 * @return {color}        The color of the text depending on background.
 */
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background-color: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

@font-face {
  font-family: 'Lato';
  font-weight: normal;
  src: url("../fonts/Lato-Regular.woff") format("woff"), url("../fonts/Lato-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Lato';
  font-weight: bold;
  src: url("../fonts/Lato-Bold.woff") format("woff"), url("../fonts/Lato-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "digitoll-font";
  src: url("../fonts/digitoll-font.eot");
  src: url("../fonts/digitoll-font.eot?#iefix") format("embedded-opentype"), url("../fonts/digitoll-font.woff") format("woff"), url("../fonts/digitoll-font.ttf") format("truetype"), url("../fonts/digitoll-font.svg#digitoll-font") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "digitoll-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "digitoll-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-plus:before {
  content: "\61"; }

.icon-pin:before {
  content: "\62"; }

.icon-phone:before {
  content: "\63"; }

.icon-cart:before {
  content: "\65"; }

.icon-calendar:before {
  content: "\66"; }

.icon-email:before {
  content: "\64"; }

.icon-chevron-down:before {
  content: "\67"; }

.icon-chevron-up:before {
  content: "\6a"; }

.icon-check:before {
  content: "\6b"; }

.icon-grid:before {
  content: "\6c"; }

.icon-easy:before {
  content: "\6d"; }

.icon-bookmark:before {
  content: "\6e"; }

.icon-key:before {
  content: "\6f"; }

.icon-trash-o:before {
  content: "\70"; }

.icon-facebook:before {
  content: "\72"; }

.icon-bars:before {
  content: "\74"; }

.icon-plus-circle:before {
  content: "\75"; }

.icon-export:before {
  content: "\76"; }

.icon-user-outline:before {
  content: "\71"; }

.icon-close:before {
  content: "\73"; }

.icon-info-circle:before {
  content: "\77"; }

.icon-pencil-square-o:before {
  content: "\68"; }

.icon-floppy-o:before {
  content: "\69"; }

.icon-angle-double-left:before {
  content: "\78"; }

.icon-angle-double-right:before {
  content: "\79"; }

.icon-angle-left:before {
  content: "\7a"; }

.icon-angle-right:before {
  content: "\41"; }

.icon-search:before {
  content: "\42"; }

.icon-cloud-download:before {
  content: "\43"; }

.icon-share-square-o:before {
  content: "\44"; }

.icon-file-text-o:before {
  content: "\45"; }

.icon-cloud-upload:before {
  content: "\49"; }

.icon-truck:before {
  content: "\47"; }

.icon-gps:before {
  content: "\48"; }

.icon-banknote:before {
  content: "\4b"; }

.icon-contract:before {
  content: "\4a"; }

.icon-print:before {
  content: "\46"; }

.icon-car:before {
  content: "\4c"; }

.icon-back:before {
  content: "\4e"; }

.icon-diff-added:before {
  content: "\50"; }

.icon-chain-broken:before {
  content: "\51"; }

.icon-shuffle:before {
  content: "\4d"; }

.icon-refresh:before {
  content: "\4f"; }

.icon-refresh[disabled] {
  opacity: 0.3; }

.icon-magic:before {
  content: "\53"; }

.icon-bell:before {
  content: "\54"; }

.icon-bell-o:before {
  content: "\55"; }

.icon-organization:before {
  content: "\52"; }

.icon-file-text:before {
  content: "\56"; }

.icon-credit-card-1:before {
  content: "\58"; }

.icon-book:before {
  content: "\5a"; }

meta.foundation-data-attribute-namespace {
  font-family: false; }

body,
button,
input,
select,
textarea {
  color: #404040;
  font-family: sans-serif;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5; }

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both; }

p {
  margin-bottom: 1.5em; }

dfn,
cite,
em,
i {
  font-style: italic; }

blockquote {
  margin: 0 1.5em; }

address {
  margin: 0 0 1.5em; }

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em; }

code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 15px;
  font-size: 0.9375rem; }

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help; }

mark,
ins {
  background: #fff9c0;
  text-decoration: none; }

big {
  font-size: 125%; }

dt {
  font-weight: bold; }

dd {
  margin: 0 1.5em 1.5em; }

table {
  margin: 0 0 1.5em;
  width: 100%; }

iframe#printJS {
  top: 0; }

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  font-family: Lato;
  font-size: 18px;
  line-height: 1.5;
  background: white;
  color: #494949; }

p:first-child {
  margin-top: 0; }

p:last-child {
  margin-bottom: 0; }

a {
  color: #F5BA16;
  text-decoration: none; }

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
  font-family: Lato;
  margin: 0; }

blockquote {
  font-style: italic; }

hr {
  height: 1px;
  border: none;
  width: 100%;
  background: #f0f0f0; }

img.alignright {
  margin: 20px 0 20px 20px; }

img.alignleft {
  margin: 20px 20px 20px 0; }

img.aligncenter {
  margin: 20px auto; }

img {
  max-width: 100%;
  height: auto; }

ol,
ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

#content[tabindex="-1"]:focus {
  outline: 0; }

embed,
iframe,
object {
  max-width: 100%; }

table {
  font-size: 20px; }
  table thead tr {
    border-bottom: none; }
  table tr {
    border-bottom: 1px solid #CACACA; }
  table th,
  table td {
    padding: 1em;
    text-align: left; }
    table th:first-child,
    table td:first-child {
      padding-left: 60px; }
    table th:last-child,
    table td:last-child {
      padding-right: 60px; }
  table th {
    background: #777879;
    color: white;
    font-weight: bold; }
  @media (max-width: 640px) {
    table th,
    table td {
      padding: 20px;
      font-size: 16px; }
      table th:last-child,
      table td:last-child {
        padding-right: 20px; } }

input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #CACACA; }

input::-moz-placeholder,
select::-moz-placeholder,
textarea::-moz-placeholder {
  color: #CACACA; }

input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #CACACA; }

input:-moz-placeholder,
select:-moz-placeholder,
textarea:-moz-placeholder {
  color: #CACACA; }

.formatted-text {
  padding: 40px; }
  .formatted-text ul {
    list-style-type: circle; }
    .formatted-text ul li {
      margin-left: 20px; }
  .formatted-text ul.square {
    list-style-type: square; }
  .formatted-text ul.decimal {
    list-style-type: decimal; }
  .formatted-text h3 {
    margin-top: 30px; }

.iban-list-container {
  border: 1px solid #CACACA;
  border-radius: 5px; }
  .iban-list-container .iban-list-ro {
    display: inline-block;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 10px;
    background-color: #EEE; }

@media (max-width: 640px) {
  div.heading-main.formatted-text.about-page {
    padding: 15px; } }

@media (max-width: 640px) {
  div.formatted-text.registration-steps {
    padding: 10px; } }

.center-ul {
  text-align: center; }
  .center-ul > ul {
    display: inline-block; }

.float-right {
  float: right; }

.float-left {
  float: left; }

.no-float,
.flost-none {
  float: none; }

.is-hidden {
  display: none; }

.cb {
  clear: both; }

hr.clear {
  border: none;
  background: transparent;
  clear: both; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-uppercase {
  text-transform: uppercase; }

.text-italic {
  font-style: italic; }

.text-white {
  color: white; }
  .text-white h1,
  .text-white h2,
  .text-white h3,
  .text-white h4,
  .text-white h5,
  .text-white h6 {
    color: white; }
  .text-white a {
    color: white; }
  .text-white a:hover {
    color: white; }

.text-primary {
  color: #F5BA16; }

.text-red {
  color: red;
  margin: 0 auto;
  padding: 10px 0; }

.text-large {
  font-size: 26px; }
  p.text-large {
    margin-top: 2.5em; }

.text-large-underline {
  box-shadow: 0 2px 0 0 #F5BA16;
  display: inline;
  padding-bottom: .5em;
  line-height: 1.9; }
  .text-large-underline-small-pd {
    padding-bottom: .1em;
    line-height: 1.2; }
  .text-large-underline-wide {
    padding-left: 1em;
    padding-right: 1em; }
  @media (max-width: 640px) {
    .text-large-underline {
      box-shadow: 0 3px 0 0 #F5BA16; } }

a.link-stealth {
  color: #494949; }

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.mt-0 {
  margin-top: 0 !important; }

.mt-4 {
  margin-top: 4px !important; }

.mt-8 {
  margin-top: 8px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mt-14 {
  margin-top: 14px !important; }

.mt-20 {
  margin-top: 20px; }

.mt-20-important {
  margin-top: 20px !important; }

.mt-30 {
  margin-top: 30px; }

.mt-34 {
  margin-top: 34px; }

.mt-40 {
  margin-top: 40px; }

.mt-50 {
  margin-top: 50px; }

.mt-60 {
  margin-top: 60px; }

.mt-70 {
  margin-top: 70px; }

.mt-80 {
  margin-top: 80px; }

.mt-90 {
  margin-top: 90px; }

.mt-100 {
  margin-top: 100px; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-10 {
  margin-bottom: 10px; }

.mb-20 {
  margin-bottom: 20px; }

.mb-30 {
  margin-bottom: 30px; }

.mb-20-important {
  margin-bottom: 20px !important; }

.mb-40 {
  margin-bottom: 40px; }

.mb-50 {
  margin-bottom: 50px; }

.mb-60 {
  margin-bottom: 60px; }

.mb-70 {
  margin-bottom: 70px; }

.mb-80 {
  margin-bottom: 80px; }

.mb-90 {
  margin-bottom: 90px; }

.mb-100 {
  margin-bottom: 100px; }

.pd-20 {
  padding: 20px !important; }

.pt-4 {
  padding-top: 4px; }

.pt-6 {
  padding-top: 6px; }

.min-width-800 {
  min-width: 800px; }

.position-initial {
  position: initial; }

.rotate45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.export-button-height {
  height: 26px; }

.custom-button-style {
  height: 26px;
  margin-right: 10px; }

.sticky-header {
  overflow: hidden; }
  .sticky-header .site-header-main {
    padding-bottom: 10px; }
  .sticky-header .site-content {
    height: 100vh; }
  .sticky-header .site-header.headroom.headroom--not-top.headroom {
    transform: unset !important;
    transition: unset !important;
    z-index: 50; }

.routes {
  display: flex; }
  .routes .icon-bars {
    position: absolute;
    z-index: 1;
    top: 150px;
    left: 50px;
    background-color: #FFFFFF;
    font-size: 30px;
    border-radius: 5px;
    height: 66px;
    text-align: center;
    line-height: 75px;
    width: 60px;
    cursor: pointer; }
  .routes.site-content {
    padding-top: 100px !important; }
  .routes .routes-menu {
    z-index: 10;
    width: 700px;
    background-color: #FFFFFF;
    left: -700px;
    transition: left 1s;
    overflow-y: auto;
    display: none; }
    .routes .routes-menu.menu-open {
      display: block; }
    .routes .routes-menu .menu-toll-charges {
      text-align: center;
      margin: 0 15px; }
    .routes .routes-menu .collapse-icon {
      font-size: 50px;
      cursor: pointer;
      position: absolute;
      left: 650px; }
    .routes .routes-menu .rt-tr-group {
      cursor: pointer; }
    .routes .routes-menu .date-picker-container .input-group {
      border-radius: 5px;
      background: #FFFFFF;
      min-height: unset;
      height: 40px;
      border: 1px solid lightgrey;
      width: 285px; }
      .routes .routes-menu .date-picker-container .input-group label {
        display: none; }
      .routes .routes-menu .date-picker-container .input-group div input {
        border: none;
        padding-left: 15px;
        height: 30px;
        padding-bottom: 0.7em; }
      .routes .routes-menu .date-picker-container .input-group .DayPicker {
        z-index: 100;
        position: absolute;
        background: #FFFFFF;
        border-left: 1px solid #CACACA;
        border-right: 1px solid #CACACA;
        border-bottom: 1px solid #CACACA; }
    .routes .routes-menu .menu-info-container {
      padding: 10px;
      display: flex;
      border-bottom: 1px solid #CACACA;
      border-top: 1px solid #CACACA;
      background-color: #FFFFFF; }
      .routes .routes-menu .menu-info-container .vehicle-info {
        margin: 10px 0;
        width: 250px; }
        .routes .routes-menu .menu-info-container .vehicle-info div {
          margin: 10px 0; }
      .routes .routes-menu .menu-info-container .routes-selection {
        display: flex;
        flex-direction: row;
        margin-right: 35px;
        flex-wrap: wrap;
        justify-content: flex-end; }
        .routes .routes-menu .menu-info-container .routes-selection label {
          cursor: pointer;
          margin: 10px 5px; }
        .routes .routes-menu .menu-info-container .routes-selection input[type="checkbox"] {
          cursor: pointer;
          margin-right: 5px; }
    .routes .routes-menu .menu-info-container.detailedMap {
      border-top: none; }
  .routes .routes-menu.detailedMap {
    width: 400px;
    left: -400px; }

#root {
  height: 100%; }

.react-datepicker-popper {
  z-index: 100 !important;
  box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.7); }
  .react-datepicker-popper > div {
    display: flex; }

.multi-select-input.searchable .react-select__input {
  margin-top: -10px;
  height: 24px; }

.css-62g3xt-dummyInput {
  padding: 4px !important;
  height: 1px !important; }

.css-1hwfws3 {
  background-color: white;
  border-radius: 5px; }

.css-1g48xl4-IndicatorsContainer {
  background-color: white !important;
  border-radius: 5px; }

.select2-input-sizes {
  text-align: left;
  font-size: 16px; }

.multi-select-input {
  height: 36px; }
  .multi-select-input .react-select__control {
    height: 36px;
    border-color: #CACACA;
    box-shadow: none;
    font-size: 16px; }
    .multi-select-input .react-select__control .react-select__value-container {
      overflow: inherit;
      height: 36px; }
      .multi-select-input .react-select__control .react-select__value-container .react-select__multi-value {
        margin-top: 3px; }
  .multi-select-input .react-select__menu {
    z-index: 10; }
  .multi-select-input .react-select__menu .react-select__option {
    padding: 0 10px;
    height: 24px;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left; }

.no-rounded-borders {
  flex-basis: 33%; }
  .no-rounded-borders .multi-select-input .react-select__control {
    background: white url(/static/media/arrow_down.b78451a6.svg) no-repeat center right 8px;
    min-height: 36px;
    padding-right: 36px;
    margin-top: 2px;
    border-radius: 0;
    border-right: 0; }
  .no-rounded-borders .multi-select-input .react-select__indicators {
    display: none; }

.no-rounded-borders-last .multi-select-input .react-select__control {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 1px solid #B3B3B3; }

.units-multiselect {
  display: flex;
  flex-flow: row;
  align-items: stretch; }
  .units-multiselect .react-select__control {
    flex: 1 auto;
    align-items: stretch;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 5px; }
  .units-multiselect .react-select__value-container {
    padding: .5em 3em .5em 2em;
    border-radius: 5px;
    outline: none; }
    .units-multiselect .react-select__value-container .react-select__input input {
      height: initial; }
  .units-multiselect .react-select__control--is-focused,
  .units-multiselect .react-select__control--is-focused:hover {
    border: 1px solid #CACACA;
    box-shadow: none; }

div.app-erp {
  height: 100%; }
  div.app-erp .grid-title-standalone {
    text-align: center;
    font-size: 22px;
    background-color: #FFFFFF;
    padding-bottom: 4px; }
  div.app-erp .split-layout .field-container {
    padding: 0 20px; }
    div.app-erp .split-layout .field-container .custom-button {
      position: relative;
      padding: 0;
      height: 36px; }
      div.app-erp .split-layout .field-container .custom-button .icon {
        position: absolute;
        left: 10px;
        color: #FFFFFF; }
      div.app-erp .split-layout .field-container .custom-button img {
        width: 28px;
        right: 4px;
        position: absolute;
        top: 7px; }
  @media (max-width: 960px) {
    div.app-erp .split-layout .field-container.reports {
      overflow-x: auto;
      height: 150px; } }
  div.app-erp .split-layout .form-inline {
    max-width: 4000px; }
  div.app-erp .multistep-picker {
    margin-top: 0;
    padding-top: 120px; }
  div.app-erp .row {
    max-width: 2000px; }
  div.app-erp .site-content {
    height: 100%; }
    div.app-erp .site-content .react-split {
      height: 100%;
      padding: 0 20px 20px 20px; }
  div.app-erp .bank-file-upload {
    width: fit-content;
    margin: 50px auto;
    text-align: center; }
  div.app-erp .nosplit {
    padding: 0 20px 20px 20px; }
  div.app-erp .gutter {
    background-color: #F5BA16;
    cursor: row-resize; }
  div.app-erp .site-header.headroom + .site-content {
    margin-top: 0;
    padding-top: 70px; }
  div.app-erp .custom-checkbox label {
    font-size: 16px;
    vertical-align: text-bottom; }
  div.app-erp .custom-checkbox input[type=checkbox] + label {
    height: 22px;
    width: 22px; }
  div.app-erp h1.site-logo {
    max-height: 38px; }
  div.app-erp .site-header-main {
    padding: 0; }
    div.app-erp .site-header-main ul.menu-main {
      vertical-align: bottom; }
  div.app-erp .aggregated-reports-filters {
    height: auto;
    overflow-y: hidden;
    overflow: visible !important; }
    div.app-erp .aggregated-reports-filters .button-filters-wrapper {
      position: absolute;
      top: 76px;
      right: 40px;
      background: white;
      border-radius: 5px; }
      div.app-erp .aggregated-reports-filters .button-filters-wrapper button {
        padding: 10px 20px;
        width: 200px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      div.app-erp .aggregated-reports-filters {
        /* IE10+ CSS styles*/ }
        div.app-erp .aggregated-reports-filters .field-container .form-inline {
          margin-top: 2px; } }

.form-file-upload {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 65px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #CACACA; }
  .form-file-upload-hidden {
    display: none; }

.add-file {
  margin-top: 20px;
  text-align: left;
  font-size: 1em;
  line-height: 20px;
  cursor: pointer; }
  .add-file:before {
    padding-right: 5px;
    vertical-align: middle;
    line-height: 20px; }

.file-input-wrapper {
  position: relative; }
  .file-input-wrapper .input-group {
    margin-bottom: 0; }
  .file-input-wrapper .remove-file {
    position: absolute;
    top: 30px;
    right: 1px; }

.file-input-label {
  display: block;
  margin-top: 40px;
  margin-bottom: 2px;
  font-size: 20px;
  font-weight: bold;
  text-align: left; }

.success-message {
  position: absolute;
  top: 0;
  left: 0; }
  .success-message .popup-wrapper.is-visible {
    width: 100%;
    height: 100%; }
    .success-message .popup-wrapper.is-visible .popup-box {
      position: absolute;
      top: 100px;
      left: 25%; }
      @media (max-width: 960px) {
        .success-message .popup-wrapper.is-visible .popup-box {
          left: 0; } }
      .success-message .popup-wrapper.is-visible .popup-box .popup-title {
        padding-right: 40px; }

.partner-pop-up .popup-box {
  width: 95%;
  height: 95%; }

.clear-background {
  background: white;
  padding-bottom: 15px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: 34px;
  font-weight: bold; }

.route-map-popup .popup-box {
  width: 95%;
  height: 95%; }
  .route-map-popup .popup-box div.popup-title {
    margin-bottom: 5px !important; }
  .route-map-popup .popup-box div.route-map-container {
    height: 100% !important; }
  .route-map-popup .popup-box .routes.site-content {
    padding-top: 0 !important;
    position: relative; }
  .route-map-popup .popup-box .collapse-icon, .route-map-popup .popup-box .icon-bars {
    display: none; }
  .route-map-popup .popup-box .menu-info-container {
    margin-bottom: 10px; }
  .route-map-popup .popup-box .routes .routes-menu {
    height: 100%;
    overflow: unset; }
    .route-map-popup .popup-box .routes .routes-menu .info-data {
      position: absolute;
      top: 0px;
      right: 14px; }
    .route-map-popup .popup-box .routes .routes-menu .react-datepicker__close-icon {
      display: none; }
    .route-map-popup .popup-box .routes .routes-menu .ReactTable .rt-tbody .rt-td input {
      width: 100%; }
    .route-map-popup .popup-box .routes .routes-menu .send-segments-button {
      border: none;
      border-radius: 0; }
    .route-map-popup .popup-box .routes .routes-menu .no-bottom-border {
      border-bottom: none; }
    .route-map-popup .popup-box .routes .routes-menu .no-margin-bottom {
      margin-bottom: 0; }
    .route-map-popup .popup-box .routes .routes-menu .no-margin-top {
      margin-top: 0 !important; }
    .route-map-popup .popup-box .routes .routes-menu .no-y-padding {
      padding-top: 0;
      padding-bottom: 0; }
    .route-map-popup .popup-box .routes .routes-menu .input-group.daterange .custom-checkbox {
      display: inline-block;
      width: 90px;
      height: 25px; }
    .route-map-popup .popup-box .routes .routes-menu .input-group.daterange .title {
      width: 200px; }
    .route-map-popup .popup-box .routes .routes-menu .input-group.daterange.detailedMap .title {
      width: 300px; }
    .route-map-popup .popup-box .routes .routes-menu .input-group.coordinates.detailedMap .title {
      width: 300px; }
    .route-map-popup .popup-box .routes .routes-menu .input-group.coordinates, .route-map-popup .popup-box .routes .routes-menu .input-group.daterange, .route-map-popup .popup-box .routes .routes-menu .input-group.segment-search {
      display: block;
      text-align: left;
      min-height: 50px;
      margin-bottom: 15px;
      border-bottom: 1px solid lightgray; }
      .route-map-popup .popup-box .routes .routes-menu .input-group.coordinates label, .route-map-popup .popup-box .routes .routes-menu .input-group.daterange label, .route-map-popup .popup-box .routes .routes-menu .input-group.segment-search label {
        width: calc(100% - 410px);
        display: inline-block;
        padding-left: 10px; }
      .route-map-popup .popup-box .routes .routes-menu .input-group.coordinates .custom-checkbox label, .route-map-popup .popup-box .routes .routes-menu .input-group.daterange .custom-checkbox label, .route-map-popup .popup-box .routes .routes-menu .input-group.segment-search .custom-checkbox label {
        height: 22px;
        width: 22px;
        margin-bottom: 0; }
      .route-map-popup .popup-box .routes .routes-menu .input-group.coordinates .custom-checkbox .custom-checkbox-label, .route-map-popup .popup-box .routes .routes-menu .input-group.daterange .custom-checkbox .custom-checkbox-label, .route-map-popup .popup-box .routes .routes-menu .input-group.segment-search .custom-checkbox .custom-checkbox-label {
        margin-bottom: -3px;
        margin-left: 0; }
      .route-map-popup .popup-box .routes .routes-menu .input-group.coordinates input, .route-map-popup .popup-box .routes .routes-menu .input-group.daterange input, .route-map-popup .popup-box .routes .routes-menu .input-group.segment-search input {
        height: 36px;
        width: 170px;
        padding: 5px 10px;
        margin-right: 10px;
        font-size: 19px; }
    .route-map-popup .popup-box .routes .routes-menu .input-group.coordinates.detailedMap, .route-map-popup .popup-box .routes .routes-menu .input-group.daterange.detailedMap, .route-map-popup .popup-box .routes .routes-menu .input-group.segment-search.detailedMap {
      border-bottom: none; }
    .route-map-popup .popup-box .routes .routes-menu .segments-table .rt-td {
      height: 43px;
      line-height: 43px; }
    .route-map-popup .popup-box .routes .routes-menu .date-picker-container .input-group {
      width: 223px; }
  .route-map-popup .popup-box .menu-toll-charges {
    height: calc(100% - 166px); }
  .route-map-popup .popup-box .menu-toll-charges.small {
    height: calc(100% - 194px); }
    .route-map-popup .popup-box .menu-toll-charges.small .toggle-tabs .tabs-header-item {
      font-size: 17px;
      height: 45px; }
    .route-map-popup .popup-box .menu-toll-charges.small .toggle-tabs {
      height: 100%; }
      .route-map-popup .popup-box .menu-toll-charges.small .toggle-tabs .tabs-main {
        height: calc(100% - 64px); }
        .route-map-popup .popup-box .menu-toll-charges.small .toggle-tabs .tabs-main .tabs-item {
          height: 100%;
          padding: 0; }
  .route-map-popup .popup-box .mapDetailsText {
    font-size: 20px; }
  .route-map-popup .popup-box .map-info-panel-charge-object {
    padding: 8px;
    margin-bottom: 3px;
    border: 1px solid lightgray;
    border-radius: 9px; }
  .route-map-popup .popup-box .map-info-panel-charge-object:hover {
    background-color: lightgray; }
  .route-map-popup .popup-box .map-info-panel-charge-object.selected {
    background-color: #e6e6e6; }
  .route-map-popup .popup-box div.toll-popup-title {
    font-weight: bold;
    text-align: center;
    font-size: 15px;
    margin-bottom: 3px; }
  .route-map-popup .popup-box .toll-popup-route-segment-name {
    margin-bottom: 10px; }
  .route-map-popup .popup-box .menu-toll-charges.small.search-segments {
    height: calc(100% - 260px); }
  .route-map-popup .popup-box .vehicle-info {
    text-align: left; }
    .route-map-popup .popup-box .vehicle-info .from-to-date-picker {
      position: absolute;
      right: -13px;
      top: 32px; }
      .route-map-popup .popup-box .vehicle-info .from-to-date-picker input {
        width: 160px;
        margin-left: 10px; }
      .route-map-popup .popup-box .vehicle-info .from-to-date-picker .icon-search {
        font-size: 21px;
        margin-left: 8px; }
  .route-map-popup .popup-box .vehicle-info.hidden-legend {
    margin-bottom: 0; }
  .route-map-popup .popup-box .route-map {
    height: 100% !important; }
  .route-map-popup .popup-box .routes-selection.calendar-only {
    padding-top: 50px; }
    .route-map-popup .popup-box .routes-selection.calendar-only .input-group {
      margin-left: 110px; }
  .route-map-popup .popup-box .map-legend-container {
    padding: 5px 0 0 700px; }
  .route-map-popup .popup-box .map-legend-container.detailedMap {
    padding: 5px 0 0 400px; }
  .route-map-popup .popup-box .map-legend-container label {
    float: left;
    margin-right: 10px; }
    .route-map-popup .popup-box .map-legend-container label .icon-cloud-download {
      font-size: 30px; }
  .route-map-popup .popup-box .map-legend-container .download-button-container {
    float: right; }
  .route-map-popup .popup-box .map-loader {
    z-index: 9999;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.5;
    vertical-align: middle;
    padding-top: 20%; }
  .route-map-popup .popup-box .add-segment-button {
    border: none;
    background-color: transparent; }
  .route-map-popup .popup-box .add-segment-button:hover {
    color: #59b7ff; }

.home-banner {
  width: 100%;
  margin-bottom: 50px;
  margin-top: 50px; }

.home-banner.car-section {
  margin-top: 0; }

.popup.popup-erp {
  position: absolute; }
  .popup.popup-erp .popup-wrapper.is-visible .popup-box {
    padding: 20px;
    box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.7);
    max-width: 95%; }
    .popup.popup-erp .popup-wrapper.is-visible .popup-box .panel-edit {
      max-width: 100%;
      width: 970px;
      height: 424px;
      overflow: visible; }
  .popup.popup-erp.scrollable .popup-wrapper.is-visible .popup-box {
    max-height: 95%;
    overflow-x: hidden;
    overflow-y: auto; }

.popup.popup-erp.partner-pop-up .popup-wrapper.is-visible .popup-box .panel-edit.panel-popup.edit-vehicles.tollPopUp {
  width: 100%; }

.popup.popup-erp.partner-pop-up .popup-wrapper.is-visible .popup-box .panel-edit {
  width: 100%;
  height: 550px; }

.field-container.partner-popup {
  overflow-y: auto;
  height: 100%; }

.popup.popup-erp .popup-wrapper.is-visible .popup-box .panel-edit.panel-popup.edit-vehicles.tollPopUp {
  height: 85%; }

@media (max-height: 900px) {
  .field-container.partner-popup {
    overflow-y: auto;
    height: 100%; }
  .popup.popup-erp .popup-wrapper.is-visible .popup-box .panel-edit.panel-popup.edit-vehicles.tollPopUp {
    height: 85%; } }

@media (max-height: 700px) {
  .field-container.partner-popup {
    overflow-y: auto;
    height: 390px; }
  .popup.popup-erp .popup-wrapper.is-visible .popup-box .panel-edit.panel-popup.edit-vehicles.tollPopUp {
    height: 450px; }
  .popup.popup-erp.partner-pop-up .popup-wrapper.is-visible .popup-box .panel-edit {
    height: 450px !important; } }

div.app-erp .react-table-filterable, .popup.popup-erp .react-table-filterable {
  height: 100%; }
  div.app-erp .react-table-filterable .header-container-wrapper, .popup.popup-erp .react-table-filterable .header-container-wrapper {
    position: relative; }
  div.app-erp .react-table-filterable .header-container, .popup.popup-erp .react-table-filterable .header-container {
    position: absolute;
    z-index: 1;
    white-space: nowrap; }
  div.app-erp .react-table-filterable .custom-buttons .custom-button-holder button, .popup.popup-erp .react-table-filterable .custom-buttons .custom-button-holder button {
    height: 26px;
    vertical-align: top;
    font-size: 16px;
    padding: 2px;
    width: 150px; }
    div.app-erp .react-table-filterable .custom-buttons .custom-button-holder button img, .popup.popup-erp .react-table-filterable .custom-buttons .custom-button-holder button img {
      width: 18px;
      height: 18px;
      margin: 2px; }

div.delict-search-form-erp {
  display: inline-block;
  margin: 0 30px 0 0;
  width: 30%; }

div.delict-search-form-erp.searchBtn {
  position: relative;
  top: 53px;
  margin: 0 30px 0 0;
  width: 250px; }

@media (max-width: 960px) {
  div.delict-search-form-erp {
    width: 100%;
    margin: 0px; }
  div.delict-search-form-erp.searchBtn {
    position: relative;
    top: 0; } }

div.delict-search-form-erp .input-group {
  font-size: 16px; }
  div.delict-search-form-erp .input-group input, div.delict-search-form-erp .input-group select {
    height: 50px; }

div.app-erp .panel-edit, .popup.popup-erp .panel-edit {
  height: 100%;
  overflow-y: auto;
  background-color: #F2F2F2; }
  div.app-erp .panel-edit div.form-error-msg, .popup.popup-erp .panel-edit div.form-error-msg {
    font-size: 16px; }
  div.app-erp .panel-edit div.form-error-msg-red, .popup.popup-erp .panel-edit div.form-error-msg-red {
    font-size: 16px;
    margin-top: 20px;
    color: red; }
  div.app-erp .panel-edit div.form-success-msg, .popup.popup-erp .panel-edit div.form-success-msg {
    font-size: 16px;
    margin-top: 20px;
    color: green; }
  div.app-erp .panel-edit .box-form, .popup.popup-erp .panel-edit .box-form {
    height: 100%; }
  div.app-erp .panel-edit .toggle-tabs-content-scroll, .popup.popup-erp .panel-edit .toggle-tabs-content-scroll {
    overflow: auto; }
  div.app-erp .panel-edit div.route-select .toggle-tabs .tabs-header-item.is-current:hover, .popup.popup-erp .panel-edit div.route-select .toggle-tabs .tabs-header-item.is-current:hover {
    background-color: transparent; }
  div.app-erp .panel-edit div.route-select .toggle-tabs .tabs-header-item.is-current, .popup.popup-erp .panel-edit div.route-select .toggle-tabs .tabs-header-item.is-current {
    background-color: transparent;
    border-bottom: 3px solid #F5BA16; }
  div.app-erp .panel-edit div.route-select .toggle-tabs .tab-content, .popup.popup-erp .panel-edit div.route-select .toggle-tabs .tab-content {
    padding-top: 50px;
    background-color: transparent;
    height: 100%; }
  div.app-erp .panel-edit .toggle-tabs, .popup.popup-erp .panel-edit .toggle-tabs {
    height: 100%;
    position: relative; }
    div.app-erp .panel-edit .toggle-tabs .tabs-header-item, .popup.popup-erp .panel-edit .toggle-tabs .tabs-header-item {
      margin-bottom: 0;
      padding-bottom: 0;
      font-size: 18px; }
      div.app-erp .panel-edit .toggle-tabs .tabs-header-item .tab-error, .popup.popup-erp .panel-edit .toggle-tabs .tabs-header-item .tab-error {
        margin-right: 20px;
        font-size: 16px;
        color: red;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 350px;
        display: inline-block; }
    div.app-erp .panel-edit .toggle-tabs .tabs-header-item.hidden-tab, .popup.popup-erp .panel-edit .toggle-tabs .tabs-header-item.hidden-tab {
      display: none; }
    div.app-erp .panel-edit .toggle-tabs .tabs-header-children, .popup.popup-erp .panel-edit .toggle-tabs .tabs-header-children {
      cursor: default; }
      div.app-erp .panel-edit .toggle-tabs .tabs-header-children button, .popup.popup-erp .panel-edit .toggle-tabs .tabs-header-children button {
        cursor: pointer; }
    div.app-erp .panel-edit .toggle-tabs .form-inline, .popup.popup-erp .panel-edit .toggle-tabs .form-inline {
      margin: 0;
      max-width: 2000px; }
      div.app-erp .panel-edit .toggle-tabs .form-inline .obu-return-checkbox, .popup.popup-erp .panel-edit .toggle-tabs .form-inline .obu-return-checkbox {
        margin-top: 5px; }
        div.app-erp .panel-edit .toggle-tabs .form-inline .obu-return-checkbox ~ label, .popup.popup-erp .panel-edit .toggle-tabs .form-inline .obu-return-checkbox ~ label {
          font-weight: bold;
          font-size: 16px;
          white-space: nowrap; }
    div.app-erp .panel-edit .toggle-tabs .tabs-header-item.is-current:hover, .popup.popup-erp .panel-edit .toggle-tabs .tabs-header-item.is-current:hover {
      background-color: #F2F2F2; }
    div.app-erp .panel-edit .toggle-tabs .tabs-header-item.is-current, .popup.popup-erp .panel-edit .toggle-tabs .tabs-header-item.is-current {
      background-color: #F2F2F2;
      border: 0; }
    div.app-erp .panel-edit .toggle-tabs .tabs-header, .popup.popup-erp .panel-edit .toggle-tabs .tabs-header {
      position: absolute;
      width: 100%; }
    div.app-erp .panel-edit .toggle-tabs .tab-content, .popup.popup-erp .panel-edit .toggle-tabs .tab-content {
      padding-top: 50px;
      background-color: #F2F2F2;
      height: 100%; }
      div.app-erp .panel-edit .toggle-tabs .tab-content .header-container, .popup.popup-erp .panel-edit .toggle-tabs .tab-content .header-container {
        background-color: #F2F2F2; }
    div.app-erp .panel-edit .toggle-tabs .tabs-header-children, .popup.popup-erp .panel-edit .toggle-tabs .tabs-header-children {
      margin-right: 0;
      padding-right: 0;
      max-height: 40px; }
  div.app-erp .panel-edit .field-container .form-inline, .popup.popup-erp .panel-edit .field-container .form-inline {
    margin-bottom: 0; }
    div.app-erp .panel-edit .field-container .form-inline .input-group, .popup.popup-erp .panel-edit .field-container .form-inline .input-group {
      min-height: 70px;
      margin-bottom: 0; }
      div.app-erp .panel-edit .field-container .form-inline .input-group .inside-button, .popup.popup-erp .panel-edit .field-container .form-inline .input-group .inside-button {
        position: absolute;
        height: 30px;
        width: 30px;
        right: 3px;
        bottom: 3px; }
        div.app-erp .panel-edit .field-container .form-inline .input-group .inside-button .icon, .popup.popup-erp .panel-edit .field-container .form-inline .input-group .inside-button .icon {
          margin-left: -4px; }
      div.app-erp .panel-edit .field-container .form-inline .input-group label, .popup.popup-erp .panel-edit .field-container .form-inline .input-group label {
        font-size: 16px;
        margin-bottom: 2px;
        padding-right: 5px; }
      div.app-erp .panel-edit .field-container .form-inline .input-group input, div.app-erp .panel-edit .field-container .form-inline .input-group select, .popup.popup-erp .panel-edit .field-container .form-inline .input-group input, .popup.popup-erp .panel-edit .field-container .form-inline .input-group select {
        padding: 8px;
        height: 36px;
        font-size: 16px; }
      div.app-erp .panel-edit .field-container .form-inline .input-group select, .popup.popup-erp .panel-edit .field-container .form-inline .input-group select {
        padding-right: 36px;
        background: white url(../images/arrow_down.svg) no-repeat center right 8px; }
    div.app-erp .panel-edit .field-container .form-inline .textarea, .popup.popup-erp .panel-edit .field-container .form-inline .textarea {
      margin-top: 10px; }
      div.app-erp .panel-edit .field-container .form-inline .textarea textarea, .popup.popup-erp .panel-edit .field-container .form-inline .textarea textarea {
        overflow-y: auto;
        min-height: 100px;
        padding: 8px; }
    div.app-erp .panel-edit .field-container .form-inline .warehouse-link, .popup.popup-erp .panel-edit .field-container .form-inline .warehouse-link {
      color: #F5BA16;
      cursor: pointer; }

div.app-erp .panel-edit.edit-obu-form {
  overflow: unset; }

div.app-erp .ReactTable, .popup.popup-erp .ReactTable {
  padding-top: 30px;
  height: 100%;
  background-color: #FFFFFF;
  border: none; }
  div.app-erp .ReactTable .rt-table .rt-thead.-header .rt-tr .rt-th, .popup.popup-erp .ReactTable .rt-table .rt-thead.-header .rt-tr .rt-th {
    padding-top: 10px;
    height: 40px; }
  div.app-erp .ReactTable .rt-tbody, .popup.popup-erp .ReactTable .rt-tbody {
    overflow-y: auto;
    overflow-x: hidden; }
    div.app-erp .ReactTable .rt-tbody .rt-tr-group, .popup.popup-erp .ReactTable .rt-tbody .rt-tr-group {
      max-height: 32px;
      border: 0; }
    div.app-erp .ReactTable .rt-tbody .rt-tr.-even:not(.-padRow), .popup.popup-erp .ReactTable .rt-tbody .rt-tr.-even:not(.-padRow) {
      background-color: #F2F2F2; }
    div.app-erp .ReactTable .rt-tbody .rt-tr.archive-file.-even:not(.-padRow), .popup.popup-erp .ReactTable .rt-tbody .rt-tr.archive-file.-even:not(.-padRow) {
      background-color: #f6e4c7; }
    div.app-erp .ReactTable .rt-tbody .rt-tr.archive-file.-odd:not(.-padRow), .popup.popup-erp .ReactTable .rt-tbody .rt-tr.archive-file.-odd:not(.-padRow) {
      background-color: #f5f5d7; }
    div.app-erp .ReactTable .rt-tbody .rt-tr:not(.-padRow) .rt-td, .popup.popup-erp .ReactTable .rt-tbody .rt-tr:not(.-padRow) .rt-td {
      border-right: 1px solid rgba(0, 0, 0, 0.05); }
    div.app-erp .ReactTable .rt-tbody .rt-tr.rt-row-selected, .popup.popup-erp .ReactTable .rt-tbody .rt-tr.rt-row-selected {
      background-color: #FFD677 !important; }
    div.app-erp .ReactTable .rt-tbody .rt-td, .popup.popup-erp .ReactTable .rt-tbody .rt-td {
      height: 26px;
      padding: 2px 8px;
      border: 0; }
  div.app-erp .ReactTable .grid-icon, .popup.popup-erp .ReactTable .grid-icon {
    padding-top: 0; }
  div.app-erp .ReactTable .grid-icon.icon-loader, .popup.popup-erp .ReactTable .grid-icon.icon-loader {
    margin-top: 0; }

.button-center-submit {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 30%; }

.button-left-submit {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 30%;
  float: left; }

.button-right-submit {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 30%;
  float: right; }

.popup-description {
  margin: 60px;
  fontSize: 20px; }

.popup-heading {
  display: block;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
  margin: 60px;
  font-size: 22px; }

.map-content, .panel-popup.route-details {
  display: flex;
  padding: 20px; }
  .map-content div.left-panel, .panel-popup.route-details div.left-panel {
    width: 450px;
    padding-right: 20px;
    background: #FFF; }
    .map-content div.left-panel .button-primary, .map-content div.left-panel .site-header-widgets input[type="submit"], .site-header-widgets .map-content div.left-panel input[type="submit"], .panel-popup.route-details div.left-panel .button-primary, .panel-popup.route-details div.left-panel .site-header-widgets input[type="submit"], .site-header-widgets .panel-popup.route-details div.left-panel input[type="submit"] {
      width: 180px;
      height: 40px;
      padding: 6px;
      font-size: 20px; }
    .map-content div.left-panel .route-pass-summary .message-container, .panel-popup.route-details div.left-panel .route-pass-summary .message-container {
      font-size: 12px;
      border: 2px solid red;
      border-radius: 5px;
      margin-bottom: 20px;
      padding: 5px; }
      .map-content div.left-panel .route-pass-summary .message-container a, .panel-popup.route-details div.left-panel .route-pass-summary .message-container a {
        font-size: 16px;
        color: red; }
      .map-content div.left-panel .route-pass-summary .message-container a:hover, .panel-popup.route-details div.left-panel .route-pass-summary .message-container a:hover {
        text-decoration: underline; }
    .map-content div.left-panel .route-pass-summary table, .panel-popup.route-details div.left-panel .route-pass-summary table {
      font-size: 16px; }
      .map-content div.left-panel .route-pass-summary table th, .map-content div.left-panel .route-pass-summary table td, .panel-popup.route-details div.left-panel .route-pass-summary table th, .panel-popup.route-details div.left-panel .route-pass-summary table td {
        padding: 1px 10px; }
      .map-content div.left-panel .route-pass-summary table td:last-child, .panel-popup.route-details div.left-panel .route-pass-summary table td:last-child {
        text-align: right; }
    .map-content div.left-panel .route-pass-summary .summary-header, .panel-popup.route-details div.left-panel .route-pass-summary .summary-header {
      font-size: 16px;
      font-weight: bold; }
    .map-content div.left-panel .route-pass-summary .print-btn, .panel-popup.route-details div.left-panel .route-pass-summary .print-btn {
      float: right;
      cursor: pointer; }
    .map-content div.left-panel .route-pass-summary .button-container, .panel-popup.route-details div.left-panel .route-pass-summary .button-container {
      text-align: center; }
  @media (max-width: 640px) {
    .map-content, .panel-popup.route-details {
      padding: 0; }
      .map-content div.left-panel, .panel-popup.route-details div.left-panel {
        padding-right: 0; }
        .map-content div.left-panel .route-pass-summary table td, .panel-popup.route-details div.left-panel .route-pass-summary table td {
          font-size: 12px; } }
  .map-content .leaflet-container, .panel-popup.route-details .leaflet-container {
    flex: 1; }

.header-category {
  margin-left: 20px;
  font-weight: bold; }

.height-auto {
  height: auto !important;
  min-height: 424px; }

.div-upload-obu {
  padding: 1vw 0 1vw;
  justify-content: center;
  display: flex; }

.obu-list-wrapper {
  margin-top: 10px; }
  .obu-list-wrapper-label {
    font-size: 16px;
    font-weight: bold;
    text-align: left; }
  .obu-list-wrapper .obu-list {
    display: flex;
    flex-wrap: wrap; }
    .obu-list-wrapper .obu-list-item {
      display: flex;
      padding: 2px 12px 0;
      border: 1px solid #CACACA;
      border-radius: 4px;
      background: white; }
      .obu-list-wrapper .obu-list-item .icon-plus {
        padding-left: 5px;
        transform: rotate(45deg);
        font-size: 12px;
        color: #CACACA;
        cursor: pointer; }

.obu-list-number {
  position: relative; }
  .obu-list-number input {
    border-right: 1px solid #CACACA !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important; }
  .obu-list-number .icon-plus-circle {
    position: absolute;
    top: 39px;
    right: 5px;
    cursor: pointer; }

.account-close-reasons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px; }

.cursor-default {
  cursor: default !important; }

.account-payments-container {
  min-width: 800px;
  max-height: 700px;
  overflow-y: auto; }

.trial-balance-custom-buttons {
  display: flex; }
  .trial-balance-custom-buttons .trial-balance-download-icon {
    position: absolute;
    right: 5px; }

.edit-deliveries .tab-content .input-group .react-datepicker-wrapper + input {
  margin-top: -2px; }

.open-shift-link {
  color: #FFFFFF;
  text-decoration: underline; }
  .open-shift-link:hover {
    cursor: pointer; }

.commissioners-checkbox .input-group-checkbox {
  width: inherit;
  margin-top: 10px; }
  .commissioners-checkbox .input-group-checkbox .show-commissioners-checkbox {
    margin-top: 5px; }
    .commissioners-checkbox .input-group-checkbox .show-commissioners-checkbox + label {
      margin-right: 0; }

.payment-title {
  margin: 60px;
  font-size: 20px; }

.show-commissioners-percentages {
  width: 50%;
  height: 90px;
  overflow-y: auto; }
  .show-commissioners-percentages .input-group {
    display: initial; }
  .show-commissioners-percentages .form-inline {
    height: 45px !important; }
  .show-commissioners-percentages .commissioner-name {
    flex-basis: 50%;
    text-align: left; }
  .show-commissioners-percentages .commissioner-percentage {
    width: 50px;
    border-radius: 0 !important; }

.route-pass-container {
  width: 100%;
  margin-top: 20px; }

div.delictPaymentText {
  text-align: center;
  padding: 10px;
  font-weight: 600;
  font-size: 20px; }

div.delictPaymentBtn {
  display: inline-block; }
  div.delictPaymentBtn button.custom-button {
    width: 180px;
    height: 45px;
    font-size: 16px; }

button.nextToTextField {
  height: 35px;
  margin-top: 35px;
  padding-top: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.form-inline .input-group:first-of-type input.generate-traker-field {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

div.mass-mailing {
  padding: 0 15px; }
  div.mass-mailing .panel-edit {
    padding: 0;
    overflow: visible; }
    div.mass-mailing .panel-edit .field-container {
      padding: 0 10px; }
  div.mass-mailing .heading-title {
    text-align: center; }
  div.mass-mailing .select-all-checkbox .custom-checkbox {
    display: inline; }
  div.mass-mailing .form-inline {
    max-width: none; }
  div.mass-mailing div.react-table-filterable {
    height: calc(100vh - 400px); }
  div.mass-mailing button .icon.icon-search {
    color: white;
    font-size: 17px; }
  div.mass-mailing button .icon.icon-pencil-square-o {
    color: white;
    font-size: 20px; }
  div.mass-mailing .send-mail-button {
    float: right; }

.popup-mail-completion .popup-box {
  width: 700px; }
  .popup-mail-completion .popup-box .popup-title {
    width: 600px;
    margin: 0 auto 25px; }
  .popup-mail-completion .popup-box .completion-message {
    margin-bottom: 40px; }

.popup.popup-erp .popup-wrapper.is-visible .popup-box div.mass-mailing-popup {
  width: 100%;
  height: calc(100% - 70px); }
  .popup.popup-erp .popup-wrapper.is-visible .popup-box div.mass-mailing-popup .custom-button span {
    color: white;
    font-size: 23px;
    margin-right: 3px;
    position: relative;
    top: 4px; }
  .popup.popup-erp .popup-wrapper.is-visible .popup-box div.mass-mailing-popup .form-mailing {
    float: left;
    display: block;
    width: 100%;
    max-width: none;
    margin-bottom: 20px;
    padding: 0 18px; }
    .popup.popup-erp .popup-wrapper.is-visible .popup-box div.mass-mailing-popup .form-mailing .mail-subject {
      width: 50%; }
  .popup.popup-erp .popup-wrapper.is-visible .popup-box div.mass-mailing-popup div.form-mailing.test-mail {
    height: 45px; }
    .popup.popup-erp .popup-wrapper.is-visible .popup-box div.mass-mailing-popup div.form-mailing.test-mail .input-group {
      width: 50%;
      display: inline-block;
      float: left; }
    .popup.popup-erp .popup-wrapper.is-visible .popup-box div.mass-mailing-popup div.form-mailing.test-mail .custom-checkbox {
      width: 50%;
      float: right;
      padding-top: 27px;
      text-align: left;
      padding-left: 20px; }
    .popup.popup-erp .popup-wrapper.is-visible .popup-box div.mass-mailing-popup div.form-mailing.test-mail .test-mail-input {
      width: 100%;
      border-right: 1px solid lightgray;
      border-radius: 5px; }
  .popup.popup-erp .popup-wrapper.is-visible .popup-box div.mass-mailing-popup button.button-primary.custom-button {
    float: right;
    margin-right: 20px; }
  .popup.popup-erp .popup-wrapper.is-visible .popup-box div.mass-mailing-popup div.panel-edit {
    width: 100%;
    height: 100%; }
  .popup.popup-erp .popup-wrapper.is-visible .popup-box div.mass-mailing-popup form {
    height: 100%; }
  .popup.popup-erp .popup-wrapper.is-visible .popup-box div.mass-mailing-popup .field-container {
    height: 100%; }
  .popup.popup-erp .popup-wrapper.is-visible .popup-box div.mass-mailing-popup .form-inline.form-mailing {
    position: relative;
    display: block;
    height: calc(100% - 150px); }
  .popup.popup-erp .popup-wrapper.is-visible .popup-box div.mass-mailing-popup .rdw-editor-wrapper {
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-top: 20px;
    height: calc(100% - 92px); }
  .popup.popup-erp .popup-wrapper.is-visible .popup-box div.mass-mailing-popup .rdw-editor-main {
    padding: 20px 15px;
    box-sizing: border-box;
    height: calc(100% - 51px); }

div.form-inline.form-inline-border.half-size {
  width: 50%;
  display: inline-block;
  float: left; }

div.form-inline.form-inline-border.half-size.left input {
  width: calc(100% - 5px);
  float: left; }

div.form-inline.form-inline-border.with-checkbox .input-group:first-of-type {
  padding-right: 10px; }
  div.form-inline.form-inline-border.with-checkbox .input-group:first-of-type input {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-right: 1px solid lightgray;
    padding-right: 3px; }

div.form-inline.form-inline-border.with-checkbox .custom-checkbox {
  padding-top: 37px; }

div.vignette-cards-container {
  width: 100%; }

div.vignette-cart {
  width: 400px;
  border: 1px solid #CACACA;
  padding: 20px;
  border-radius: 5px;
  margin: 0 auto; }
  div.vignette-cart .status-checker {
    margin-bottom: 0;
    margin-top: 0; }

div.check-vignette.buy-now-link {
  width: 100%; }
  div.check-vignette.buy-now-link a.button-primary {
    margin: 0 auto;
    display: block;
    width: fit-content; }

.clickable-tooltip {
  position: absolute;
  opacity: 0;
  bottom: -80px;
  display: none;
  transition: opacity 2s; }
  .clickable-tooltip .button-primary:hover, .clickable-tooltip .site-header-widgets input:hover[type="submit"], .site-header-widgets .clickable-tooltip input:hover[type="submit"] {
    background-color: #F5BA16; }

.input-group:hover .clickable-tooltip {
  display: block;
  opacity: 0.8; }

.input-group:hover div.clickable-tooltip:hover {
  opacity: 1; }

.tooltip-button {
  position: absolute;
  opacity: 0;
  bottom: 8px;
  right: 13px;
  display: none; }
  .tooltip-button .button-primary, .tooltip-button .site-header-widgets input[type="submit"], .site-header-widgets .tooltip-button input[type="submit"] {
    background-color: transparent;
    width: 38px;
    height: 35px;
    min-width: 0;
    padding: 8px 0 0 0;
    text-align: center;
    color: #666666;
    font-size: 30px; }
    .tooltip-button .button-primary a, .tooltip-button .site-header-widgets input[type="submit"] a, .site-header-widgets .tooltip-button input[type="submit"] a {
      color: black; }
  .tooltip-button .button-primary:hover, .tooltip-button .site-header-widgets input:hover[type="submit"], .site-header-widgets .tooltip-button input:hover[type="submit"] {
    background-color: transparent;
    color: #2b2b2b; }

.input-group:hover .tooltip-button {
  display: block;
  opacity: 0.8; }

.input-group:hover div.tooltip-button:hover {
  opacity: 1; }

.lpn-help-link {
  text-align: center; }
  .lpn-help-link a {
    background: transparent;
    color: #666666;
    padding: 0;
    padding-bottom: 10px; }
  .lpn-help-link a:hover {
    background-color: transparent;
    color: #F5BA16; }

.lpn-help-link.right-aligned {
  padding-left: 50%;
  text-align: left; }
  .lpn-help-link.right-aligned a {
    padding-left: 0; }

.lpn-help-link.check-vignette {
  text-align: left;
  padding-left: 510px; }

@media (max-width: 1425px) {
  .lpn-help-link.check-vignette {
    padding-left: 0;
    text-align: center; } }

div.lpn-help-link.routr-pass {
  text-align: left;
  height: 30px;
  line-height: 34px; }
  div.lpn-help-link.routr-pass a.button.button-primary {
    width: 100%;
    font-size: 15px;
    padding: 0; }

.formatted-text.check-vignette p {
  margin-bottom: 5px; }

.formatted-text.check-vignette h1 {
  text-align: center; }

.input-group.route-select .tab-content.tab-content-default {
  padding-right: 0;
  padding-left: 0; }

.snowflake {
  color: #fff;
  font-size: 1.7em;
  font-family: Arial;
  text-shadow: 0 0 1px #000; }

@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%; }
  100% {
    top: 100%; } }

@-webkit-keyframes snowflakes-shake {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }
  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px); }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px); } }

@keyframes snowflakes-fall {
  0% {
    top: -10%; }
  100% {
    top: 100%; } }

@keyframes snowflakes-shake {
  0% {
    transform: translateX(0px); }
  50% {
    transform: translateX(80px); }
  100% {
    transform: translateX(0px); } }

.snowflake {
  position: fixed;
  top: -10%;
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall,snowflakes-shake;
  -webkit-animation-duration: 10s,3s;
  -webkit-animation-timing-function: linear,ease-in-out;
  -webkit-animation-iteration-count: infinite,infinite;
  -webkit-animation-play-state: running,running;
  animation-name: snowflakes-fall,snowflakes-shake;
  animation-duration: 10s,3s;
  animation-timing-function: linear,ease-in-out;
  animation-iteration-count: infinite,infinite;
  animation-play-state: running,running; }

.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s,0s;
  animation-delay: 0s,0s; }

.snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s,1s;
  animation-delay: 1s,1s; }

.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s,.5s;
  animation-delay: 6s,.5s; }

.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s,2s;
  animation-delay: 4s,2s; }

.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s,2s;
  animation-delay: 2s,2s; }

.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s,3s;
  animation-delay: 8s,3s; }

.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s,2s;
  animation-delay: 6s,2s; }

.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s,1s;
  animation-delay: 2.5s,1s; }

.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s,0s;
  animation-delay: 1s,0s; }

.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s,1.5s;
  animation-delay: 3s,1.5s; }

/* Demo Purpose Only*/
.demo {
  font-family: 'Raleway', sans-serif;
  color: #fff;
  display: block;
  margin: 0 auto;
  padding: 15px 0;
  text-align: center; }

.demo a {
  font-family: 'Raleway', sans-serif;
  color: #000; }

div.carousel-overlay .heading-cta .vehicle-section {
  padding-bottom: 40px;
  width: 1450px;
  margin: 0 auto; }

div.bubble-container.home-page {
  display: inline-block;
  height: 250px;
  width: 20%;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  vertical-align: top; }
  div.bubble-container.home-page .bubble {
    width: 245px;
    height: 245px;
    border: solid 5px #F5BA16;
    border-radius: 180px;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 0 auto;
    overflow: hidden;
    transition: width .2s, height .2s, padding-top .2s, margin-top .2s; }
  div.bubble-container.home-page .bubble-title {
    color: white;
    margin-top: 40px;
    font-size: 17px; }
  div.bubble-container.home-page .button.button-primary, div.bubble-container.home-page .site-header-widgets input[type="submit"], .site-header-widgets div.bubble-container.home-page input[type="submit"] {
    border-radius: 40px;
    background-color: #F5BA16;
    font-size: 17px;
    margin: 0 auto;
    padding: 10px;
    width: 165px;
    font-weight: 600;
    position: relative;
    z-index: 10; }
  div.bubble-container.home-page .bubble-image-container {
    height: 95px; }
  div.bubble-container.home-page img {
    position: relative;
    height: 105px;
    top: -5px; }
  div.bubble-container.home-page img.map {
    height: 145px;
    top: -26px;
    left: 5px; }
  div.bubble-container.home-page img.tracker {
    height: 125px;
    top: -8px; }
  div.bubble-container.home-page img.tax {
    top: -8px; }
  div.bubble-container.home-page img.vignette {
    height: 170px;
    top: -60px;
    left: 20px; }

.home-image-container {
  overflow: hidden; }
  .home-image-container .dark-screen {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 20, 20, 0.7); }
  .home-image-container .home-image {
    object-fit: cover;
    height: 1000px; }

@media (min-width: 1460px) {
  div.bubble-container.home-page .bubble:hover {
    width: 265px;
    height: 265px;
    padding-top: 10px;
    margin-top: -10px; } }

@media (max-width: 1460px) {
  div.carousel-overlay .heading-cta .vehicle-section {
    width: 1280px; } }

@media (max-width: 1300px) {
  div.carousel-overlay .heading-cta .vehicle-section {
    width: 900px; }
  .home-image-container .home-image, .home-image-container .dark-screen {
    height: 1000px; }
  div.bubble-container.home-page {
    width: 33%; } }

@media (max-width: 1030px) {
  div.carousel-overlay .heading-cta .vehicle-section {
    width: 600px; }
  .home-image-container .home-image, .home-image-container .dark-screen {
    height: 1300px; }
  div.bubble-container.home-page {
    width: 50%; } }

@media (max-width: 900px) {
  div.carousel-overlay .heading-cta .vehicle-section {
    width: 300px; }
  .home-image-container .home-image, .home-image-container .dark-screen {
    height: 1650px; }
  div.bubble-container.home-page {
    width: 100%; } }

.invoice-preview {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 10px; }
  .invoice-preview .invoice-header {
    margin-top: 15px; }
  .invoice-preview .half-split {
    display: flex;
    flex-direction: row;
    margin-top: 15px; }
  .invoice-preview .half-split div.half {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    max-width: 50%; }
    .invoice-preview .half-split div.half .field-row {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .invoice-preview .half-split div.half .field-row .field-label {
        flex-grow: 0;
        flex-shrink: 0;
        width: 35%;
        text-align: right;
        background-color: #ececec;
        padding-right: 5px;
        font-weight: bold;
        height: 100%; }
      .invoice-preview .half-split div.half .field-row .field-content {
        flex-grow: 1;
        text-align: left;
        padding-left: 5px; }
      .invoice-preview .half-split div.half .field-row .summary-label {
        flex-grow: 0;
        flex-shrink: 0;
        width: 50%;
        text-align: right;
        padding-right: 5px;
        font-weight: bold; }
      .invoice-preview .half-split div.half .field-row .summary-content {
        font-size: 15px;
        flex-grow: 1;
        text-align: left;
        padding-left: 5px; }
        .invoice-preview .half-split div.half .field-row .summary-content.align-right {
          text-align: right;
          padding-right: 5px; }
  .invoice-preview .invoice-table {
    margin-top: 15px; }
    .invoice-preview .invoice-table thead {
      border-left: 1px solid #777879; }
      .invoice-preview .invoice-table thead tr {
        height: 50px;
        background-color: #d9d9d9;
        padding-top: 10px;
        padding-bottom: 10px; }
        .invoice-preview .invoice-table thead tr th {
          text-align: center; }
        .invoice-preview .invoice-table thead tr th:nth-child(1) {
          padding-left: 10px !important;
          width: 10%; }
        .invoice-preview .invoice-table thead tr th:nth-child(2) {
          width: 50%; }
        .invoice-preview .invoice-table thead tr th:nth-child(3) {
          width: 10%; }
        .invoice-preview .invoice-table thead tr th:nth-child(4) {
          width: 15%; }
        .invoice-preview .invoice-table thead tr th:nth-child(5) {
          width: 15%; }
    .invoice-preview .invoice-table td {
      border: 1px solid black;
      text-align: center; }
      .invoice-preview .invoice-table td:nth-child(1) {
        padding-left: 10px !important; }
      .invoice-preview .invoice-table td:nth-child(2) {
        text-align: left; }
      .invoice-preview .invoice-table td:nth-child(3) {
        text-align: right; }
      .invoice-preview .invoice-table td:nth-child(4) {
        text-align: right; }
      .invoice-preview .invoice-table td:nth-child(5) {
        padding: 1rem;
        text-align: right; }

.empty-invoice-preview {
  background: #F2F2F2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }

.responsive-image {
  overflow: hidden;
  padding-bottom: 75%;
  width: 100%;
  height: 0;
  display: inline-block;
  position: relative;
  margin-bottom: -8px;
  background: #f8f8f8; }
  .responsive-image img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute; }
  .responsive-image.ratio-16-9 {
    padding-bottom: 56.25%; }
  .responsive-image.ratio-16-10 {
    padding-bottom: 62.5%; }
  .responsive-image.ratio-11-8 {
    padding-bottom: 72.72727%; }
  .responsive-image.ratio-3-2 {
    padding-bottom: 66.66667%; }
  .responsive-image.ratio-1-1 {
    padding-bottom: 100%; }
  .responsive-image.ratio-golden {
    padding-bottom: 61.8047%; }
  .responsive-image.ratio-silver {
    padding-bottom: 41.42502%; }
  .responsive-image.radius-3 {
    border-radius: 3px; }
  .responsive-image.radius-5 {
    border-radius: 5px; }
  .responsive-image.radius-10 {
    border-radius: 10px; }
  .responsive-image.radius-20 {
    border-radius: 20x; }
  .responsive-image.radius-circle {
    border-radius: 50%; }

/**
 * Foundation for Sites by ZURB
 * Version 6.3.0
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.row {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .row .row {
    margin-right: -0.55556rem;
    margin-left: -0.55556rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.83333rem;
        margin-left: -0.83333rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.83333rem;
        margin-left: -0.83333rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns, .browser-ie .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row,
  .browser-ie .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns, .browser-ie .columns {
  flex: 1 1 0px;
  padding-right: 0.55556rem;
  padding-left: 0.55556rem;
  min-width: initial; }
  @media print, screen and (min-width: 40em) {
    .column, .columns, .browser-ie .columns {
      padding-right: 0.83333rem;
      padding-left: 0.83333rem; } }

.column.row.row, .row.row.columns {
  display: flex; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

.small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

.small-up-1 {
  flex-wrap: wrap; }
  .small-up-1 > .column, .small-up-1 > .columns, .browser-ie .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
  flex-wrap: wrap; }
  .small-up-2 > .column, .small-up-2 > .columns, .browser-ie .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
  flex-wrap: wrap; }
  .small-up-3 > .column, .small-up-3 > .columns, .browser-ie .small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
  flex-wrap: wrap; }
  .small-up-4 > .column, .small-up-4 > .columns, .browser-ie .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
  flex-wrap: wrap; }
  .small-up-5 > .column, .small-up-5 > .columns, .browser-ie .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
  flex-wrap: wrap; }
  .small-up-6 > .column, .small-up-6 > .columns, .browser-ie .small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
  flex-wrap: wrap; }
  .small-up-7 > .column, .small-up-7 > .columns, .browser-ie .small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
  flex-wrap: wrap; }
  .small-up-8 > .column, .small-up-8 > .columns, .browser-ie .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns, .browser-ie .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns, .browser-ie .small-uncollapse > .columns {
  padding-right: 0.55556rem;
  padding-left: 0.55556rem; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; }
  .medium-up-1 {
    flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns, .browser-ie .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns, .browser-ie .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns, .browser-ie .medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns, .browser-ie .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns, .browser-ie .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns, .browser-ie .medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns, .browser-ie .medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns, .browser-ie .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns, .browser-ie .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 40em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns, .browser-ie .row.medium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .medium-collapse > .column, .medium-collapse > .columns, .browser-ie .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns, .browser-ie .medium-uncollapse > .columns {
    padding-right: 0.83333rem;
    padding-left: 0.83333rem; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; }
  .large-up-1 {
    flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns, .browser-ie .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns, .browser-ie .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns, .browser-ie .large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns, .browser-ie .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns, .browser-ie .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns, .browser-ie .large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns, .browser-ie .large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns, .browser-ie .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    flex: 1 1 0px; } }

@media print, screen and (min-width: 64em) {
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; } }

.row.large-unstack > .column, .row.large-unstack > .columns, .browser-ie .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 64em) {
    .row.large-unstack > .column, .row.large-unstack > .columns, .browser-ie .row.large-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 64em) {
  .large-collapse > .column, .large-collapse > .columns, .browser-ie .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns, .browser-ie .large-uncollapse > .columns {
    padding-right: 0.83333rem;
    padding-left: 0.83333rem; } }

.shrink {
  flex: 0 0 auto;
  max-width: 100%; }

.column-block {
  margin-bottom: 1.11111rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.66667rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe; }
  .tooltip::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #0a0a0a;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0a0a0a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0a0a0a;
    top: 50%;
    bottom: auto;
    left: 100%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0a0a0a transparent transparent;
    top: 50%;
    right: 100%;
    bottom: auto;
    left: auto;
    transform: translateY(-50%); }

.browser-ie .row {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto; }
  .browser-ie .row::before, .browser-ie .row::after {
    display: table;
    content: ' '; }
  .browser-ie .row::after {
    clear: both; }
  .browser-ie .row.collapse > .column, .browser-ie .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .browser-ie .row .row {
    margin-right: -0.55556rem;
    margin-left: -0.55556rem; }
    @media print, screen and (min-width: 40em) {
      .browser-ie .row .row {
        margin-right: -0.83333rem;
        margin-left: -0.83333rem; } }
    @media print, screen and (min-width: 64em) {
      .browser-ie .row .row {
        margin-right: -0.83333rem;
        margin-left: -0.83333rem; } }
    .browser-ie .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .browser-ie .row.expanded {
    max-width: none; }
    .browser-ie .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .browser-ie .row:not(.expanded) .row {
    max-width: none; }
  .browser-ie .row.gutter-small > .column, .browser-ie .row.gutter-small > .columns {
    padding-right: 0.55556rem;
    padding-left: 0.55556rem; }
  .browser-ie .row.gutter-medium > .column, .browser-ie .row.gutter-medium > .columns {
    padding-right: 0.83333rem;
    padding-left: 0.83333rem; }

.browser-ie .column, .browser-ie .columns {
  width: 100%;
  float: left;
  padding-right: 0.55556rem;
  padding-left: 0.55556rem; }
  @media print, screen and (min-width: 40em) {
    .browser-ie .column, .browser-ie .columns {
      padding-right: 0.83333rem;
      padding-left: 0.83333rem; } }
  .browser-ie .column:last-child:not(:first-child), .browser-ie .columns:last-child:not(:first-child) {
    float: right; }
  .browser-ie .column.end:last-child:last-child, .browser-ie .end.columns:last-child:last-child {
    float: left; }

.browser-ie .column.row.row, .browser-ie .row.row.columns {
  float: none; }

.browser-ie .row .column.row.row, .browser-ie .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.browser-ie .small-1 {
  width: 8.33333%; }

.browser-ie .small-push-1 {
  position: relative;
  left: 8.33333%; }

.browser-ie .small-pull-1 {
  position: relative;
  left: -8.33333%; }

.browser-ie .small-offset-0 {
  margin-left: 0%; }

.browser-ie .small-2 {
  width: 16.66667%; }

.browser-ie .small-push-2 {
  position: relative;
  left: 16.66667%; }

.browser-ie .small-pull-2 {
  position: relative;
  left: -16.66667%; }

.browser-ie .small-offset-1 {
  margin-left: 8.33333%; }

.browser-ie .small-3 {
  width: 25%; }

.browser-ie .small-push-3 {
  position: relative;
  left: 25%; }

.browser-ie .small-pull-3 {
  position: relative;
  left: -25%; }

.browser-ie .small-offset-2 {
  margin-left: 16.66667%; }

.browser-ie .small-4 {
  width: 33.33333%; }

.browser-ie .small-push-4 {
  position: relative;
  left: 33.33333%; }

.browser-ie .small-pull-4 {
  position: relative;
  left: -33.33333%; }

.browser-ie .small-offset-3 {
  margin-left: 25%; }

.browser-ie .small-5 {
  width: 41.66667%; }

.browser-ie .small-push-5 {
  position: relative;
  left: 41.66667%; }

.browser-ie .small-pull-5 {
  position: relative;
  left: -41.66667%; }

.browser-ie .small-offset-4 {
  margin-left: 33.33333%; }

.browser-ie .small-6 {
  width: 50%; }

.browser-ie .small-push-6 {
  position: relative;
  left: 50%; }

.browser-ie .small-pull-6 {
  position: relative;
  left: -50%; }

.browser-ie .small-offset-5 {
  margin-left: 41.66667%; }

.browser-ie .small-7 {
  width: 58.33333%; }

.browser-ie .small-push-7 {
  position: relative;
  left: 58.33333%; }

.browser-ie .small-pull-7 {
  position: relative;
  left: -58.33333%; }

.browser-ie .small-offset-6 {
  margin-left: 50%; }

.browser-ie .small-8 {
  width: 66.66667%; }

.browser-ie .small-push-8 {
  position: relative;
  left: 66.66667%; }

.browser-ie .small-pull-8 {
  position: relative;
  left: -66.66667%; }

.browser-ie .small-offset-7 {
  margin-left: 58.33333%; }

.browser-ie .small-9 {
  width: 75%; }

.browser-ie .small-push-9 {
  position: relative;
  left: 75%; }

.browser-ie .small-pull-9 {
  position: relative;
  left: -75%; }

.browser-ie .small-offset-8 {
  margin-left: 66.66667%; }

.browser-ie .small-10 {
  width: 83.33333%; }

.browser-ie .small-push-10 {
  position: relative;
  left: 83.33333%; }

.browser-ie .small-pull-10 {
  position: relative;
  left: -83.33333%; }

.browser-ie .small-offset-9 {
  margin-left: 75%; }

.browser-ie .small-11 {
  width: 91.66667%; }

.browser-ie .small-push-11 {
  position: relative;
  left: 91.66667%; }

.browser-ie .small-pull-11 {
  position: relative;
  left: -91.66667%; }

.browser-ie .small-offset-10 {
  margin-left: 83.33333%; }

.browser-ie .small-12 {
  width: 100%; }

.browser-ie .small-offset-11 {
  margin-left: 91.66667%; }

.browser-ie .small-up-1 > .column, .browser-ie .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .browser-ie .small-up-1 > .column:nth-of-type(1n), .browser-ie .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .browser-ie .small-up-1 > .column:nth-of-type(1n+1), .browser-ie .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .browser-ie .small-up-1 > .column:last-child, .browser-ie .small-up-1 > .columns:last-child {
    float: left; }

.browser-ie .small-up-2 > .column, .browser-ie .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .browser-ie .small-up-2 > .column:nth-of-type(1n), .browser-ie .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .browser-ie .small-up-2 > .column:nth-of-type(2n+1), .browser-ie .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .browser-ie .small-up-2 > .column:last-child, .browser-ie .small-up-2 > .columns:last-child {
    float: left; }

.browser-ie .small-up-3 > .column, .browser-ie .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .browser-ie .small-up-3 > .column:nth-of-type(1n), .browser-ie .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .browser-ie .small-up-3 > .column:nth-of-type(3n+1), .browser-ie .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .browser-ie .small-up-3 > .column:last-child, .browser-ie .small-up-3 > .columns:last-child {
    float: left; }

.browser-ie .small-up-4 > .column, .browser-ie .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .browser-ie .small-up-4 > .column:nth-of-type(1n), .browser-ie .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .browser-ie .small-up-4 > .column:nth-of-type(4n+1), .browser-ie .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .browser-ie .small-up-4 > .column:last-child, .browser-ie .small-up-4 > .columns:last-child {
    float: left; }

.browser-ie .small-up-5 > .column, .browser-ie .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .browser-ie .small-up-5 > .column:nth-of-type(1n), .browser-ie .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .browser-ie .small-up-5 > .column:nth-of-type(5n+1), .browser-ie .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .browser-ie .small-up-5 > .column:last-child, .browser-ie .small-up-5 > .columns:last-child {
    float: left; }

.browser-ie .small-up-6 > .column, .browser-ie .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .browser-ie .small-up-6 > .column:nth-of-type(1n), .browser-ie .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .browser-ie .small-up-6 > .column:nth-of-type(6n+1), .browser-ie .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .browser-ie .small-up-6 > .column:last-child, .browser-ie .small-up-6 > .columns:last-child {
    float: left; }

.browser-ie .small-up-7 > .column, .browser-ie .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .browser-ie .small-up-7 > .column:nth-of-type(1n), .browser-ie .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .browser-ie .small-up-7 > .column:nth-of-type(7n+1), .browser-ie .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .browser-ie .small-up-7 > .column:last-child, .browser-ie .small-up-7 > .columns:last-child {
    float: left; }

.browser-ie .small-up-8 > .column, .browser-ie .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .browser-ie .small-up-8 > .column:nth-of-type(1n), .browser-ie .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .browser-ie .small-up-8 > .column:nth-of-type(8n+1), .browser-ie .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .browser-ie .small-up-8 > .column:last-child, .browser-ie .small-up-8 > .columns:last-child {
    float: left; }

.browser-ie .small-collapse > .column, .browser-ie .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.browser-ie .small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.browser-ie .expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.browser-ie .small-uncollapse > .column, .browser-ie .small-uncollapse > .columns {
  padding-right: 0.55556rem;
  padding-left: 0.55556rem; }

.browser-ie .small-centered {
  margin-right: auto;
  margin-left: auto; }
  .browser-ie .small-centered, .browser-ie .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.browser-ie .small-uncentered,
.browser-ie .small-push-0,
.browser-ie .small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .browser-ie .medium-1 {
    width: 8.33333%; }
  .browser-ie .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .browser-ie .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .browser-ie .medium-offset-0 {
    margin-left: 0%; }
  .browser-ie .medium-2 {
    width: 16.66667%; }
  .browser-ie .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .browser-ie .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .browser-ie .medium-offset-1 {
    margin-left: 8.33333%; }
  .browser-ie .medium-3 {
    width: 25%; }
  .browser-ie .medium-push-3 {
    position: relative;
    left: 25%; }
  .browser-ie .medium-pull-3 {
    position: relative;
    left: -25%; }
  .browser-ie .medium-offset-2 {
    margin-left: 16.66667%; }
  .browser-ie .medium-4 {
    width: 33.33333%; }
  .browser-ie .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .browser-ie .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .browser-ie .medium-offset-3 {
    margin-left: 25%; }
  .browser-ie .medium-5 {
    width: 41.66667%; }
  .browser-ie .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .browser-ie .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .browser-ie .medium-offset-4 {
    margin-left: 33.33333%; }
  .browser-ie .medium-6 {
    width: 50%; }
  .browser-ie .medium-push-6 {
    position: relative;
    left: 50%; }
  .browser-ie .medium-pull-6 {
    position: relative;
    left: -50%; }
  .browser-ie .medium-offset-5 {
    margin-left: 41.66667%; }
  .browser-ie .medium-7 {
    width: 58.33333%; }
  .browser-ie .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .browser-ie .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .browser-ie .medium-offset-6 {
    margin-left: 50%; }
  .browser-ie .medium-8 {
    width: 66.66667%; }
  .browser-ie .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .browser-ie .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .browser-ie .medium-offset-7 {
    margin-left: 58.33333%; }
  .browser-ie .medium-9 {
    width: 75%; }
  .browser-ie .medium-push-9 {
    position: relative;
    left: 75%; }
  .browser-ie .medium-pull-9 {
    position: relative;
    left: -75%; }
  .browser-ie .medium-offset-8 {
    margin-left: 66.66667%; }
  .browser-ie .medium-10 {
    width: 83.33333%; }
  .browser-ie .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .browser-ie .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .browser-ie .medium-offset-9 {
    margin-left: 75%; }
  .browser-ie .medium-11 {
    width: 91.66667%; }
  .browser-ie .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .browser-ie .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .browser-ie .medium-offset-10 {
    margin-left: 83.33333%; }
  .browser-ie .medium-12 {
    width: 100%; }
  .browser-ie .medium-offset-11 {
    margin-left: 91.66667%; }
  .browser-ie .medium-up-1 > .column, .browser-ie .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .browser-ie .medium-up-1 > .column:nth-of-type(1n), .browser-ie .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .browser-ie .medium-up-1 > .column:nth-of-type(1n+1), .browser-ie .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .browser-ie .medium-up-1 > .column:last-child, .browser-ie .medium-up-1 > .columns:last-child {
      float: left; }
  .browser-ie .medium-up-2 > .column, .browser-ie .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .browser-ie .medium-up-2 > .column:nth-of-type(1n), .browser-ie .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .browser-ie .medium-up-2 > .column:nth-of-type(2n+1), .browser-ie .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .browser-ie .medium-up-2 > .column:last-child, .browser-ie .medium-up-2 > .columns:last-child {
      float: left; }
  .browser-ie .medium-up-3 > .column, .browser-ie .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .browser-ie .medium-up-3 > .column:nth-of-type(1n), .browser-ie .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .browser-ie .medium-up-3 > .column:nth-of-type(3n+1), .browser-ie .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .browser-ie .medium-up-3 > .column:last-child, .browser-ie .medium-up-3 > .columns:last-child {
      float: left; }
  .browser-ie .medium-up-4 > .column, .browser-ie .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .browser-ie .medium-up-4 > .column:nth-of-type(1n), .browser-ie .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .browser-ie .medium-up-4 > .column:nth-of-type(4n+1), .browser-ie .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .browser-ie .medium-up-4 > .column:last-child, .browser-ie .medium-up-4 > .columns:last-child {
      float: left; }
  .browser-ie .medium-up-5 > .column, .browser-ie .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .browser-ie .medium-up-5 > .column:nth-of-type(1n), .browser-ie .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .browser-ie .medium-up-5 > .column:nth-of-type(5n+1), .browser-ie .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .browser-ie .medium-up-5 > .column:last-child, .browser-ie .medium-up-5 > .columns:last-child {
      float: left; }
  .browser-ie .medium-up-6 > .column, .browser-ie .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .browser-ie .medium-up-6 > .column:nth-of-type(1n), .browser-ie .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .browser-ie .medium-up-6 > .column:nth-of-type(6n+1), .browser-ie .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .browser-ie .medium-up-6 > .column:last-child, .browser-ie .medium-up-6 > .columns:last-child {
      float: left; }
  .browser-ie .medium-up-7 > .column, .browser-ie .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .browser-ie .medium-up-7 > .column:nth-of-type(1n), .browser-ie .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .browser-ie .medium-up-7 > .column:nth-of-type(7n+1), .browser-ie .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .browser-ie .medium-up-7 > .column:last-child, .browser-ie .medium-up-7 > .columns:last-child {
      float: left; }
  .browser-ie .medium-up-8 > .column, .browser-ie .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .browser-ie .medium-up-8 > .column:nth-of-type(1n), .browser-ie .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .browser-ie .medium-up-8 > .column:nth-of-type(8n+1), .browser-ie .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .browser-ie .medium-up-8 > .column:last-child, .browser-ie .medium-up-8 > .columns:last-child {
      float: left; }
  .browser-ie .medium-collapse > .column, .browser-ie .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .browser-ie .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .browser-ie .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .browser-ie .medium-uncollapse > .column, .browser-ie .medium-uncollapse > .columns {
    padding-right: 0.83333rem;
    padding-left: 0.83333rem; }
  .browser-ie .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .browser-ie .medium-centered, .browser-ie .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .browser-ie .medium-uncentered,
  .browser-ie .medium-push-0,
  .browser-ie .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .browser-ie .large-1 {
    width: 8.33333%; }
  .browser-ie .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .browser-ie .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .browser-ie .large-offset-0 {
    margin-left: 0%; }
  .browser-ie .large-2 {
    width: 16.66667%; }
  .browser-ie .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .browser-ie .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .browser-ie .large-offset-1 {
    margin-left: 8.33333%; }
  .browser-ie .large-3 {
    width: 25%; }
  .browser-ie .large-push-3 {
    position: relative;
    left: 25%; }
  .browser-ie .large-pull-3 {
    position: relative;
    left: -25%; }
  .browser-ie .large-offset-2 {
    margin-left: 16.66667%; }
  .browser-ie .large-4 {
    width: 33.33333%; }
  .browser-ie .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .browser-ie .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .browser-ie .large-offset-3 {
    margin-left: 25%; }
  .browser-ie .large-5 {
    width: 41.66667%; }
  .browser-ie .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .browser-ie .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .browser-ie .large-offset-4 {
    margin-left: 33.33333%; }
  .browser-ie .large-6 {
    width: 50%; }
  .browser-ie .large-push-6 {
    position: relative;
    left: 50%; }
  .browser-ie .large-pull-6 {
    position: relative;
    left: -50%; }
  .browser-ie .large-offset-5 {
    margin-left: 41.66667%; }
  .browser-ie .large-7 {
    width: 58.33333%; }
  .browser-ie .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .browser-ie .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .browser-ie .large-offset-6 {
    margin-left: 50%; }
  .browser-ie .large-8 {
    width: 66.66667%; }
  .browser-ie .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .browser-ie .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .browser-ie .large-offset-7 {
    margin-left: 58.33333%; }
  .browser-ie .large-9 {
    width: 75%; }
  .browser-ie .large-push-9 {
    position: relative;
    left: 75%; }
  .browser-ie .large-pull-9 {
    position: relative;
    left: -75%; }
  .browser-ie .large-offset-8 {
    margin-left: 66.66667%; }
  .browser-ie .large-10 {
    width: 83.33333%; }
  .browser-ie .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .browser-ie .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .browser-ie .large-offset-9 {
    margin-left: 75%; }
  .browser-ie .large-11 {
    width: 91.66667%; }
  .browser-ie .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .browser-ie .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .browser-ie .large-offset-10 {
    margin-left: 83.33333%; }
  .browser-ie .large-12 {
    width: 100%; }
  .browser-ie .large-offset-11 {
    margin-left: 91.66667%; }
  .browser-ie .large-up-1 > .column, .browser-ie .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .browser-ie .large-up-1 > .column:nth-of-type(1n), .browser-ie .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .browser-ie .large-up-1 > .column:nth-of-type(1n+1), .browser-ie .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .browser-ie .large-up-1 > .column:last-child, .browser-ie .large-up-1 > .columns:last-child {
      float: left; }
  .browser-ie .large-up-2 > .column, .browser-ie .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .browser-ie .large-up-2 > .column:nth-of-type(1n), .browser-ie .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .browser-ie .large-up-2 > .column:nth-of-type(2n+1), .browser-ie .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .browser-ie .large-up-2 > .column:last-child, .browser-ie .large-up-2 > .columns:last-child {
      float: left; }
  .browser-ie .large-up-3 > .column, .browser-ie .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .browser-ie .large-up-3 > .column:nth-of-type(1n), .browser-ie .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .browser-ie .large-up-3 > .column:nth-of-type(3n+1), .browser-ie .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .browser-ie .large-up-3 > .column:last-child, .browser-ie .large-up-3 > .columns:last-child {
      float: left; }
  .browser-ie .large-up-4 > .column, .browser-ie .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .browser-ie .large-up-4 > .column:nth-of-type(1n), .browser-ie .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .browser-ie .large-up-4 > .column:nth-of-type(4n+1), .browser-ie .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .browser-ie .large-up-4 > .column:last-child, .browser-ie .large-up-4 > .columns:last-child {
      float: left; }
  .browser-ie .large-up-5 > .column, .browser-ie .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .browser-ie .large-up-5 > .column:nth-of-type(1n), .browser-ie .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .browser-ie .large-up-5 > .column:nth-of-type(5n+1), .browser-ie .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .browser-ie .large-up-5 > .column:last-child, .browser-ie .large-up-5 > .columns:last-child {
      float: left; }
  .browser-ie .large-up-6 > .column, .browser-ie .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .browser-ie .large-up-6 > .column:nth-of-type(1n), .browser-ie .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .browser-ie .large-up-6 > .column:nth-of-type(6n+1), .browser-ie .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .browser-ie .large-up-6 > .column:last-child, .browser-ie .large-up-6 > .columns:last-child {
      float: left; }
  .browser-ie .large-up-7 > .column, .browser-ie .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .browser-ie .large-up-7 > .column:nth-of-type(1n), .browser-ie .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .browser-ie .large-up-7 > .column:nth-of-type(7n+1), .browser-ie .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .browser-ie .large-up-7 > .column:last-child, .browser-ie .large-up-7 > .columns:last-child {
      float: left; }
  .browser-ie .large-up-8 > .column, .browser-ie .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .browser-ie .large-up-8 > .column:nth-of-type(1n), .browser-ie .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .browser-ie .large-up-8 > .column:nth-of-type(8n+1), .browser-ie .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .browser-ie .large-up-8 > .column:last-child, .browser-ie .large-up-8 > .columns:last-child {
      float: left; }
  .browser-ie .large-collapse > .column, .browser-ie .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .browser-ie .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .browser-ie .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .browser-ie .large-uncollapse > .column, .browser-ie .large-uncollapse > .columns {
    padding-right: 0.83333rem;
    padding-left: 0.83333rem; }
  .browser-ie .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .browser-ie .large-centered, .browser-ie .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .browser-ie .large-uncentered,
  .browser-ie .large-push-0,
  .browser-ie .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.browser-ie .column-block {
  margin-bottom: 1.11111rem; }
  .browser-ie .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .browser-ie .column-block {
      margin-bottom: 1.66667rem; }
      .browser-ie .column-block > :last-child {
        margin-bottom: 0; } }

.popup-buttons button.button, .popup-buttons a.button {
  display: inline-block;
  margin: 5px;
  width: 100%; }
  @media (min-width: 640px) {
    .popup-buttons button.button, .popup-buttons a.button {
      width: 45%;
      max-width: 220px;
      min-width: 120px; } }

.button, .site-header-widgets input[type="submit"] {
  display: inline-block;
  border-radius: 5px;
  color: white;
  background-color: #333;
  border: none;
  padding: .7em 3em .8em;
  line-height: 1.3; }
  .button-secondary {
    background-color: #a7a7a7; }
  .button-narrow {
    padding-left: 1em;
    padding-right: 1em; }
  .button-primary, .site-header-widgets input[type="submit"] {
    background-color: #F5BA16;
    transition: .3s; }
    .button-primary.button-ghost, .site-header-widgets input.button-ghost[type="submit"] {
      box-shadow: inset 0 0 0 2px #F5BA16;
      color: #494949; }
      .button-primary.button-ghost:hover, .site-header-widgets input.button-ghost:hover[type="submit"] {
        box-shadow: inset 0 0 0 2px #FFD677;
        background: transparent; }
    .button-primary.nowrap, .site-header-widgets input.nowrap[type="submit"] {
      white-space: nowrap; }
    .button-primary:hover, .site-header-widgets input:hover[type="submit"] {
      background-color: #FFD677;
      transition: .3s; }
  .button-large {
    font-size: 24px; }
  .button-ghost {
    background: transparent; }
  @media (max-width: 640px) {
    .button, .site-header-widgets input[type="submit"] {
      font-size: 16px; }
      .button-large {
        font-size: 16px; } }

.submit-button-center {
  display: flex;
  justify-content: center; }

.icon-button {
  background: transparent;
  border: none;
  cursor: pointer;
  height: 30px; }
  .icon-button img {
    width: 24px;
    height: 24px; }

.icon-button[disabled] {
  opacity: 0.3; }

.icon-deliveries {
  position: absolute;
  right: 55px;
  z-index: 2;
  top: 72px;
  cursor: pointer;
  font-size: 30px !important; }

.custom-button-holder button.custom-button {
  padding: 10px;
  width: 250px;
  height: 50px;
  font-size: 20px;
  display: inline-block; }
  @media (max-width: 640px) {
    .custom-button-holder button.custom-button {
      width: 100%; } }
  .custom-button-holder button.custom-button img {
    width: 32px;
    float: right;
    margin-top: -3px; }

/**
 * The standard section wrapper. Use this with other classes for the base styling.
 * Default paddings are 40 and 80px
 */
.section-fullwidth {
  padding: 40px 0;
  position: relative; }
  @media (min-width: 640px) {
    .section-fullwidth {
      padding: 40px 0; } }
  .section-fullwidth.text-white, .section-registration, .section-background-overlay, .section-background-overlay-light, .section-intro, .section-dark, .site-footer {
    color: white; }
    .section-fullwidth.text-white hr, .section-registration hr, .section-background-overlay hr, .section-background-overlay-light hr, .section-intro hr, .section-dark hr, .site-footer hr {
      background-color: rgba(255, 255, 255, 0.2); }
  .section-fullwidth-no-pb {
    padding-bottom: 0; }
  .section-fullwidth-large-pd {
    padding-top: 120px;
    padding-bottom: 120px; }
  @media (max-width: 640px) {
    .section-fullwidth {
      padding: 20px 0; } }

.section-purchase {
  padding: 60px 0 10px 0; }
  .section-purchase .section-heading {
    margin-bottom: 35px; }
  @media (max-width: 640px) {
    .section-purchase .section-heading {
      margin-bottom: 10px; } }

div.input-date > div {
  z-index: 100; }

.section-check-validity {
  background: #F2F2F2; }
  @media (max-width: 640px) {
    .section-check-validity .section-heading {
      margin-bottom: 30px; } }

.section-registration {
  background: #494949; }
  .section-registration .list-large-connected {
    margin-bottom: 30px; }
  @media (max-width: 640px) {
    .section-registration {
      padding-bottom: 40px; }
      .section-registration .button, .section-registration .site-header-widgets input[type="submit"], .site-header-widgets .section-registration input[type="submit"] {
        display: block; }
      .section-registration .list-large-connected {
        margin-bottom: 0; } }

.section-pricing th.tollFirstPricingTableHeader {
  text-align: center;
  padding-bottom: 0;
  padding-right: 15px;
  padding-left: 15px; }

.section-pricing th.tollSecondPricingTableHeader {
  text-align: center;
  padding: 0px 15px; }

.section-pricing th.tollThirdPricingTableHeader {
  padding-left: 15px; }

.section-pricing th.tollLastPricingTableHeader {
  padding-right: 10px;
  width: 10%; }

.section-pricing td.tollPricingVehicleTitle {
  width: 50%; }

.section-pricing td.tollPricingVehicleEmissionClass {
  width: 24%; }

.section-pricing .table-default-small {
  display: none; }

@media (max-width: 960px) {
  .section-pricing .table-default td, .section-pricing .table-default th {
    padding: 5px; }
  .section-pricing .table-default {
    display: table; } }

.table-faq-lpn {
  width: 40%;
  margin: 0 auto; }
  .table-faq-lpn tr th {
    text-align: center; }
  .table-faq-lpn tr td {
    text-align: center; }

.section-benefits.section-fullwidth {
  padding: 190px 0; }

.section-benefits .section-heading {
  max-width: 600px; }

@media (max-width: 640px) {
  .section-benefits.section-fullwidth {
    font-size: 14px;
    padding: 30px 0; }
  .section-benefits .section-heading-left {
    text-align: center;
    font-size: 14px; } }

.section-partners {
  background: #F2F2F2; }

.section-road-separator {
  height: 116px;
  overflow: hidden;
  position: relative; }
  .section-road-separator img {
    max-width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0; }
  @media (max-width: 960px) {
    .section-road-separator img {
      max-width: 120%; } }
  @media (max-width: 640px) {
    .section-road-separator img {
      max-width: 150%; } }

.section-faq {
  font-size: 20px; }
  @media (max-width: 640px) {
    .section-faq .section-heading-left {
      text-align: center; }
    .section-faq .text-large {
      font-size: 16px;
      text-align: center;
      margin: 10px 0; }
    .section-faq .toggle-accordion {
      margin-top: 20px; } }

.section-wide-note {
  background: #F5BA16;
  padding: 40px 0; }
  .section-wide-note .note-huge {
    display: flex;
    align-items: center;
    justify-content: center; }
    .section-wide-note .note-huge .note-image {
      margin-right: 20px; }
    .section-wide-note .note-huge .note-title {
      font-size: 65px; }
  @media (max-width: 960px) {
    .section-wide-note .note-huge .note-title {
      font-size: 48px; }
    .section-wide-note .note-huge .note-image {
      width: 56px; } }
  @media (max-width: 640px) {
    .section-wide-note {
      padding: 10px 5px; }
      .section-wide-note .note-huge .note-title {
        font-size: 16px; }
      .section-wide-note .note-huge .note-image {
        width: 36px; } }

.section-background {
  background-size: cover;
  background-position: center center; }
  .section-background-overlay, .section-background-overlay-light, .section-intro {
    position: relative; }
    .section-background-overlay:before, .section-background-overlay-light:before, .section-intro:before {
      height: 100%;
      width: 100%;
      position: absolute;
      content: '';
      display: block;
      background: rgba(73, 73, 73, 0.9);
      z-index: 0;
      top: 0;
      left: 0; }
    .section-background-overlay > *, .section-background-overlay-light > *, .section-intro > * {
      z-index: 1;
      position: relative; }
  .section-background-overlay-light:before, .section-intro:before {
    background: rgba(73, 73, 73, 0.6); }

.section-empty.section-background {
  padding-bottom: 45%; }

.section-validity {
  padding: 0 0 60px 0; }
  .section-validity .box-form {
    min-height: 265px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .section-validity .box-form input,
    .section-validity .box-form select {
      border: none; }
  .section-validity .status-checker {
    margin-bottom: 2em;
    margin-top: 1.5em; }

.section-intro {
  padding-top: 160px;
  padding-bottom: 50px; }
  .section-intro .heading-subtitle {
    color: #F5BA16; }

.section-dark {
  background: #494949; }

@media (max-width: 640px) {
  .section-measure .section-heading-left {
    text-align: center; }
  .section-measure .button, .section-measure .site-header-widgets input[type="submit"], .site-header-widgets .section-measure input[type="submit"] {
    display: block; }
  .section-measure img {
    max-width: 140px; } }

.section-error-page {
  text-align: center;
  padding-top: 200px; }
  .section-error-page .error-title {
    font-size: 180px;
    margin-bottom: 18px; }
  .section-error-page .error-subtitle {
    font-size: 40px; }
  .section-error-page .error-buttons {
    max-width: 1000px;
    margin: 70px auto 0; }
    .section-error-page .error-buttons .button, .section-error-page .error-buttons .site-header-widgets input[type="submit"], .site-header-widgets .section-error-page .error-buttons input[type="submit"] {
      margin: 25px; }
  @media (max-width: 640px) {
    .section-error-page {
      padding-top: 80px; }
      .section-error-page .error-buttons {
        margin-top: 40px; }
        .section-error-page .error-buttons .button, .section-error-page .error-buttons .site-header-widgets input[type="submit"], .site-header-widgets .section-error-page .error-buttons input[type="submit"] {
          margin: 10px;
          display: block; } }

.section-404-image img {
  width: 50%;
  display: block; }

@media (max-width: 640px) {
  .section-404-image img {
    width: 85%; } }

.section-contacts .box-form {
  margin-top: 40px; }

.section-slider {
  position: relative; }

.section-intro-slider {
  display: flex;
  align-items: flex-end;
  min-height: calc(50vh + 300px);
  padding-bottom: 160px; }
  .section-intro-slider .row {
    width: 100%; }
  .section-intro-slider .heading-title {
    font-size: 86px; }
  .section-intro-slider .heading-subtitle {
    font-weight: normal;
    margin-top: 20px; }
  .section-intro-slider .heading-cta .button, .section-intro-slider .heading-cta .site-header-widgets input[type="submit"], .site-header-widgets .section-intro-slider .heading-cta input[type="submit"], .section-intro-slider .heading-cta a {
    max-width: 500px;
    margin: 0 30px;
    width: 100%;
    text-align: center; }
    .section-intro-slider .heading-cta .button-ghost, .section-intro-slider .heading-cta a-ghost {
      color: white; }
  @media (max-width: 1160px) {
    .section-intro-slider .heading-cta .button, .section-intro-slider .heading-cta .site-header-widgets input[type="submit"], .site-header-widgets .section-intro-slider .heading-cta input[type="submit"], .section-intro-slider .heading-cta a {
      margin: 30px;
      width: auto; } }
  @media (max-width: 640px) {
    .section-intro-slider {
      padding-bottom: 80px;
      min-height: calc(50vh + 150px); }
      .section-intro-slider .section-heading .heading-title {
        font-size: 42px; }
      .section-intro-slider .section-heading .heading-cta {
        margin-top: 30px; }
        .section-intro-slider .section-heading .heading-cta .button, .section-intro-slider .section-heading .heading-cta .site-header-widgets input[type="submit"], .site-header-widgets .section-intro-slider .section-heading .heading-cta input[type="submit"], .section-intro-slider .section-heading .heading-cta a {
          width: 100%;
          margin: 10px 0; } }

.section-slider-navigation {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%); }
  .section-slider-navigation .slider-dot {
    display: inline-block;
    height: 15px;
    width: 15px;
    background: white;
    margin: 10px;
    opacity: .4;
    border-radius: 50%;
    cursor: pointer;
    transition: .3s; }
    .section-slider-navigation .slider-dot.is-current {
      background: #F5BA16; }
    .section-slider-navigation .slider-dot:hover {
      transform: scale(1.3);
      opacity: 1;
      transition: .3s; }
  @media (max-width: 640px) {
    .section-slider-navigation {
      bottom: 50px; } }

.section-route-pass .map-content {
  height: auto; }

.section-buyng-vignette-steps .buying-vignette-steps-image {
  display: flex;
  justify-content: space-around;
  width: 100%; }

.section-buyng-vignette-steps .no-bullets li {
  margin-left: 0;
  list-style-type: none; }

.steps-image-receipt {
  display: inline-block;
  width: 30%;
  vertical-align: top; }

div.documents-text-center {
  font-size: 20px; }
  div.documents-text-center div {
    margin: 0 auto;
    width: 500px; }
  div.documents-text-center p {
    margin: 5px; }
  div.documents-text-center p:hover {
    text-decoration: underline; }

@media (max-width: 640px) {
  div.documents-text-center {
    font-size: 16px; }
    div.documents-text-center div {
      width: 350px; } }

.price-container-end {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.price-value {
  margin-right: 4px; }

.price-container-start {
  display: flex;
  justify-content: flex-start; }

.span-right {
  display: inline-block;
  width: 100px;
  text-align: right;
  margin-right: 4px; }

.span-left {
  display: inline-block;
  width: 100px;
  text-align: left; }

.input-group label,
.input-group .input-group-title {
  line-height: 1.25;
  color: #494949;
  display: block;
  margin-bottom: 15px;
  font-weight: bold;
  text-align: left; }

.captcha-group {
  font-size: 20px;
  min-height: 95px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex-basis: 290px;
  margin: 42px 16px 0px; }

.input-group {
  font-size: 20px;
  min-height: 95px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 290px; }
  .input-group input,
  .input-group select,
  .input-group textarea {
    border-radius: 5px; }
  .input-group input,
  .input-group select,
  .input-group textarea, .input-group-radio {
    line-height: 1.3;
    padding: .5em 3em .5em 2em;
    border: 1px solid #CACACA;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: white;
    width: 100%;
    outline: none;
    font-size: 20px;
    height: 65px; }
    .input-group input:hover,
    .input-group select:hover,
    .input-group textarea:hover, .input-group-radio:hover {
      border-color: #b1b1b1; }
  .input-group textarea {
    min-height: 150px;
    padding: 1em 2em; }
  .input-group input[type="button"],
  .input-group input[type="submit"] {
    padding-left: 2em;
    padding-right: 2em; }
  .input-group .input-date {
    position: relative; }
    .input-group .input-date input {
      background: url(../images/icon-input-date.svg) no-repeat center right 1em;
      background-size: 1.5em;
      padding-right: 2.25em; }
      .input-group .input-date input::-webkit-inner-spin-button, .input-group .input-date input::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .input-group .input-date {
        /* IE10+ CSS styles */
        line-height: 0; } }
    .input-group .input-date > div {
      position: absolute;
      background: white;
      border: 1px solid #CACACA;
      margin-top: 2px; }
  .input-group input[type="radio"] {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 1px solid #494949;
    padding: 0; }
  .input-group input[type="checkbox"] {
    display: none; }
    .input-group input[type="checkbox"] + label {
      width: 1em;
      height: 1em;
      border-radius: 2px;
      border: 1px solid #CACACA;
      padding: 0; }
    .input-group input[type="checkbox"] + label.show-invalid {
      border: 1px solid red; }
    .input-group input[type="checkbox"]:checked + label {
      background: #F5BA16 url(../images/checkbox-small.svg) no-repeat center center;
      background-size: .6em;
      border-color: #F5BA16; }
  .input-group input[type="button"] {
    background: #F5BA16; }
  .input-group select {
    background: white url(../images/arrow_down.svg) no-repeat center right 2em; }
  .input-group-description {
    font-size: 16px;
    color: #777879;
    margin-top: .25em; }
  .input-group-checkbox {
    display: flex;
    margin-bottom: 5px; }
    .input-group-checkbox input[type="checkbox"] + label {
      flex: 0 0 1em;
      margin-right: 20px;
      margin-top: 3px;
      cursor: pointer; }
    .input-group-checkbox span,
    .input-group-checkbox label {
      font-size: 18px;
      font-weight: normal; }
  .input-group-toggle {
    display: flex;
    align-items: center; }
    .input-group-toggle label {
      font-weight: normal;
      margin: 0; }
    .input-group-toggle input[type="checkbox"] + label {
      margin: 3px 20px 0 0;
      cursor: pointer;
      border-radius: 100px;
      width: 50px;
      height: 28px;
      position: relative;
      transition: .3s; }
      .input-group-toggle input[type="checkbox"] + label:before {
        position: absolute;
        content: '';
        display: block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        top: 3px;
        left: 3px;
        background: #CACACA;
        transition: .3s; }
    .input-group-toggle input[type="checkbox"]:checked + label {
      transition: .3s;
      background: #F5BA16;
      border-color: #F5BA16; }
      .input-group-toggle input[type="checkbox"]:checked + label:before {
        transform: translateX(22px);
        transition: .3s;
        background: white; }
  .input-group-rememberme {
    display: flex;
    justify-content: space-between;
    flex-direction: row; }
  .input-group-radio {
    padding: .25em 2em;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: .75em; }
    .input-group-radio:last-child {
      margin-bottom: 0; }
    .input-group-radio input[type="radio"] {
      margin-right: 1em;
      flex-shrink: 0; }
      .input-group-radio input[type="radio"]:checked {
        background: #F5BA16;
        box-shadow: inset 0 0 0 4px white; }
    .input-group-radio-label {
      flex-grow: 1; }
  .input-group-auto {
    min-height: auto; }
  @media (max-width: 960px) {
    .input-group {
      margin-bottom: 25px;
      min-height: 0;
      font-size: 14px; }
      .input-group:last-child {
        margin-bottom: 0; }
      .input-group input,
      .input-group select,
      .input-group textarea {
        height: 45px;
        padding: .5em 1em;
        font-size: 14px; }
      .input-group textarea {
        height: auto;
        padding: 1em; }
      .input-group select {
        background-size: 14px;
        background-position: center right 1em; }
      .input-group label {
        text-align: center; }
      .input-group .button, .input-group .site-header-widgets input[type="submit"], .site-header-widgets .input-group input[type="submit"] {
        font-size: 16px; }
      .input-group span,
      .input-group label {
        font-size: 14px; }
      .input-group-checkbox span,
      .input-group-checkbox label {
        text-align: left; }
      .input-group-checkbox input[type="checkbox"] + label {
        margin-right: .5em; }
      .input-group-radio {
        font-size: 14px;
        height: 45px;
        padding: .5em 1em;
        margin-bottom: 1.5em; }
        .input-group-radio img {
          max-width: 45px; }
      .input-group .input-group-title {
        font-size: 18px;
        text-align: center; } }

.box-form div.form-error-msg {
  color: red;
  font-size: 30px;
  margin: 20px; }

.form-inline {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  width: 100%; }
  .form-inline-2 .input-group {
    flex-basis: 50%; }
  .form-inline-3 .input-group {
    flex-basis: calc(100% / 3); }
  .form-inline .input-group-submit {
    flex-grow: 0; }
    .form-inline .input-group-submit input[type="button"],
    .form-inline .input-group-submit input[type="submit"] {
      border: none; }
  @media (min-width: 960px) {
    .form-inline .input-group select,
    .form-inline .input-group input {
      border-radius: 0;
      border-right: none; } }

@media (min-width: 960px) and (min-width: 960px) {
  .form-inline .input-group:first-of-type input,
  .form-inline .input-group:first-of-type select {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; } }
  @media (min-width: 960px) {
    .form-inline .input-group:last-of-type input,
    .form-inline .input-group:last-of-type select {
      border-right: none; } }
  @media (min-width: 960px) and (min-width: 960px) {
    .form-inline .input-group:last-of-type input,
    .form-inline .input-group:last-of-type select {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; } }
  @media (min-width: 960px) {
    .form-inline input[type="button"] {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; } }
  .form-inline-border .input-group input,
  .form-inline-border .input-group select {
    border: 1px solid #CACACA;
    border-right: none; }
  .form-inline-border .input-group:last-child input,
  .form-inline-border .input-group:last-child select {
    border-right: 1px solid #CACACA; }
  .form-inline-full-border .input-group input,
  .form-inline-full-border .input-group select {
    border: 1px solid #CACACA; }
  .form-inline-full-border .input-group:last-child input,
  .form-inline-full-border .input-group:last-child select {
    border-right: 1px solid #CACACA; }
  @media (max-width: 960px) {
    .form-inline {
      display: block; }
      .form-inline-border .input-group input,
      .form-inline-border .input-group select {
        border: 1px solid #CACACA; } }

.delictPayment input[type="text"],
.delictPayment select#countryCode,
.delictPayment input[type="button"],
.delictPayment button[type="submit"] {
  font-size: 24px;
  height: 80px; }

.delictPayment button[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }
  .delictPayment button[disabled]:hover, .delictPayment button[disabled]:focus {
    background-color: #F5BA16;
    color: #FFFFFF; }

.form-validity input[type="text"],
.form-validity select#countryCode,
.form-validity input[type="button"],
.form-validity button[type="submit"] {
  font-size: 24px;
  height: 80px; }

.form-validity button[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }
  .form-validity button[disabled]:hover, .form-validity button[disabled]:focus {
    background-color: #F5BA16;
    color: #FFFFFF; }

@media (min-width: 640px) {
  .form-validity input[type="text"],
  .form-validity select#countryCode,
  .form-validity input[type="button"],
  .form-validity button[type="submit"] {
    font-size: 24px;
    height: 80px; } }

@media (max-width: 640px) {
  .form-validity {
    flex-direction: column; } }

.payroll-datepicker {
  flex-basis: 33.3%; }
  .payroll-datepicker .input-date {
    height: 38px !important; }
  .payroll-datepicker .input-date::placeholder {
    color: gray; }
  .payroll-datepicker + div.input-group .multi-select-input {
    margin-bottom: 2px; }

.payroll-datepicker.trialBalance {
  flex-basis: 15%; }
  .payroll-datepicker.trialBalance .input-date {
    height: 36px !important; }

.trialBalance {
  flex-basis: 15%; }

.reports-customers .react-select__control {
  overflow: auto;
  height: fit-content;
  max-height: 70px; }
  .reports-customers .react-select__control .react-select__value-container--has-value {
    height: fit-content; }

.multiselect-style {
  display: flex;
  flex-basis: 33%;
  flex-grow: 1; }
  .multiselect-style select {
    height: 38px !important; }

.report-fields {
  display: flex;
  flex: 1;
  height: 65px; }
  .report-fields .input-group-checkbox {
    flex-basis: 33%;
    align-items: flex-end; }
    .report-fields .input-group-checkbox .report-checkbox {
      margin-bottom: 5px;
      margin-right: 5px; }
      .report-fields .input-group-checkbox .report-checkbox + label {
        display: none; }
      .report-fields .input-group-checkbox .report-checkbox ~ label {
        line-height: initial; }
  .report-fields .input-group {
    flex-basis: 67%; }
    .report-fields .input-group input {
      border-radius: 4px; }
      .report-fields .input-group input::placeholder {
        color: #777879; }

.currentTime-checkbox {
  margin-top: 10px; }
  .currentTime-checkbox ~ label {
    margin-top: 5px; }

.icon {
  color: #494949;
  font-size: 24px; }

.icon-large {
  font-size: 35px;
  margin-right: 30px; }

.icon-red {
  color: #FF6262; }

.icon-green {
  color: #53D409; }

.icon-white {
  color: #FFFFFF; }

.icon-grey {
  color: #494949; }

.icon-primary {
  color: #F5BA16; }

.icon-disabled, .icon[disabled] {
  color: rgba(0, 0, 0, 0.2); }

.icon-filter-obligations {
  position: absolute;
  right: 23px;
  z-index: 2;
  cursor: pointer; }

.import-csv-button {
  position: absolute;
  right: 5px;
  z-index: 2; }
  @media (max-width: 640px) {
    .import-csv-button {
      position: relative;
      width: 80%;
      margin-left: 10%;
      margin-bottom: 20px; } }

.icon-loader {
  background: url(../images/loader.gif) no-repeat;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin-top: 8px; }

.section-heading {
  text-align: center;
  margin-bottom: 50px; }
  .section-heading .heading-title {
    font-size: 50px; }
    .section-heading .heading-title-small {
      font-size: 35px; }
    @media (min-width: 960px) {
      .section-heading .heading-title-large {
        font-size: 60px; } }
  .section-heading .text-large-underline + * {
    margin-top: 30px; }
  .section-heading .heading-undertitle {
    font-weight: normal;
    font-size: 26px; }
  .section-heading .heading-description {
    margin: 60px 0; }
    .section-heading .heading-description:last-child {
      margin-bottom: 0; }
  .section-heading .heading-colored {
    color: #F5BA16; }
  .section-heading .button, .section-heading .site-header-widgets input[type="submit"], .site-header-widgets .section-heading input[type="submit"] {
    font-size: 24px; }
  .section-heading .heading-about {
    font-size: 86px; }
  .section-heading .heading-cta {
    margin-top: 80px; }
  .section-heading-icon {
    display: flex;
    width: 100%; }
    .section-heading-icon .heading-icon {
      margin-right: 3em;
      flex: 0 0 140px; }
      @media (max-width: 960px) {
        .section-heading-icon .heading-icon {
          display: none; } }
      .section-heading-icon .heading-icon img {
        width: 100%; }
      .section-heading-icon .heading-icon-large {
        flex: 0 0 200px; }
    .section-heading-icon .heading-main {
      flex-grow: 1;
      text-align: left; }
  .section-heading-no-mb {
    margin-bottom: 0; }
  .section-heading-mb-large {
    margin-bottom: 110px; }
  .section-heading-left {
    text-align: left; }
  @media (max-width: 640px) {
    .section-heading .heading-title {
      font-size: 22px; }
    .section-heading .button, .section-heading .site-header-widgets input[type="submit"], .site-header-widgets .section-heading input[type="submit"] {
      font-size: 16px; } }

.multistep-picker {
  margin-top: 20px; }

.multistep-heading {
  font-size: 25px;
  text-align: center;
  margin-bottom: 50px; }

.multistep-title {
  padding-left: 2em;
  padding-right: 2em;
  font-size: 35px; }

.multistep-navigation {
  margin-bottom: 10px; }
  .multistep-navigation .navigation {
    counter-reset: multistep-nav; }
    .multistep-navigation .navigation-steps {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center; }
    .multistep-navigation .navigation-step-inner {
      background: white;
      text-align: center;
      border-radius: 50%;
      border: 3px solid #F5BA16;
      transition: .5s;
      transform: rotate(45deg);
      border-color: #F5BA16;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 38px; }
      .multistep-navigation .navigation-step-inner:hover {
        border-color: #FFD677; }
      .multistep-navigation .navigation-step-inner:before {
        content: counter(section);
        display: inline-block;
        color: #494949;
        transform: rotate(-45deg); }
    .multistep-navigation .navigation-step {
      counter-increment: section;
      display: inline-block;
      cursor: pointer;
      margin: 0 60px;
      font-size: 19px;
      position: relative; }
      .multistep-navigation .navigation-step:after {
        content: '';
        display: block;
        height: 2px;
        width: calc(38px + 60px - 30px);
        left: calc(100% + 20px);
        background: url(../images/dot.svg) repeat-x;
        position: absolute;
        top: 50%; }
      .multistep-navigation .navigation-step:last-of-type:after {
        display: none; }
      .multistep-navigation .navigation-step:first-child {
        margin-left: 0; }
      .multistep-navigation .navigation-step:last-child {
        margin-right: 0; }
      .multistep-navigation .navigation-step .navigation-step-inner {
        border-color: rgba(245, 186, 22, 0.5); }
      .multistep-navigation .navigation-step.is-current .navigation-step-inner {
        transform: rotate(45deg);
        border-color: #F5BA16; }
        .multistep-navigation .navigation-step.is-current .navigation-step-inner:before {
          transform: rotate(-45deg);
          transition: .5s; }
      .multistep-navigation .navigation-step.is-current ~ li .navigation-step-inner {
        border-color: #F2F2F2;
        border-radius: 50%;
        transition: .5s;
        transform: rotate(0); }
        .multistep-navigation .navigation-step.is-current ~ li .navigation-step-inner:before {
          transform: rotate(0);
          transition: .5s; }
        .multistep-navigation .navigation-step.is-current ~ li .navigation-step-inner:hover {
          border-color: #ddd; }
      .multistep-navigation .navigation-step.is-final .navigation-step-inner:before {
        content: '';
        background: transparent url(../images/check-white-dark.svg) no-repeat left -3px center;
        background-size: contain;
        width: 30px;
        height: 30px; }
      @media (max-width: 1200px) {
        .multistep-navigation .navigation-step {
          margin: 0 35px; }
          .multistep-navigation .navigation-step:after {
            width: 40px; } }
      @media (max-width: 640px) {
        .multistep-navigation .navigation-step {
          margin: 0 15px; }
          .multistep-navigation .navigation-step:after {
            width: 27px;
            left: 47px; }
          .multistep-navigation .navigation-step-inner {
            font-size: 15px;
            width: 45px;
            height: 45px; } }

.multistep-next-wrapper {
  margin-top: 95px; }

@media (max-width: 960px) {
  .multistep-step {
    max-width: 700px;
    margin: 0 auto; }
  .multistep-title {
    padding-left: 0;
    padding-right: 0;
    font-size: 18px; }
  .multistep-navigation {
    margin-bottom: 30px; }
  .multistep-next-wrapper {
    margin-top: 40px; }
    .multistep-next-wrapper .button, .multistep-next-wrapper .site-header-widgets input[type="submit"], .site-header-widgets .multistep-next-wrapper input[type="submit"] {
      display: block; } }

.box-form .box-form-option {
  height: calc(100% - 22px); }

.box-form .box-form-section .box-form-header {
  font-size: 20px;
  font-weight: bold;
  padding-top: 42px; }

.box-form .box-form-section:first-of-type .box-form-header {
  padding-top: 0; }

.box-form-border {
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  border: 1px solid #CACACA;
  border-radius: 5px;
  padding: 45px; }
  .box-form-border-narrow {
    max-width: 1100px; }

.box-form .form-inline,
.box-form .input-group {
  margin-bottom: 1.25em; }

.box-form .form-inline .input-group {
  margin-bottom: 0; }

.box-form .form-footer-cta {
  margin-top: 30px; }
  .box-form .form-footer-cta .button, .box-form .form-footer-cta .site-header-widgets input[type="submit"], .site-header-widgets .box-form .form-footer-cta input[type="submit"] {
    font-size: 24px; }

@media (max-width: 960px) {
  .box-form {
    border: none;
    padding: 0; }
    .box-form .form-inline .input-group {
      margin-bottom: 15px; } }

.box-solid {
  background: #F2F2F2;
  display: inline-block;
  padding: 1em 3em;
  text-align: center;
  margin: 1em;
  cursor: pointer;
  border-radius: 5px; }
  .box-solid .text-value {
    font-size: 28px;
    font-weight: bold;
    display: block;
    margin-bottom: .25em; }
  .box-solid .text-type {
    display: block;
    font-size: 18px; }
  .box-solid.is-selected, .box-solid:hover {
    background: #F5BA16;
    color: white; }
  @media (max-width: 640px) {
    .box-solid {
      display: block; } }

.box-picker {
  display: flex;
  flex-direction: column;
  border: 1px solid #CACACA;
  border-radius: 5px;
  text-align: center;
  padding: 40px;
  cursor: pointer; }
  .box-picker:hover, .box-picker.is-selected {
    background: #F5BA16;
    color: white; }
    .box-picker:hover img, .box-picker.is-selected img {
      filter: invert(100%); }
  .box-picker:active {
    transition: .1s;
    transform: scale(0.95); }
  .box-picker .option-image-wrapper {
    display: block;
    align-items: center;
    justify-content: center; }
  .box-picker .option-title {
    font-size: 24px;
    font-weight: bold;
    margin: .8em;
    white-space: nowrap; }
  .box-picker .option-description {
    font-size: 18px; }
  @media (max-width: 640px) {
    .box-picker .option-image {
      max-width: 100px;
      opacity: .7; }
    .box-picker .option-title {
      margin-bottom: .4em; }
    .box-picker .option-description {
      font-size: 16px;
      line-height: 1.25; } }

.box-summary-header {
  display: flex;
  align-items: center; }
  .box-summary-header img {
    margin-right: 1.5em; }

.box-summary-title {
  font-size: 20px; }

.box-summary-purchases {
  margin-bottom: 1em; }

.box-summary-final {
  font-size: 24px;
  font-weight: bold;
  text-align: right;
  margin-bottom: .5em; }

@media (max-width: 640px) {
  .box-summary {
    padding: 0; }
    .box-summary-final {
      text-align: center; }
    .box-summary-header, .box-summary-purchases {
      padding-left: 20px;
      padding-right: 20px; } }

.box-summary-text {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin-top: .5em; }

.payment-info-text {
  font-weight: 400;
  padding-right: 10px; }

.cart-summary-title {
  display: block;
  width: 100%;
  font-weight: bold;
  cursor: pointer;
  overflow: hidden; }

.cart-summary-title.delict-cart {
  text-align: left; }

.toggle-tabs.toggle-tabs-clean {
  border: none; }
  .toggle-tabs.toggle-tabs-clean .tabs-header {
    border: none;
    background: white; }
  .toggle-tabs.toggle-tabs-clean .tab-content {
    padding: 20px; }
  .toggle-tabs.toggle-tabs-clean .tab-content-default {
    display: none; }
  .toggle-tabs.toggle-tabs-clean .tab-content-default.is-current {
    display: block; }
  .toggle-tabs.toggle-tabs-clean .tabs-header-item {
    font-size: 20px;
    margin-right: 10px;
    flex-grow: 0; }
    .toggle-tabs.toggle-tabs-clean .tabs-header-item.is-wide {
      flex-grow: 1;
      text-align: right; }
    .toggle-tabs.toggle-tabs-clean .tabs-header-item.is-current {
      background: transparent;
      border-bottom: 3px solid #F5BA16;
      color: #494949; }
      .toggle-tabs.toggle-tabs-clean .tabs-header-item.is-current:hover {
        background: transparent;
        opacity: .7; }
    .toggle-tabs.toggle-tabs-clean .tabs-header-item:hover {
      background: transparent;
      opacity: .7; }
  .toggle-tabs.toggle-tabs-clean .tabs-main {
    padding: 0;
    border: none; }
  .toggle-tabs.toggle-tabs-clean .tabs-item {
    border: 1px solid #CACACA;
    border-radius: 5px;
    padding: 45px;
    margin-top: 30px; }
  @media (max-width: 960px) {
    .toggle-tabs.toggle-tabs-clean .tabs-item {
      padding: 0;
      border: none; }
    .toggle-tabs.toggle-tabs-clean .tabs-header {
      flex-wrap: nowrap;
      display: flex;
      max-width: 100%;
      overflow-x: auto;
      box-shadow: inset 0px -3px 0 0px #f2f2f2; }
    .toggle-tabs.toggle-tabs-clean .tabs-header-item {
      flex-shrink: 0;
      font-size: 16px;
      padding-left: 0;
      padding-right: 0; } }

.toggle-tabs .tabs-header {
  display: flex;
  background: #F2F2F2;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }

.toggle-tabs .tabs-header-item {
  padding: .6em .8em;
  font-weight: bold;
  font-size: 24px;
  flex-grow: 1;
  text-align: center;
  cursor: pointer; }
  .toggle-tabs .tabs-header-item:hover {
    background: #f8f8f8; }
  .toggle-tabs .tabs-header-item.is-current {
    background: #F5BA16;
    color: white; }
    .toggle-tabs .tabs-header-item.is-current:hover {
      background: #FFD677; }

.toggle-tabs .tabs-item {
  padding: 60px 80px;
  display: none; }
  .toggle-tabs .tabs-item.is-current {
    display: block; }

.toggle-tabs .tabs-item-small-padding {
  padding: 10px 10px !important; }

.toggle-tabs .tabs-main {
  border: 1px solid #CACACA;
  border-top: none; }

.toggle-tabs .img-text-inline {
  margin-bottom: 30px; }

.toggle-tabs .table-price-mobile {
  display: none; }

@media (max-width: 960px) {
  .toggle-tabs .tabs-item {
    padding: 1.5em; } }

@media (max-width: 640px) {
  .toggle-tabs .tabs-header {
    display: block; }
    .toggle-tabs .tabs-header-item {
      font-size: 16px; }
  .toggle-tabs .tabs-main {
    border-bottom: none; }
  .toggle-tabs .tabs-item {
    padding: 0; }
  .toggle-tabs .img-text-inline {
    margin: 0;
    padding: 10px;
    flex-wrap: wrap; }
    .toggle-tabs .img-text-inline h3 {
      flex-basis: 100%;
      font-size: 15px; }
  .toggle-tabs .img-text-inline > * {
    flex-basis: 33.333333%; }
  .toggle-tabs .table-price-desktop {
    display: none; }
  .toggle-tabs .table-price-mobile {
    display: table; } }

.toggle-accordion .accordion-item {
  border: 1px solid #CACACA;
  background: white;
  display: block;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 5px; }
  .toggle-accordion .accordion-item .item-title {
    padding-right: 50px; }
  .toggle-accordion .accordion-item .item-title:after {
    position: absolute;
    top: 50%;
    right: 1.7em;
    transform: translateY(-50%) rotate(0) scale(0.5);
    opacity: .5;
    transition: .3s;
    content: '';
    width: 30px;
    height: 30px;
    background: url(../images/plus.svg) no-repeat;
    background-size: contain;
    display: block; }
  .toggle-accordion .accordion-item:hover {
    border-color: #aaa; }
  .toggle-accordion .accordion-item .item-title:hover:after {
    transform: translateY(-50%) rotate(0) scale(0.7);
    opacity: 1; }
  .toggle-accordion .accordion-item:last-of-type {
    margin-bottom: 0; }
  .toggle-accordion .accordion-item.is-expanded .item-content {
    display: block; }
  .toggle-accordion .accordion-item.is-expanded .item-title {
    background: #F5BA16;
    color: white; }
  .toggle-accordion .accordion-item.is-expanded .item-title:after {
    opacity: 1;
    height: 2px;
    background: white;
    top: calc(1em + 13px); }

.toggle-accordion .item-content {
  display: none;
  padding: 1em 1.5em;
  background: #F2F2F2; }
  .toggle-accordion .item-content .formatted-text {
    padding: 0; }

.toggle-accordion .item-title {
  font-weight: normal;
  padding: 1em 2.5em 1em 1.5em;
  position: relative; }

@media (max-width: 640px) {
  .toggle-accordion .accordion-item:after {
    transform: translateY(-50%) rotate(0) scale(0.5);
    right: .7em; }
  .toggle-accordion .item-title {
    padding: 20px;
    font-size: 14px; }
  .toggle-accordion .item-description {
    font-size: 14px; }
  .toggle-accordion .item-content {
    font-size: 14px;
    padding: 20px; } }

.img-text-inline {
  display: flex;
  align-items: center;
  font-size: 18px; }
  .img-text-inline img {
    opacity: .9;
    max-width: 80px;
    margin-right: 1.25em; }

.slider {
  position: relative; }
  .slider-controls {
    position: absolute;
    width: 100%;
    top: 50%;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    transform: translateY(-50%); }
    .slider-controls .slide-right,
    .slider-controls .slide-left {
      background-color: #FFFFFF;
      background-position: center center;
      background-repeat: no-repeat;
      width: 64px;
      height: 64px;
      display: inline-block;
      border: none;
      border-radius: 50%;
      pointer-events: all;
      transition: .2s; }
      .slider-controls .slide-right:hover,
      .slider-controls .slide-left:hover {
        opacity: .8;
        transition: .2s; }
      .slider-controls .slide-right:focus,
      .slider-controls .slide-left:focus {
        outline: none; }
    .slider-controls .slide-right {
      background-image: url(../images/arrow_down.svg);
      transform: rotate(-90deg); }
    .slider-controls .slide-left {
      background-image: url(../images/arrow_down.svg);
      transform: rotate(90deg); }
  .slider-logos .slider-items {
    display: flex;
    justify-content: center; }
  .slider-logos .slider-item {
    height: 100%;
    background: white;
    border: 1px solid #CACACA;
    padding: 30px 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    border-radius: 5px;
    transition: .3s; }
    .slider-logos .slider-item:hover {
      transform: scale(1.05);
      transition: .3s; }
    .slider-logos .slider-item:active {
      transform: scale(0.95);
      transition: .1s; }
    .slider-logos .slider-item img {
      display: block; }
  @media (max-width: 640px) {
    .slider-logos .slider-controls {
      display: none; }
    .slider-logos .slider-item {
      display: block;
      margin-bottom: 20px;
      text-align: center; }
      .slider-logos .slider-item img {
        display: inline-block; }
    .slider-logos .slider-items {
      display: block; } }

.payment-picker {
  display: flex;
  justify-content: space-between; }

.payment-summary {
  max-width: 540px;
  width: 100%; }

.payment-information {
  max-width: 650px;
  width: 100%; }
  .payment-information .input-group {
    margin-bottom: 1.75em; }

@media (max-width: 640px) {
  .payment-picker {
    display: block;
    max-width: 650px; }
  .payment-summary {
    max-width: 100%; } }

.note {
  font-size: 16px;
  padding: 1.5em;
  background: #F2F2F2;
  color: #707070;
  text-align: center; }
  .note-default {
    background: rgba(245, 186, 22, 0.2); }
  .note-large {
    padding: .5em 1.5em .6em;
    font-size: 18px; }
    .note-large .text-large {
      transform: translateY(0.1em);
      display: inline-block;
      margin-left: 1em;
      font-size: 24px; }
  .note-fullwidth {
    width: 100%; }

.left-align {
  text-align: left; }

.purchase-success {
  text-align: center; }
  .purchase-success .success-title {
    font-size: 34px; }
  .purchase-success .success-icon {
    text-align: center; }
    .purchase-success .success-icon img {
      max-width: 170px;
      width: 100%;
      margin-bottom: 10px; }
  .purchase-success .success-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    font-size: 24px; }
  .purchase-success .button, .purchase-success .site-header-widgets input[type="submit"], .site-header-widgets .purchase-success input[type="submit"] {
    display: inline-flex;
    align-items: center;
    margin: 1em;
    max-height: 80px; }
    .purchase-success .button-ghost {
      font-size: 18px;
      padding-top: .95em;
      padding-bottom: .95em; }
    .purchase-success .button .icon, .purchase-success .site-header-widgets input[type="submit"] .icon, .site-header-widgets .purchase-success input[type="submit"] .icon {
      margin-right: 20px;
      width: 33px; }
  @media (max-width: 640px) {
    .purchase-success .success-title {
      font-size: 22px;
      display: block;
      line-height: 1.25;
      margin-bottom: 65px; }
    .purchase-success .success-icon img {
      max-width: 100px;
      margin-bottom: 50px; }
    .purchase-success .success-buttons {
      display: block; }
      .purchase-success .success-buttons .icon {
        width: 20px; }
    .purchase-success .button, .purchase-success .site-header-widgets input[type="submit"], .site-header-widgets .purchase-success input[type="submit"] {
      margin: 45px 0 0;
      display: block; }
      .purchase-success .button-ghost {
        font-size: 16px; } }

body.purchase-step-final {
  background: url(../images/road-all.svg) no-repeat bottom center;
  background-size: auto; }
  @media (max-width: 1200px) {
    body.purchase-step-final {
      background-size: 120%; } }
  @media (max-width: 640px) {
    body.purchase-step-final {
      background-size: 1200px;
      background-position: bottom 80px center;
      border-bottom: 80px solid #494949; } }

.status-checker {
  font-weight: bold;
  padding-left: 1.5em;
  position: relative; }
  .status-checker:before {
    content: '';
    display: inline-block;
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
    background-color: #f0f0f0;
    margin-top: .3em;
    left: 0;
    position: absolute; }
  .status-checker.status-active:before {
    background: #53D409; }
  .status-checker.status-inactive:before {
    background: #FF6262; }
  .status-checker.status-pending:before {
    background: #FFD800; }

.data-table {
  width: 100%;
  background: rgba(247, 247, 247, 0.7);
  border-left: 6px solid #F5BA16;
  font-size: 20px;
  display: flex;
  flex-flow: row wrap; }
  .data-table div {
    box-sizing: border-box; }
  .data-table header.data-row {
    background: #F2F2F2;
    font-weight: bold; }
  .data-table .data-row {
    width: 100%;
    display: flex; }
    @media (min-width: 640px) {
      .data-table .data-row {
        padding-left: 5em; } }
  .data-table .data-cell {
    padding: 1em; }
  .data-table.columns-3 .data-cell {
    width: calc(100% / 3); }

.table-actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center; }
  .table-actions .actions-select {
    flex-grow: 0;
    flex-direction: row;
    min-height: 0; }
    .table-actions .actions-select .button, .table-actions .actions-select .site-header-widgets input[type="submit"], .site-header-widgets .table-actions .actions-select input[type="submit"] {
      font-size: 20px;
      margin-left: 20px;
      padding: 0.9em 3em; }
  @media (max-width: 640px) {
    .table-actions {
      justify-content: center;
      flex-direction: column; }
      .table-actions .actions-select {
        width: 100%; }
        .table-actions .actions-select .button, .table-actions .actions-select .site-header-widgets input[type="submit"], .site-header-widgets .table-actions .actions-select input[type="submit"] {
          font-size: 16px;
          padding: .6em 3em; } }

.pagination a {
  display: inline-block;
  padding: .25em;
  color: #494949; }
  .pagination a.is-current {
    color: #F5BA16;
    text-decoration: underline; }

.loading-mask {
  width: 100%;
  z-index: 100;
  position: fixed;
  top: 0px; }

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100; }
  .overlay .maintenance {
    margin-left: auto;
    margin-right: auto;
    background-color: #FFFFFF;
    width: 50%;
    margin-top: 100px;
    border-radius: 20px;
    padding: 50px;
    text-align: center;
    max-width: 650px; }
    @media (max-width: 960px) {
      .overlay .maintenance {
        width: 90%;
        padding-left: 10px;
        padding-right: 10px; } }

.custom-checkbox input[type=checkbox], .panel-route-pass .custom-checkbox input[type=checkbox] {
  display: none; }

.custom-checkbox input[type="checkbox"] + label, .panel-route-pass .custom-checkbox input[type="checkbox"] + label {
  background-image: url(../images/checkmark.svg);
  background-size: contain;
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: inline-block;
  border: none; }

.custom-checkbox input[type="checkbox"]:checked + label, .panel-route-pass .custom-checkbox input[type="checkbox"]:checked + label {
  background: unset;
  background-image: url(../images/checkmark-dark.svg);
  background-size: contain; }

.custom-checkbox .custom-checkbox-label, .panel-route-pass .custom-checkbox .custom-checkbox-label {
  cursor: pointer;
  vertical-align: super;
  margin-left: 10px;
  display: inline-block; }

.multi-value-editor {
  width: 100%;
  position: relative; }
  .multi-value-editor table {
    margin: 0; }
    .multi-value-editor table tr {
      border: 0; }
      .multi-value-editor table tr td {
        padding: 0; }
        .multi-value-editor table tr td input {
          width: 100%;
          padding: 0 5px;
          box-sizing: border-box;
          border-width: 1px;
          border-radius: 0;
          font-size: 14px; }
        .multi-value-editor table tr td input.fld-invalid {
          border: 1px solid red;
          outline: none; }
        .multi-value-editor table tr td input[type=checkbox] {
          width: 20px;
          vertical-align: inherit; }
        .multi-value-editor table tr td .icon {
          height: 24px;
          cursor: pointer; }
  .multi-value-editor .editor-dropdown {
    background-color: #FFF;
    width: 100%;
    z-index: 110;
    overflow-y: auto;
    max-height: 200px;
    border-radius: 3px;
    box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.7);
    display: none;
    position: absolute;
    table-layout: fixed; }
    .multi-value-editor .editor-dropdown-open {
      display: table; }
    .multi-value-editor .editor-dropdown tr {
      padding: 3px 6px;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      border-bottom: none; }
      .multi-value-editor .editor-dropdown tr td {
        overflow: hidden;
        padding-left: 4px; }
        .multi-value-editor .editor-dropdown tr td .icon {
          height: 18px;
          width: 18px;
          font-size: 16px; }
      .multi-value-editor .editor-dropdown tr td.delete-icon {
        width: 18px;
        padding-left: 0; }
        .multi-value-editor .editor-dropdown tr td.delete-icon .icon {
          cursor: pointer;
          display: none; }
    .multi-value-editor .editor-dropdown tr:hover {
      background-color: rgba(0, 0, 0, 0.05); }
      .multi-value-editor .editor-dropdown tr:hover td.delete-icon .icon {
        display: block; }

.upload-file-container {
  width: 380px;
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 4px; }
  .upload-file-container.full-width {
    width: 100%; }
  .upload-file-container .form-error-msg {
    color: #FF6262;
    margin-top: 20px; }
  .upload-file-container .dropzone {
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 20px;
    border: 1px solid #CACACA;
    border-radius: 4px; }
    .upload-file-container .dropzone-hightlight {
      background: #F2F2F2; }
    .upload-file-container .dropzone .file-input {
      display: none; }
    .upload-file-container .dropzone .file-upload-icon {
      justify-content: space-around;
      margin-top: 20px;
      font-size: 40px; }
  .upload-file-container .progress-bar-file {
    padding: 20px 0 0 0;
    overflow: hidden; }
    .upload-file-container .progress-bar-file .progress-bar {
      width: 100%;
      height: 8px;
      border-radius: 5px;
      background-color: #FFD677; }
      .upload-file-container .progress-bar-file .progress-bar-disabled {
        background-color: #CACACA; }
      .upload-file-container .progress-bar-file .progress-bar .progress {
        height: 100%;
        margin: 0;
        border-radius: 5px;
        background-color: #F5BA16; }
  .upload-file-container .actions-wrapper {
    text-align: center;
    padding-top: 20px; }
    .upload-file-container .actions-wrapper button {
      margin-right: 0; }
    .upload-file-container .actions-wrapper .button-upload-disabled {
      background: #CACACA;
      cursor: not-allowed; }

@media (min-width: 960px) {
  .map-content .field-container.panel-route-pass .custom-checkbox {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -12px; } }

.map-content .field-container.panel-route-pass .input-group {
  margin-top: 10px;
  min-height: auto;
  font-size: 16px; }
  .map-content .field-container.panel-route-pass .input-group .chip-label {
    display: block; }
  .map-content .field-container.panel-route-pass .input-group label {
    white-space: nowrap;
    margin-bottom: 2px; }
  .map-content .field-container.panel-route-pass .input-group select {
    padding-left: 10px;
    background: white url(../images/arrow_down.svg) no-repeat center right 1em; }
  .map-content .field-container.panel-route-pass .input-group .chip-wrapper {
    display: flex; }
    .map-content .field-container.panel-route-pass .input-group .chip-wrapper .chip {
      display: inline-block;
      width: 100%;
      min-width: 0;
      height: 40px;
      padding: 8px;
      background: #FFFFFF;
      border: 0;
      border-radius: 0;
      box-shadow: inset 0 0 0 2px #F5BA16;
      outline: none;
      font-size: 16px;
      text-align: center;
      cursor: pointer; }
      .map-content .field-container.panel-route-pass .input-group .chip-wrapper .chip:first-of-type {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px; }
      .map-content .field-container.panel-route-pass .input-group .chip-wrapper .chip:last-of-type {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px; }
      .map-content .field-container.panel-route-pass .input-group .chip-wrapper .chip:not(:first-of-type) {
        margin-left: -2px; }
      .map-content .field-container.panel-route-pass .input-group .chip-wrapper .chip:hover {
        background: #FFD677;
        color: #FFFFFF; }
      .map-content .field-container.panel-route-pass .input-group .chip-wrapper .chip-selected {
        background: #F5BA16;
        color: #FFFFFF; }
      .map-content .field-container.panel-route-pass .input-group .chip-wrapper .chip img {
        padding: 4px 10px 0 10px; }
  .map-content .field-container.panel-route-pass .input-group .map-dropdown {
    height: 40px;
    box-shadow: inset 0 0 0 2px #F5BA16;
    border-radius: 5px;
    border: none;
    font-size: 16px; }
  .map-content .field-container.panel-route-pass .input-group .multi-select-input .react-select__input input {
    height: 1em; }
  .map-content .field-container.panel-route-pass .input-group .multi-select-input .react-select__menu {
    box-shadow: inset 0 0 0 2px #F5BA16, 2px 2px 8px -1px rgba(0, 0, 0, 0.7); }
  .map-content .field-container.panel-route-pass .input-group .multi-select-input .react-select__control {
    height: 40px;
    box-shadow: inset 0 0 0 2px #F5BA16;
    border-radius: 5px;
    border: none;
    font-size: 16px; }
  .map-content .field-container.panel-route-pass .input-group .multi-select-input .react-select__indicators {
    display: none; }
  .map-content .field-container.panel-route-pass .input-group .multi-select-input .react-select__control {
    padding-right: 36px;
    background: white url(../images/arrow_down.svg) no-repeat center right 1em; }

.map-content .field-container.panel-route-pass div.errorMessageBox {
  text-align: center;
  width: 97%;
  margin: 0 auto;
  border-radius: 3px;
  color: red;
  font-size: 16px; }

.map-content .react-datepicker-wrapper {
  width: 100%; }
  .map-content .react-datepicker-wrapper .routing-time-picker {
    width: 100%;
    height: 40px;
    padding: 10px;
    box-shadow: inset 0 0 0 2px #F5BA16;
    border-radius: 5px;
    border: none;
    font-size: 16px; }

.dnd-sortable-list {
  padding-top: 11px; }
  .dnd-sortable-list-item {
    position: relative;
    box-shadow: inset -1px 0 0 #F5BA16, inset 0 -1px 0 #F5BA16, inset 1px 0 0 #F5BA16;
    cursor: pointer; }
    .dnd-sortable-list-item:first-of-type {
      box-shadow: inset 0 1px 0 #F5BA16, inset -1px 0 0 #F5BA16, inset 0 -1px 0 #F5BA16, inset 1px 0 0 #F5BA16;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px; }
    .dnd-sortable-list-item:last-of-type {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px; }
  .dnd-sortable-list .sortable-index {
    display: inline-block;
    margin: 5px;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    text-align: center;
    background: #F5BA16;
    font-size: 18px;
    box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.7); }
  .dnd-sortable-list .sortable-items-close {
    position: absolute;
    top: 4px;
    right: 5px;
    cursor: pointer; }

.map-custom-icon {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  text-align: center;
  background: #F5BA16;
  font-size: 18px;
  box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.7); }

.button-primary-disabled,
.button-primary-disabled:hover {
  background: #CACACA;
  cursor: not-allowed; }

.contract-checkbox {
  margin-top: 5px; }
  .contract-checkbox ~ label {
    display: flex; }

.back-button {
  position: absolute;
  top: .5em;
  left: .5em;
  display: flex;
  height: 3em;
  opacity: .5;
  transition: .3s;
  background: none;
  border: none;
  font-size: 18px;
  outline: none; }

.back-button-text {
  margin-top: 2px; }

.back-button:hover {
  opacity: 1;
  transition: .3s; }

.payment-summary-title {
  display: block;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
  color: #494949; }

.all-items-checkbox ~ label {
  position: relative;
  top: -5px;
  left: -30px; }

.min-amount-payment {
  margin-top: -15px;
  text-align: left;
  color: #494949;
  font-size: 16px; }

select::-ms-expand {
  display: none; }

.transport-picker .multistep-steps {
  margin-bottom: 20px; }

.border-checkbox-wrapper {
  position: absolute;
  right: 6px;
  top: 5px; }

.predefined-route-close {
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
  margin-left: 4px;
  cursor: pointer; }

.shifts-checkbox-wrapper .input-group-checkbox {
  width: 100%;
  margin-top: 10px;
  margin-left: 1px; }

.shifts-details .react-datepicker-wrapper ~ input {
  margin: -1px; }

.nosplit_vignettes {
  padding: 0 20px 20px 20px;
  text-align: center; }

.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  transition: 0.3s; }
  .popup-wrapper.is-visible {
    pointer-events: all;
    transition: 0.3s; }
    .popup-wrapper.is-visible .popup-box {
      transform: scale(1);
      transition: 0.3s;
      opacity: 1;
      padding: 60px; }
      .popup-wrapper.is-visible .popup-box .popup-title {
        margin-top: 5px;
        margin-bottom: 40px; }
    .popup-wrapper.is-visible .popup-backdrop {
      transition: 0.3s;
      opacity: 1; }

.popup-box {
  padding: 80px;
  text-align: center;
  background: white;
  border-radius: 5px;
  z-index: 1;
  position: relative;
  transform: scale(0);
  opacity: 0;
  transition: 0.3s;
  margin: 20px; }

.popup-title {
  margin-top: 15px;
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 60px; }

.popup-buttons {
  font-size: 24px; }

.popup-close {
  position: absolute;
  top: .5em;
  right: .5em;
  transform: rotate(45deg);
  opacity: .5;
  transition: .3s;
  width: 3em;
  height: 3em;
  background: url(../images/plus.svg) no-repeat center center;
  background-size: 1.7em;
  display: block;
  cursor: pointer; }
  .popup-close:hover {
    opacity: 1;
    transition: .3s; }

.popup-backdrop {
  background: rgba(73, 73, 73, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: 0.3s; }

@media (max-width: 960px) {
  .popup-box {
    padding: 50px; }
  .popup-buttons .button, .popup-buttons .site-header-widgets input[type="submit"], .site-header-widgets .popup-buttons input[type="submit"] {
    display: block; }
    .popup-buttons .button:last-child, .popup-buttons .site-header-widgets input:last-child[type="submit"], .site-header-widgets .popup-buttons input:last-child[type="submit"] {
      margin-bottom: 0; } }

.popup .notifications-erp-popup {
  width: 1000px;
  padding: 20px;
  height: 500px;
  overflow: auto;
  overflow-y: auto;
  background-color: #F2F2F2;
  border-radius: 5px;
  text-align: left; }
  .popup .notifications-erp-popup p {
    margin: 0; }

.popup .notifications-popup {
  max-width: 100%;
  padding: 20px;
  overflow: visible;
  overflow-y: auto;
  background-color: #F2F2F2;
  border-radius: 5px;
  text-align: left; }
  .popup .notifications-popup p {
    margin: 0; }

.popup.popup-large .popup-wrapper .popup-box {
  flex-basis: 1400px;
  padding: 30px;
  text-align: left; }
  @media (min-width: 960px) {
    .popup.popup-large .popup-wrapper .popup-box {
      height: 780px; } }
  .popup.popup-large .popup-wrapper .popup-box .tab-content {
    padding: 10px 0 0 0; }
  .popup.popup-large .popup-wrapper .popup-box .tabs-header-children {
    margin-right: 0;
    padding-right: 0; }
  .popup.popup-large .popup-wrapper .popup-box .popup-title {
    margin-bottom: 10px;
    margin-top: 0;
    text-align: center; }
  .popup.popup-large .popup-wrapper .popup-box .field-container {
    margin-top: 10px; }
  .popup.popup-large .popup-wrapper .popup-box .custom-button-holder {
    display: inline-block; }
    .popup.popup-large .popup-wrapper .popup-box .custom-button-holder button.custom-button {
      height: 38px;
      width: 150px;
      padding: 0;
      font-size: 18px; }
      .popup.popup-large .popup-wrapper .popup-box .custom-button-holder button.custom-button img {
        width: 28px;
        margin-right: 5px; }
  .popup.popup-large .popup-wrapper .popup-box .input-group input, .popup.popup-large .popup-wrapper .popup-box .input-group select, .popup.popup-large .popup-wrapper .popup-box .input-group textarea, .popup.popup-large .popup-wrapper .popup-box .input-group-radio {
    padding-left: 1em; }

.notifications-grid .popup-box {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90%; }
  .notifications-grid .popup-box .sms-notifications-popup {
    margin-bottom: 20px; }
  .notifications-grid .popup-box .popup-title {
    margin-bottom: 20px !important; }

ul.custom-context-menu {
  position: fixed;
  z-index: 1000;
  top: 50px;
  left: 50px;
  width: 300px;
  background-color: white;
  box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.7); }
  ul.custom-context-menu li {
    padding: 4px 8px;
    cursor: pointer;
    font-size: 16px;
    height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    ul.custom-context-menu li .icon {
      margin-right: 20px; }
    ul.custom-context-menu li span {
      vertical-align: super; }
    ul.custom-context-menu li span.option-disabled {
      color: #AAA; }
  ul.custom-context-menu li:hover {
    background-color: #EEE; }

.panel-popup.popup-query {
  margin-bottom: 40px;
  text-align: left; }

.panel-popup.route-details {
  display: flex;
  padding: 0; }
  .panel-popup.route-details .left-panel {
    overflow-y: auto; }
  .panel-popup.route-details .leaflet-container {
    flex: 1; }

.panel-popup .vignette-summary table th, .panel-popup .vignette-summary table td {
  padding: 1px 10px;
  font-size: 16px; }

.panel-popup .vignette-summary table td:not(:first-child) {
  width: 30%; }

.panel-popup .vignette-summary.details-scroll {
  overflow-x: auto;
  height: 400px; }

.panel-popup.delict-details {
  overflow-y: auto !important; }
  .panel-popup.delict-details .delict-summary table th, .panel-popup.delict-details .delict-summary table td {
    padding: 1px 10px;
    font-size: 16px; }
  .panel-popup.delict-details .delict-summary table td:not(:first-child) {
    width: 30%; }

.panel-popup.obligations-summary {
  overflow-y: auto !important; }
  .panel-popup.obligations-summary .payment-transactions-title {
    padding: 1px 10px;
    background: #777879;
    text-align: left;
    color: white;
    font-weight: bold;
    font-size: 16px; }
  .panel-popup.obligations-summary .payment-transactions {
    padding-top: 0; }

.payments-popup .popup-box {
  height: 750px; }

.obulist-popup {
  padding: 20px; }
  .obulist-popup .button-obulist {
    cursor: pointer; }
    .obulist-popup .button-obulist:disabled {
      cursor: initial;
      background: #CACACA; }

.obu-list-number input[type=number]::-webkit-inner-spin-button,
.obu-list-number input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.obu-list-number input[type=number] {
  -moz-appearance: textfield; }

.shifts-report-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%; }
  .shifts-report-wrapper .shifts-report {
    display: flex;
    flex-direction: column;
    cursor: pointer; }
    .shifts-report-wrapper .shifts-report .icon-shifts {
      font-size: 50px; }

.edit-partners {
  height: 550px !important; }

.bold {
  font-weight: bold; }

.full-width {
  width: 100%; }

.popup.popup-erp .popup-wrapper.is-visible .popup-box .panel-edit.edit-contracts {
  height: 470px; }

.list-checklist {
  font-size: 26px; }
  .list-checklist .list-item {
    padding: 35px 0 35px 90px;
    border-bottom: 1px solid #ddd;
    background: url(../images/checkmark.svg) no-repeat top 45px left 0; }
    .list-checklist .list-item:first-child {
      padding-top: 0;
      background-position: top 10px left; }
    .list-checklist .list-item:last-child {
      padding-bottom: 0;
      border-bottom: none; }
  @media (max-width: 640px) {
    .list-checklist {
      font-size: 14px;
      margin: 0 auto;
      max-width: 250px; }
      .list-checklist .list-item {
        background-position: top center;
        background-size: 100px;
        padding: 120px 20px 20px;
        margin-bottom: 20px;
        text-align: center; }
        .list-checklist .list-item:first-child {
          padding-top: 120px;
          background-position: top center; } }

.list-minimal {
  font-size: 16px; }
  .list-minimal .list-item {
    margin-bottom: .5em;
    display: flex;
    align-items: flex-start; }
  .list-minimal a:hover {
    opacity: .8; }
  .list-minimal .icon {
    width: 24px;
    margin-right: 20px; }

.list-large-connected {
  display: flex;
  justify-content: space-between; }
  .list-large-connected .list-item {
    text-align: center;
    width: 250px; }
  .list-large-connected .item-icon {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0 0 0 20px #494949, 0 0 0 22px #F5BA16;
    margin: 20px auto 55px;
    padding: 10px; }
    .list-large-connected .item-icon .icon {
      font-size: 80px;
      margin-top: 30px; }
  .list-large-connected .item-description {
    font-size: 26px; }
  @media (max-width: 640px) {
    .list-large-connected {
      display: block; }
      .list-large-connected .list-item {
        max-width: 190px;
        display: block;
        margin: 30px auto 60px; }
      .list-large-connected .item-icon {
        width: 70px;
        height: 70px;
        margin: 30px auto; }
        .list-large-connected .item-icon .icon {
          font-size: 60px;
          margin-top: 25px; }
      .list-large-connected .item-description {
        font-size: 16px;
        line-height: 1.25; } }

.list-purchases .list-item {
  padding: 20px 0 20px 20px;
  border-bottom: 1px solid #CACACA;
  justify-content: space-between;
  display: flex; }

.list-purchases .item-delete {
  margin-left: 10px; }
  .list-purchases .item-delete > div {
    cursor: pointer;
    height: 28px;
    margin-top: -10px;
    color: #777879; }
  .list-purchases .item-delete > div:hover {
    color: #FF6262; }

.list-purchases .item-warning {
  color: #F5BA16; }

.list-purchases .item-products {
  flex: 1; }

.list-purchases .item-product,
.list-purchases .item-description,
.list-purchases .item-validity,
.list-purchases .item-timespan {
  display: block; }

.list-purchases .item-product.inline {
  display: inline; }

.list-purchases .item-product {
  font-size: 26px; }

.list-purchases .item-description,
.list-purchases .item-validity,
.list-purchases .item-timespan {
  font-size: 18px; }

.list-purchases .item-cost {
  text-align: right; }

.list-purchases .item-price {
  font-size: 24px;
  font-weight: bold;
  color: #F5BA16; }

@media (max-width: 640px) {
  .list-purchases .item-product {
    font-size: 16px; }
  .list-purchases .list-item {
    padding-left: 0;
    padding-right: 0; }
  .list-purchases .item-description,
  .list-purchases .item-validity,
  .list-purchases .item-timespan {
    font-size: 16px; } }

.list-purchases.print-purchases .list-item {
  padding: 10px;
  border: none; }
  .list-purchases.print-purchases .list-item .item-container {
    border: 1px solid #CACACA;
    padding: 20px;
    width: 100%; }
    .list-purchases.print-purchases .list-item .item-container .item-product {
      text-align: center; }
    .list-purchases.print-purchases .list-item .item-container table {
      margin-bottom: 0;
      margin-top: 20px; }
      .list-purchases.print-purchases .list-item .item-container table tr {
        display: flex;
        border: none; }
      .list-purchases.print-purchases .list-item .item-container table td {
        padding: 1px;
        text-overflow: clip;
        overflow: hidden;
        white-space: nowrap; }
        .list-purchases.print-purchases .list-item .item-container table td:first-child {
          flex-basis: 40%;
          background: #FFFFFF;
          color: #494949; }
          @media (max-width: 1200px) {
            .list-purchases.print-purchases .list-item .item-container table td:first-child {
              flex-basis: 50%; } }
        .list-purchases.print-purchases .list-item .item-container table td:last-child {
          flex-basis: 50%; }

.list-types {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 60px 0; }
  .list-types .list-item {
    flex-basis: calc(100% / 3 - 80px);
    flex-grow: 0;
    flex-shrink: 0; }
  .list-types .item-image {
    margin-bottom: 40px;
    height: 40px; }

.payment-picker {
  margin-bottom: 20px; }
  .payment-picker .input-group input, .payment-picker .input-group input:hover, .payment-picker .input-group-radio, .payment-picker .input-group-radio:hover, .payment-picker .input-group .react-select__control {
    border: 2px solid #F5BA16;
    border-radius: 5px; }
    .payment-picker .input-group input:hover, .payment-picker .input-group input:hover:hover, .payment-picker .input-group-radio:hover, .payment-picker .input-group-radio:hover:hover, .payment-picker .input-group .react-select__control:hover {
      border-color: #f9d778; }
  .payment-picker .input-group .multi-select-input.searchable {
    height: 60px; }
  .payment-picker .input-group .react-select__control {
    line-height: 1.3;
    padding: .5em 10xp .5em 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: white;
    width: 100%;
    outline: none;
    font-size: 20px;
    height: 65px; }
  .payment-picker .input-group .react-select__input input {
    height: 42px; }
  .payment-picker .box-summary-note {
    margin: 10px 0 20px 0; }
  .payment-picker .payment-summary {
    max-width: 620px; }
    @media (min-width: 640px) {
      .payment-picker .payment-summary {
        margin-left: 40px; } }
    .payment-picker .payment-summary .box-summary-empty {
      padding: 80px 0;
      text-align: center;
      font-size: 22px;
      margin: 15px 0 10px 0;
      border: 2px solid #F5BA16;
      border-radius: 5px; }
      .payment-picker .payment-summary .box-summary-empty .icon {
        color: #CCC;
        font-size: 150px; }
    .payment-picker .payment-summary .button.button-add, .payment-picker .payment-summary .site-header-widgets input.button-add[type="submit"], .site-header-widgets .payment-picker .payment-summary input.button-add[type="submit"] {
      width: 100%;
      margin: 5px 0;
      text-align: center; }
    .payment-picker .payment-summary .box-summary-header .icon {
      max-height: 40px; }
    .payment-picker .payment-summary .cart-summary-title .custom-button-holder {
      display: inline-block;
      float: right; }
      .payment-picker .payment-summary .cart-summary-title .custom-button-holder .icon {
        float: left;
        color: #FFF; }
      .payment-picker .payment-summary .cart-summary-title .custom-button-holder img {
        width: 24px;
        margin-top: 0px; }
      .payment-picker .payment-summary .cart-summary-title .custom-button-holder .button, .payment-picker .payment-summary .cart-summary-title .custom-button-holder .site-header-widgets input[type="submit"], .site-header-widgets .payment-picker .payment-summary .cart-summary-title .custom-button-holder input[type="submit"] {
        height: 34px;
        width: 160px;
        font-size: 18px;
        padding: 5px; }
    .payment-picker .payment-summary .box-summary-container {
      border: 2px solid #F5BA16;
      margin: 14px 0;
      padding: 10px;
      border-radius: 5px; }
      .payment-picker .payment-summary .box-summary-container .box-summary-add {
        display: inline-block;
        cursor: pointer; }
        .payment-picker .payment-summary .box-summary-container .box-summary-add .icon {
          height: 28px;
          margin-right: 10px; }
        .payment-picker .payment-summary .box-summary-container .box-summary-add div {
          display: inline-block;
          vertical-align: bottom; }
        .payment-picker .payment-summary .box-summary-container .box-summary-add:hover .icon {
          color: #F5BA16; }
      .payment-picker .payment-summary .box-summary-container .box-summary-final {
        font-size: 20px;
        text-align: right; }

/*NEWS LIST PAGE*/
.site-content .news-container {
  margin: 50px 150px;
  text-align: justify; }

.site-content .news-layout {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between; }
  .site-content .news-layout .main-content {
    order: 1;
    flex: 3 2 auto; }
    .site-content .news-layout .main-content p {
      text-indent: 1.5em; }
    .site-content .news-layout .main-content header {
      margin-bottom: 20px; }
      .site-content .news-layout .main-content header img {
        width: 100%; }
  .site-content .news-layout .widget-content {
    order: 2;
    flex: 2 3 auto;
    margin-left: 50px;
    min-width: 200px; }
    .site-content .news-layout .widget-content .widget-item {
      margin-top: 50px; }
    .site-content .news-layout .widget-content.banners {
      min-width: 320px; }
    .site-content .news-layout .widget-content.banners article {
      display: flex;
      flex-direction: row;
      max-width: 330px;
      padding-top: 20px;
      margin-bottom: 10px;
      border-top: 1px solid #e7e7e7;
      margin-top: auto; }
      .site-content .news-layout .widget-content.banners article:first-child {
        border: none; }
      .site-content .news-layout .widget-content.banners article .thumbnail {
        min-width: auto;
        flex: 1 1 auto; }
        .site-content .news-layout .widget-content.banners article .thumbnail img {
          width: 95px;
          height: 95px;
          max-width: 95px;
          max-height: 95px;
          object-fit: cover; }
      .site-content .news-layout .widget-content.banners article .info {
        flex: 2 2 auto;
        padding: 8px; }

.site-content header.news-page {
  margin: 50px 150px 0px; }
  .site-content header.news-page h1 {
    padding: 20px 0px; }
  .site-content header.news-page h2 {
    padding: 20px 0px 30px 0px;
    border-bottom: 1px solid #e7e7e7;
    font-weight: 200;
    font-size: 1.2em; }
  .site-content header.news-page h5 {
    padding: 15px 0px;
    font-weight: 100;
    font-size: 0.7em; }

.news-item {
  transition: box-shadow .3s;
  clear: both;
  margin-bottom: 15px;
  margin-top: 25px;
  background-color: #f2f2f25c;
  border-radius: 5px;
  display: flex;
  border: 1px solid #e7e7e7; }
  .news-item:after {
    display: block;
    clear: both;
    content: ""; }
  .news-item:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2); }
  .news-item .thumbnail {
    position: relative;
    order: 1;
    flex: 1 2 auto;
    min-width: 320px;
    display: inline-flex; }
    .news-item .thumbnail img {
      display: block;
      max-width: 100%;
      height: auto;
      width: 100%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px; }
  .news-item .info {
    padding: 15px 20px 20px;
    position: relative;
    order: 2;
    flex: 3 1 auto; }
    .news-item .info .headline {
      font-size: 1.375rem;
      line-height: 1.2;
      margin-bottom: 10px; }
    .news-item .info .description {
      font-size: .95rem;
      line-height: 1.2;
      margin-top: 12px;
      padding-bottom: 30px; }
    .news-item .info .published {
      position: absolute;
      left: 27px;
      right: 20px;
      bottom: 16px;
      font-style: italic;
      font-size: .7875rem; }

@media (min-width: 1800px) {
  .news-item .thumbnail {
    width: 420px;
    flex: 1 0 auto; } }

@media (max-width: 1200px) {
  .site-content .news-container {
    margin: 50px 50px; }
  .site-content .news-layout .widget-content {
    display: none; }
  .site-content header.news-page {
    margin: 50px 50px 0px; } }

@media (max-width: 960px) {
  .site-content .news-container {
    margin: 30px 20px; }
  .site-content header.news-page {
    margin: 30px 20px 0px; }
  .news-item .thumbnail {
    min-width: 220px; } }

@media (max-width: 640px) {
  .site-content .news-container {
    margin: 30px 5px; }
  .site-content header.news-page {
    margin: 30px 5px 0px; }
  .news-item {
    border-radius: 0px;
    flex-direction: column;
    border: none; }
    .news-item .thumbnail img {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px; } }

.react-table-filterable {
  position: relative; }
  .react-table-filterable .filters-btn {
    font-size: 16px;
    margin: 0; }
    .react-table-filterable .filters-btn .icon {
      font-size: 16px;
      width: 18px;
      height: 18px;
      display: inline-block;
      vertical-align: middle; }
  .react-table-filterable table.header-container {
    margin: 0;
    table-layout: fixed; }
    .react-table-filterable table.header-container tr {
      border: 0; }
      .react-table-filterable table.header-container tr td {
        padding: 0; }
  .react-table-filterable .custom-buttons {
    float: right;
    margin-right: 10px; }
    .react-table-filterable .custom-buttons div.custom-checkbox {
      display: inline-block; }
    .react-table-filterable .custom-buttons .custom-button-holder {
      display: inline-block; }
      .react-table-filterable .custom-buttons .custom-button-holder button {
        height: 36px;
        margin-right: 20px;
        padding-top: 6px;
        font-size: 18px;
        vertical-align: super; }
        .react-table-filterable .custom-buttons .custom-button-holder button img {
          width: 28px; }
  .react-table-filterable .filters-btn {
    cursor: pointer;
    margin: 4px 10px; }
    .react-table-filterable .filters-btn .icon {
      margin-right: 10px; }
  .react-table-filterable .table-title {
    text-align: center;
    font-weight: bold;
    font-size: 22px; }
    .react-table-filterable .table-title div {
      margin-top: -4px; }
  .react-table-filterable .add-btn {
    width: 30px; }
    .react-table-filterable .add-btn .icon {
      cursor: pointer;
      font-size: 27px;
      height: 30px; }
  .react-table-filterable .date-range-dropdown {
    background-color: #FFF;
    width: 100%;
    z-index: 110;
    overflow-y: auto;
    max-height: 200px;
    border-radius: 3px;
    box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.7); }
  .react-table-filterable .ReactTable .rt-thead.-filters input, .react-table-filterable .ReactTable .rt-thead.-filters select {
    height: 33px;
    width: 100%;
    font-size: 16px; }

div.react-datepicker-wrapper .react-datepicker__close-icon::after {
  background-color: #494949; }

div.react-datepicker-wrapper .react-datepicker__close-icon:hover::after {
  background-color: #F5BA16; }

.ReactTable {
  width: 100%; }
  .ReactTable .rt-noData {
    z-index: 0 !important; }
  .ReactTable .align-right {
    text-align: right !important; }
  .ReactTable .align-center {
    text-align: center !important; }
  .ReactTable .custom-dropdown-btn {
    height: 33px;
    width: 100%;
    background-color: #FFFFFF;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 100;
    text-overflow: clip;
    overflow: hidden; }
  .ReactTable .custom-dropdown-btn.date-range-empty {
    color: #CACACA; }
  .ReactTable .rt-thead.-filters {
    height: 44px; }
    .ReactTable .rt-thead.-filters div.react-datepicker-wrapper {
      display: block; }
      .ReactTable .rt-thead.-filters div.react-datepicker-wrapper input {
        font-size: 15px; }
  .ReactTable .rt-tfoot {
    color: #FFFFFF;
    background-color: #555657; }
    .ReactTable .rt-tfoot .rt-tr {
      height: 26px; }
      .ReactTable .rt-tfoot .rt-tr .rt-td {
        padding: 2px;
        font-size: 16px; }
    .ReactTable .rt-tfoot .align-right {
      width: 100%;
      text-align: right;
      padding-right: 6px; }
  .ReactTable .rt-thead {
    background: #666768;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 24px; }
    .ReactTable .rt-thead .rt-th {
      height: 50px; }
      .ReactTable .rt-thead .rt-th .rt-resizer {
        z-index: 1; }
      .ReactTable .rt-thead .rt-th .react-datepicker-popper .react-datepicker {
        display: block; }
        .ReactTable .rt-thead .rt-th .react-datepicker-popper .react-datepicker .react-datepicker__month-container, .ReactTable .rt-thead .rt-th .react-datepicker-popper .react-datepicker .react-datepicker__time-container {
          display: inline-block;
          vertical-align: top;
          float: none; }
    .ReactTable .rt-thead.-header .rt-tr .rt-th {
      padding-top: 20px;
      height: 60px;
      font-size: 18px;
      text-align: left; }
    .ReactTable .rt-thead.-header .rt-tr .rt-th.-sort-desc {
      box-shadow: inset 0 -4px 0 0 #F5BA16; }
    .ReactTable .rt-thead.-header .rt-tr .rt-th.-sort-asc {
      box-shadow: inset 0 4px 0 0 #F5BA16; }
    .ReactTable .rt-thead .rt-th.calendar-column {
      overflow: visible;
      z-index: 1; }
  .ReactTable .rt-tbody .rt-td {
    font-size: 16px;
    text-align: left;
    height: 38px; }
    .ReactTable .rt-tbody .rt-td input, .ReactTable .rt-tbody .rt-td .custom-checkbox {
      height: 24px; }
    .ReactTable .rt-tbody .rt-td .custom-checkbox label {
      height: 24px;
      width: 24px; }
  .ReactTable .rt-tbody .rt-td.action-column {
    margin: 0;
    padding: 0;
    text-align: right;
    text-overflow: clip; }
    .ReactTable .rt-tbody .rt-td.action-column .icon:hover {
      opacity: 0.8; }
  .ReactTable .rt-tbody .rt-td.custom-control {
    padding: 0 !important;
    margin-top: -2px;
    overflow: visible; }
    .ReactTable .rt-tbody .rt-td.custom-control .multi-value-text {
      overflow: hidden;
      text-overflow: ellipsis;
      height: 26px;
      margin-top: 5px; }
  .ReactTable .rt-tbody .rt-tr {
    align-items: center; }
  .ReactTable .rt-tbody .rt-tr:hover {
    opacity: 0.8; }
  .ReactTable .pagination-bottom {
    background-color: #666768;
    height: 26px;
    font-size: 16px;
    color: #F2F2F2; }
    .ReactTable .pagination-bottom .total-rows {
      margin-left: 8px;
      position: absolute; }
    .ReactTable .pagination-bottom .custom-message {
      position: absolute;
      display: inline-block;
      right: 8px; }
    .ReactTable .pagination-bottom .table-navigation {
      display: inline-block;
      white-space: nowrap; }
      .ReactTable .pagination-bottom .table-navigation button[disabled], .ReactTable .pagination-bottom .table-navigation button[disabled]:hover {
        opacity: 0.4;
        color: #F2F2F2; }
      .ReactTable .pagination-bottom .table-navigation .visible-pages-wrapper {
        display: inline-block;
        color: #F2F2F2;
        margin-top: -2px;
        vertical-align: top; }
      .ReactTable .pagination-bottom .table-navigation button {
        height: 22px;
        background: transparent;
        padding: 0 8px;
        overflow: hidden;
        border: none;
        margin: 4px 0;
        outline: none;
        color: #F2F2F2; }
        .ReactTable .pagination-bottom .table-navigation button .icon {
          margin-top: -6px;
          margin-left: -3px;
          font-size: 24px;
          color: inherit; }
      .ReactTable .pagination-bottom .table-navigation button:hover {
        color: #FFD677; }
      .ReactTable .pagination-bottom .table-navigation .page-button.page-button-active {
        color: #F5BA16;
        font-weight: bold; }
      .ReactTable .pagination-bottom .table-navigation .sl-page-size {
        border: none;
        background-color: #F2F2F2;
        vertical-align: top;
        margin-top: 4px; }
  .ReactTable .grid-icon {
    width: 22px;
    height: 22px;
    padding-top: 6px;
    display: inline-block;
    margin-right: 5px; }
  .ReactTable .status-orb {
    width: 16px;
    height: 16px;
    border-radius: 10px;
    margin-top: 3px;
    background-color: #FFFFFF; }
    .ReactTable .status-orb-active {
      background-color: #53D409; }
    .ReactTable .status-orb-inactive {
      background-color: #FF6262; }
    .ReactTable .status-orb-filter {
      font-size: 14px;
      margin-top: 8px;
      cursor: pointer; }
    .ReactTable .status-orb-canceled:after {
      height: 16px;
      width: 16px;
      border-radius: 10px;
      border: 1px solid #FF6262;
      text-align: center;
      position: relative;
      display: block;
      content: "\00d7";
      line-height: 14px;
      font-size: 14px;
      padding: 0.5px 2px 0px 2px;
      color: #FF6262; }
  .ReactTable .rt-th.calendar-column .input-date input {
    height: 40px;
    width: 100%;
    padding-right: 30px;
    font-size: 18px; }
  .ReactTable .rt-th.calendar-column .input-date > div {
    background-color: #666768;
    z-index: 1000;
    width: 300px;
    position: relative; }
  .ReactTable .rt-th.calendar-column .input-date button {
    margin-left: -28px;
    height: 24px;
    width: 24px;
    background: #F2F2F2;
    border: 1px;
    border: 0; }
    .ReactTable .rt-th.calendar-column .input-date button .icon {
      font-size: 16px;
      margin-left: -2px;
      margin-top: 2px;
      color: #777879; }
  .ReactTable .rt-th.calendar-column .input-date button:hover {
    background: #FF6262; }
    .ReactTable .rt-th.calendar-column .input-date button:hover .icon {
      color: #FFFFFF; }
  .ReactTable .space-between {
    display: flex;
    justify-content: space-between; }

div.delictPayment {
  margin: 20px 100px; }
  div.delictPayment .ReactTable {
    height: 400px; }

div.delictPayment.paymentPage {
  margin: 20px; }

@media (max-width: 960px) {
  div.delictPayment {
    margin: 20px 15px; } }

body,
html {
  height: 100%;
  transition: .5s; }

.row {
  max-width: 1440px; }
  .row.wide {
    max-width: 1640px; }
  .row.narrow {
    max-width: 1240px; }
  .row .column-content-center {
    display: flex;
    align-items: center;
    justify-content: center; }
  @media (max-width: 640px) {
    .row {
      padding: 0 10px; } }

.BrainhubCarousel {
  position: relative; }
  .BrainhubCarousel .BrainhubCarousel__trackContainer {
    overflow: hidden; }
    .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
      display: flex;
      height: 300px; }
      @media screen and (min-width: 640px) {
        .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
          height: 400px; } }
      @media screen and (min-width: 960px) {
        .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
          height: 600px; } }
      @media screen and (min-width: 1200px) {
        .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
          height: 800px; } }
      @media screen and (min-width: 1800px) {
        .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
          height: 1000px; } }
      .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track .BrainhubCarouselItem {
        display: flex; }
        .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track .BrainhubCarouselItem img {
          width: 100%; }

.BrainhubCarousel.buttons-overlay:before {
  position: absolute;
  content: '';
  display: block;
  background: rgba(20, 20, 20, 0.7);
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.carousel-overlay {
  position: absolute;
  z-index: 8;
  width: 100%; }
  .carousel-overlay .heading-cta .vehicle-section {
    padding-bottom: 40px; }
    .carousel-overlay .heading-cta .vehicle-section .vehicle-section-header-wrapper {
      margin: 30px 10% 50px;
      border-bottom: 1px solid #FFFFFF; }
      @media (max-width: 960px) {
        .carousel-overlay .heading-cta .vehicle-section .vehicle-section-header-wrapper {
          margin-top: 10px;
          margin-bottom: 10px; } }
    .carousel-overlay .heading-cta .vehicle-section .vehicle-section-header {
      display: inline-block;
      width: 290px;
      margin: 5px 5px 0 5px;
      padding-top: 15px;
      padding-bottom: 10px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: #F2F2F2;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
      opacity: 0.6;
      vertical-align: bottom; }
      .carousel-overlay .heading-cta .vehicle-section .vehicle-section-header-selected {
        background: #FFFFFF;
        color: #F5BA16;
        opacity: 1; }
      .carousel-overlay .heading-cta .vehicle-section .vehicle-section-header:hover {
        background: #FFD677;
        color: #FFFFFF;
        opacity: 1; }
      @media (max-width: 640px) {
        .carousel-overlay .heading-cta .vehicle-section .vehicle-section-header {
          width: 178px;
          padding: 5px;
          font-size: 16px;
          height: 60px; } }
      @media (max-width: 480px) {
        .carousel-overlay .heading-cta .vehicle-section .vehicle-section-header {
          width: 100px;
          font-size: 9px;
          padding: 6px;
          height: auto; } }
  @media (max-width: 960px) {
    .carousel-overlay .section-heading {
      margin-top: 90px; }
    .carousel-overlay .heading-cta {
      margin-top: 30px; }
      .carousel-overlay .heading-cta button, .carousel-overlay .heading-cta a {
        width: 250px;
        margin-top: 10px;
        font-size: 16px;
        max-height: 50px;
        padding-top: 5px; } }
  @media (min-width: 640px) {
    .carousel-overlay {
      top: 100px; }
      .carousel-overlay .heading-title {
        font-size: 45px; } }
  @media (min-width: 960px) {
    .carousel-overlay {
      top: 200px; }
      .carousel-overlay .heading-cta button, .carousel-overlay .heading-cta a {
        width: 400px; } }
  @media (min-width: 1800px) {
    .carousel-overlay {
      top: 300px; }
      .carousel-overlay .heading-title {
        font-size: 65px; }
      .carousel-overlay .heading-subtitle {
        font-size: 30px;
        margin-top: 20px; }
      .carousel-overlay .heading-cta button, .carousel-overlay .heading-cta a {
        width: 600px;
        margin: 0 40px;
        font-size: 30px; } }
  .carousel-overlay h1, .carousel-overlay .button, .carousel-overlay .site-header-widgets input[type="submit"], .site-header-widgets .carousel-overlay input[type="submit"] {
    color: #FFFFFF;
    margin: 0 20px; }

p.margin-bottom-small {
  margin-bottom: 0.5em; }

.phone-fields {
  display: flex;
  max-width: 100%; }
  .phone-fields div:first-child {
    max-width: 180px; }
    .phone-fields div:first-child input {
      padding: 0;
      text-align: center; }

.contract-popup .popup-box {
  width: 95%;
  height: 80%; }

div.popup.delict-popup div.popup-wrapper div.popup-box {
  padding: 30px 60px; }

.rt-tr.rt-row-selected {
  background-color: #FFD677 !important; }

.site-header-main .dropdown-container .menu-dropdown, .menu-language-picker .language-dropdown {
  position: absolute;
  top: 100%;
  width: 280px;
  background: #FFFFFF;
  right: 0;
  opacity: 0;
  transition: .3s;
  transform: scaleY(0);
  pointer-events: none;
  transform-origin: top center;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  min-width: 310px;
  padding: 15px 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px); }

.site-header-main .dropdown-container:hover .menu-dropdown, .menu-language-picker.menu-language-picker-hover .language-dropdown {
  opacity: 1;
  transition: .3s;
  transform: scaleY(1);
  transform-origin: top center;
  pointer-events: all; }

.site-header-main .dropdown-container .menu-dropdown .menu-item, .menu-language-picker .language-dropdown li {
  display: block;
  cursor: pointer;
  padding: .8em 1.5em;
  color: #494949; }
  .site-header-main .dropdown-container .menu-dropdown .menu-item:hover, .menu-language-picker .language-dropdown li:hover {
    background: rgba(119, 120, 121, 0.1); }

.site-header {
  position: relative;
  z-index: 10; }
  .site-header-simple .site-header-main {
    background: none; }
  .site-header-simple .site-header-inner {
    justify-content: space-between; }
  .site-header-simple .site-login .menu-item a {
    color: #494949; }
  .site-header-simple .site-logo {
    background-image: url(../images/logo-color.svg); }
  .site-header-mobilenav {
    display: none; }
  .site-header.headroom {
    width: 100%;
    transition: .5s;
    position: fixed;
    top: 0; }
    .site-header.headroom .popup-cart {
      position: absolute;
      top: 89px;
      left: 0;
      z-index: 10;
      width: 400px;
      padding: 20px;
      background: white;
      border: 1px solid #CACACA;
      border-top: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.7); }
      .site-header.headroom .popup-cart .cart-buttons {
        display: flex;
        justify-content: space-between; }
      @media (max-width: 1500px) {
        .site-header.headroom .popup-cart {
          left: calc(50% - 250px); } }
      @media (max-width: 480px) {
        .site-header.headroom .popup-cart {
          left: calc(50% - 200px); } }
      @media (max-width: 1280px) {
        .site-header.headroom .popup-cart {
          top: 59px; } }
    .site-header.headroom + .site-content {
      padding-top: 60px; }
      @media (min-width: 1280px) {
        .site-header.headroom + .site-content {
          padding-top: 120px; } }
    @media (min-width: 1280px) {
      .site-header.headroom.headroom--not-top + .site-content {
        padding-top: 80px; } }
    .site-header.headroom + div.site-content.home {
      padding-top: 0; }
    @media (min-width: 1280px) {
      .site-header.headroom.headroom--not-top.headroom {
        transform: translateY(-40px);
        transition: .5s; }
        .site-header.headroom.headroom--not-top.headroom .popup-cart {
          transform: translateY(-20px);
          transition: .5s; }
      .site-header.headroom.headroom--not-top .site-header-inner {
        transform: translateY(20px);
        transition: .5s; } }

@media (min-width: 1280px) {
  .headroom--not-top.headroom {
    transform: translateY(-40px);
    transition: .5s; }
  .headroom--not-top .site-header-inner {
    transform: translateY(20px);
    transition: .5s; } }

.site-branding {
  display: inline-block; }

.site-header-widgets {
  background: black;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 0;
  flex-grow: 1;
  position: relative;
  z-index: 90; }
  .site-header-widgets.is-hidden {
    display: none; }
  .site-header-widget-inner {
    display: flex;
    align-items: center; }
  .site-header-widget {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px 0;
    flex-grow: 1;
    display: flex;
    justify-content: center; }
    .site-header-widget:first-child {
      padding-left: 0; }
    .site-header-widget:last-child {
      padding-right: 0;
      border-right: none; }
  .site-header-widgets .widget-label {
    margin: 0 20px;
    white-space: nowrap; }
    .site-header-widgets .widget-label:last-child {
      margin-right: 0; }
    .site-header-widgets .widget-label:first-child {
      margin-left: 0; }
  .site-header-widgets .widget-date .widget-date-short {
    display: none; }
  @media (min-width: 1700px) {
    .site-header-widgets .widget-date .widget-date-short {
      display: inline-block; } }
  @media (max-width: 1700px) and (min-width: 1400px) {
    .site-header-widgets .widget-date {
      max-width: 180px; }
      .site-header-widgets .widget-date .widget-date-label {
        display: none; }
      .site-header-widgets .widget-date .widget-date-short {
        display: inline-block; } }
  @media (max-width: 1100px) {
    .site-header-widgets .widget-date {
      flex-grow: 0;
      padding-right: 20px; }
      .site-header-widgets .widget-date .widget-date-label {
        display: none; }
      .site-header-widgets .widget-date .widget-date-short {
        display: inline-block; } }
  .site-header-widgets .weather-forecast {
    display: flex;
    align-content: center;
    min-width: 130px; }
    .site-header-widgets .weather-forecast .forecast-number {
      font-size: 26px;
      margin-left: 20px;
      margin-right: 10px;
      line-height: 1.3; }
    .site-header-widgets .weather-forecast .forecast-icon {
      max-height: 44px; }
    @media (max-width: 1400px) {
      .site-header-widgets .weather-forecast {
        display: none; } }
  @media (max-width: 1400px) {
    .site-header-widgets .widget-forecast {
      display: none; } }
  .site-header-widgets .widget-controller {
    width: 120px;
    flex-grow: 0;
    line-height: 18px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px; }
    .site-header-widgets .widget-controller img {
      height: 7px;
      width: 12px; }
    .site-header-widgets .widget-controller-label {
      text-align: center;
      white-space: normal; }
  .site-header-widgets .form-inline {
    display: flex; }
  .site-header-widgets input[type="text"],
  .site-header-widgets select {
    background: #494949;
    border-radius: 5px;
    max-width: 100px;
    width: 100%;
    color: white;
    border: none;
    padding: 5px;
    margin: 0 5px; }
    .site-header-widgets input[type="text"]::-webkit-input-placeholder,
    .site-header-widgets select::-webkit-input-placeholder {
      color: #777; }
    .site-header-widgets input[type="text"]::-moz-placeholder,
    .site-header-widgets select::-moz-placeholder {
      color: #777; }
    .site-header-widgets input[type="text"]:-ms-input-placeholder,
    .site-header-widgets select:-ms-input-placeholder {
      color: #777; }
    .site-header-widgets input[type="text"]:-moz-placeholder,
    .site-header-widgets select:-moz-placeholder {
      color: #777; }
  .site-header-widgets select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(../images/arrow_down_white.svg);
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px; }
    .site-header-widgets select.form-select-wider {
      width: 145px; }
  .site-header-widgets input[type="submit"] {
    min-width: 50px;
    padding: 0;
    background-image: url(../images/arrow_right_white.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10px; }
  @media (max-width: 850px) {
    .site-header-widgets {
      display: none; } }

.loader-image-on-button {
  width: 18px;
  height: 18px;
  margin-left: 8px;
  position: absolute; }

.loader-image-on-refresh-button {
  width: 18px;
  height: 18px; }

.loader-image-inside-input {
  width: 18px;
  height: 18px;
  margin-top: 18px; }

.input-container {
  position: relative; }

.icon-container {
  position: absolute;
  right: 10px;
  top: calc(50% - 10px); }

.payment-button-yes {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 30%;
  float: left; }

.payment-button-no {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 30%;
  float: right; }

.site-header-main {
  background: rgba(50, 50, 50, 0.7);
  padding: 30px 0;
  transition: .5s;
  z-index: 80; }
  .site-header-main .site-header-main.is-small {
    transform: translateY(-40px); }
    .site-header-main .site-header-main.is-small .site-header-inner {
      transform: translateY(20px); }
  .site-header-main .site-header-inner {
    display: flex;
    align-items: center;
    min-height: 60px;
    transition: .5s; }
  .site-header-main .menu-icon {
    display: block;
    width: 42px;
    height: 42px;
    padding: 10px; }
    .site-header-main .menu-icon-nav {
      margin-left: 8px; }
  .site-header-main .menu-icon.menu-icon-lang {
    padding: 0;
    height: auto;
    margin-top: 10px;
    margin-left: 10px; }
  .site-navigation {
    flex-grow: 1;
    white-space: nowrap; }
    .site-navigation .menu-icon {
      display: none; }
      .site-navigation .menu-icon-nav {
        cursor: pointer; }
    .site-navigation .menu-item {
      display: inline-block; }
      .site-navigation .menu-item a {
        color: white;
        padding: .8em 1em;
        display: inline-block; }
    @media (max-width: 1280px) {
      .site-navigation {
        flex-grow: 0; }
        .site-navigation .menu-main {
          display: none; }
        .site-navigation .menu-icon {
          display: inline-block; } }
  .site-login .menu-item {
    display: inline-block; }
    .site-login .menu-item a, .site-login .menu-item div {
      color: white;
      padding: .8em 1.5em;
      display: inline-block; }
  .site-login .menu-icon-profile {
    display: none; }
  .site-login .menu-icon {
    display: none; }
  @media (max-width: 1500px) {
    .site-login .menu-links {
      display: none; }
    .site-login .menu-icon {
      display: inline-block; } }
  .site-logo {
    text-indent: -9999px;
    background: url(../images/logo_digitoll_color.svg) no-repeat center left;
    background-size: contain;
    max-height: 60px;
    margin-right: 10px; }
    .site-logo a {
      display: block;
      height: 62px;
      width: 220px; }
    @media (max-width: 1280px) {
      .site-logo {
        flex-grow: 1; }
        .site-logo a {
          height: 30px;
          width: 105px; } }
  .menu-shopping-cart {
    margin-right: 20px;
    position: relative; }
    .menu-shopping-cart.menu-shopping-cart-hover {
      cursor: pointer; }
    .menu-shopping-cart .icon-cart {
      padding-left: 25px;
      margin-top: 12px;
      font-size: 30px; }
    @media (max-width: 1800px) {
      .menu-shopping-cart {
        margin-right: 20px; } }
    @media (max-width: 1500px) {
      .menu-shopping-cart .shopping-cart-icon {
        height: 22px;
        width: 22px; }
      .menu-shopping-cart .shopping-cart-items {
        font-size: 13px; } }
  .site-header-main .dropdown-container {
    position: relative;
    transform: translateY(1px); }
    .site-header-main .dropdown-container span {
      padding: 1em;
      color: white;
      cursor: default; }
    .site-header-main .dropdown-container ul a {
      color: #494949; }
  .menu-language-picker {
    margin-right: 20px;
    position: relative;
    transform: translateY(1px);
    padding: 19px; }
    .menu-language-picker .language-current {
      cursor: pointer; }
    .menu-language-picker .language-current,
    .menu-language-picker .language-select {
      text-indent: -9999px;
      display: block;
      height: 22px;
      width: 45px;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat; }
    .menu-language-picker .language-select {
      margin-right: 10px; }
    .menu-language-picker .language-dropdown div {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer; }
    @media (max-width: 1800px) {
      .menu-language-picker {
        margin-right: 0; } }
    @media (max-width: 1500px) {
      .menu-language-picker {
        order: 2; } }
    @media (max-width: 1280px) {
      .menu-language-picker {
        display: none; } }
  @media (max-width: 1280px) {
    .site-header-main {
      padding: 0; }
      .site-header-main .site-logo {
        order: 0; }
      .site-header-main .site-navigation {
        order: 3; }
      .site-header-main .menu-shopping-cart {
        order: 1; }
      .site-header-main .site-login {
        order: 2; } }

.shopping-cart-items {
  width: 1.3em;
  height: 1.3em;
  line-height: 1.5em;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: #F5BA16;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 10px;
  right: -5px; }

.shopping-cart-section {
  top: -5px;
  left: 100%; }

.site-header-mobilenav {
  position: absolute;
  top: 60px;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  max-height: 85vh;
  overflow-y: scroll; }
  .site-header-mobilenav .menu-mobile .menu-item-submenu {
    text-align: right; }
  .site-header-mobilenav .menu-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    text-align: center;
    height: 60px; }
    .site-header-mobilenav .menu-item:last-child {
      border-bottom: none; }
    .site-header-mobilenav .menu-item a, .site-header-mobilenav .menu-item div {
      padding: 1em 1.5em;
      display: block;
      color: white; }
    .site-header-mobilenav .menu-item img.language-select {
      margin-top: 5px;
      float: left; }
    .site-header-mobilenav .menu-item div.language-text {
      color: #FFFFFF;
      padding-top: 18px; }
  @media (max-width: 1280px) {
    .site-header-mobilenav.is-visible {
      display: block; } }

@media (max-width: 1280px) {
  nav.site-navigation-dropdown .menu-main {
    display: none; } }

nav.site-navigation-dropdown ul ul {
  min-width: 200px;
  display: block;
  opacity: 0;
  visibility: hidden;
  background: #777879;
  padding: 0;
  position: absolute;
  top: 100%;
  -webkit-transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
  -ms-transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out; }

nav.site-navigation-dropdown ul li:hover {
  background: #555657; }
  nav.site-navigation-dropdown ul li:hover > ul {
    opacity: 1;
    visibility: visible; }

nav.site-navigation-dropdown ul {
  list-style: none;
  position: relative;
  display: inline-table; }

nav.site-navigation-dropdown ul:after {
  content: "";
  clear: both;
  display: block; }

nav.site-navigation-dropdown ul li {
  float: left; }

nav.site-navigation-dropdown ul li ul {
  box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.7); }

nav.site-navigation-dropdown ul li a, nav.site-navigation-dropdown ul li span {
  cursor: pointer;
  display: block;
  padding: 12px 20px;
  color: #FFFFFF;
  text-decoration: none;
  outline: 0; }

nav.site-navigation-dropdown ul ul li {
  float: none;
  position: relative; }

nav.site-navigation-dropdown ul ul li a, nav.site-navigation-dropdown ul ul li span {
  padding: 15px 40px;
  color: #FFFFFF; }

nav.site-navigation-dropdown ul ul li a:hover, nav.site-navigation-dropdown ul ul li span:hover {
  background: #555657; }

nav.site-navigation-dropdown ul ul ul {
  position: absolute;
  left: 100%;
  top: 0; }

.site-footer {
  background: #494949;
  padding-bottom: 40px; }
  .site-footer a {
    color: white; }
  .site-footer .widget-title {
    margin-bottom: 1.5em; }
  .site-footer .widget-second-title {
    margin-bottom: 8px; }
  .site-footer .app-buttons a {
    padding: 0 5px; }
    .site-footer .app-buttons a img {
      width: 120px; }
  .site-footer .linked-in-icon {
    width: 24px; }
  @media (max-width: 640px) {
    .site-footer {
      text-align: center;
      font-size: 16px; }
      .site-footer .widget {
        margin-bottom: 80px; }
        .site-footer .widget .list-item {
          justify-content: center; }
        .site-footer .widget .list-minimal-icons {
          max-width: 200px;
          margin: 0 auto; }
          .site-footer .widget .list-minimal-icons .list-item {
            justify-content: flex-start;
            text-align: left; } }

.site-copyright {
  margin-top: 120px;
  font-size: 16px; }
  .site-copyright span {
    display: inline-block;
    padding: 0 20px;
    border-right: 1px solid #CACACA;
    color: #CACACA;
    line-height: 1.1; }
    .site-copyright span:last-child {
      border-right: 0; }
  @media (max-width: 640px) {
    .site-copyright {
      margin-top: 40px;
      font-size: 12px;
      color: #777879; } }
