@media (orientation: portrait), (max-width:801px) {
  .map-content.onTablet,
  .panel-popup.route-details {
    display: flex;
    flex: none;
    padding: 20px;
    flex-direction: column;
    width: 100%;

    div.right-panel {
      height: 300px;
    }

    div.left-panel.onTablet {
      width: 95%;
      margin: 0 auto;
      margin-top: 15px;
      order: 1;
      padding-right: 0;
      background: #fff;
      overflow: initial;
    }
  }

  #print-container {
    width: 100%;
  }

  div.app-erp .panel-edit,
  .popup.popup-erp .panel-edit {
    overflow-y: none;
  }

  .map-content.onTablet div.left-panel,
  .panel-popup.route-details div.left-panel {
    width: 100%;
  }

  .map-content.onTablet,
  .panel-popup.route-details {
    display: flex;
    padding: 20px;
    flex-direction: column;

    div.right-panel {
      height: 300px;
    }
  }

  div.app-erp .panel-edit,
  .popup.popup-erp .panel-edit {
    overflow-y: none;
  }

  .map-content.onTablet {
    .chip > img {
      width: 70px !important;
      display: block !important;
      margin: 0 auto !important;
      height: auto !important;
    }
  }

  .icon {
    font-size: 24px !important;
    z-index: 100;
  }

  .map-content.onTablet .leaflet-container,
  .panel-popup.route-details .leaflet-container {
    flex: auto;
  }

  .map-container {
    order:0;
    height:300px;
  }

  .leaflet-container {
    flex: auto;
    order: 0;
    height: 300px !important;
  }

  table {
    margin: 0 0 1em;
    width: 100%;
  }

  .map-content.onTablet {
    .react-datepicker {
      font-size: 1rem !important;
    }

    .react-datepicker__day {
      width: 4.7em !important;
      line-height: 3rem !important;
    }

    .react-datepicker__day-name {
      width: 2.7rem !important;
    }

    .react-datepicker__time-list {
      height: 325px !important;
    }

    .react-datepicker__header--time {
      padding-bottom: 32px !important;
    }

    .react-datepicker__time-list-item {
      height: 30px !important;
      padding: 25px 10px !important;
    }
  }

  .map-content.onTablet .field-container.panel-route-pass div.country-dropdown .input-group .map-dropdown {
    height: 50px;
  }

  .input-group-checkbox label {
    font-size: 15px;
  }

  .input-group-checkbox {
    margin-top: 20px;
  }

  .text-center {
    margin-top: 10px;
  }

  .logo {
    padding: 10px !important;
  }

  .list-purchases.print-purchases {
    .list-item {
      .item-container {
        table {
          td {
            white-space: normal;
          }
        }
      }
    }
  }

  ul.dnd-sortable-list.onTablet {
    width: 100%;
  }

  .scroll-btn-container {
    display: none;
  }
}

@media (orientation: landscape) and (min-width:801px) {
  .main-map {
    display: block;
    width: 40%;
  }
  
  .map-content,
  .panel-popup.route-details {
    div.left-panel.onTablet {
      width: 60%;
      padding-right: 20px;
      background: #fff;
    }
  }

  .map-content.onTablet{
    .chip > img {
      width: 70px !important;
      display: block !important;
      margin: 0 auto !important;
      height: auto !important;
    }
  }
  

  .map-content.onTablet,
  .panel-popup.route-details {
    display: flex;
    padding: 5px 20px;
  }

  table {
    font-size: 15px;
  }

  .section-fullwidth.onTablet {
    padding: 0;
  }

  .section-heading.onTablet {
    margin-bottom: 0;

    .heading-title {
      font-size: 35px;
    }
  }

  .box-summary-final {
		font-size: 20px;
		font-weight: bold;
		text-align: right;
    margin-bottom: .5em;
  }

  .box-form-border.onTablet {
    height: auto;
    border: none;
    padding: 7px 20px;
  }

  .map-content.onTablet {
    .react-datepicker {
      font-size: 1rem !important;
    }
  
    .react-datepicker__day {
      width: 4.7em !important;
      line-height: 3rem !important;
    }
  
    .react-datepicker__day-name {
      width: 2.7rem !important;
    }
  
    .react-datepicker__time-list {
      height: 250px !important;
    }
  
    .react-datepicker__header--time {
      padding-bottom: 32px !important;
    }
  
    .react-datepicker__time-list-item {
      height: 30px !important;
      padding: 25px 10px !important;
    }
  }

  .map-content.onTablet .field-container.panel-route-pass div.country-dropdown .input-group .map-dropdown {
    height: 50px;
  }

  .input-group-checkbox label {
    font-size: 15px;
  }

  .input-group-checkbox {
    margin-top: 5px;
  }

  .map-content.onTablet .input-group label {
    text-align: center;
    cursor: pointer;
    vertical-align: super;
    margin-left: 10px;
    display: inline-block;
  }

  .scroll-btn-container {
    position: fixed;
    left: 28.5%;
    bottom: 10px;

    .scroll-to-bottom-btn:focus {
      outline: none;
    }

    .scroll-to-bottom-btn {
      border-radius: 50%;
      opacity: 0.8;
      background-color: #F5BA16;
      border: none;
      position: relative;
      width: 40px;
      height: 40px;

      .icon {
        position: absolute;
        transform: rotate(268deg);
        top: 5px;
        left: 11px;
      }
    }
  }

  .map-content .field-container.panel-route-pass {
    .custom-checkbox.onTablet {
      position: absolute;
      top: 40px;
      right: -259px;
    }
  }

  ul.dnd-sortable-list.onTablet {
    width: 723px;
  }
}

.menu-language-picker.onTablet {
  display: block;
}

div.form-error-msg.onTablet {
  text-align: center;
  border: 1px solid red;
  padding: 5px;
  width: 70%;
  margin: 0 auto;
  border-radius: 3px;
  color: red;
}


input[type=checkbox].currentTime-checkbox {
  display:none;
}

input[type="checkbox"].currentTime-checkbox + label {
  background-image: url(../../images/checkmark.svg);
  background-size: contain;
  cursor: pointer;
  height: 24px;
  width: 24px;
  display:inline-block;
  border: none;
  flex: none;
  margin: 5px 5px 0 0;
}

input[type="checkbox"]:checked.currentTime-checkbox + label {
  background: unset;
  background-image: url(../../images/checkmark-dark.svg);
  background-size: contain;
}

div.route-select.onTablet {
  .toggle-tabs {
    .tabs-header-item.is-current:hover {
      background-color: transparent;
    }

    .tabs-header-item.is-current {
      background-color: transparent;
      border-bottom: 3px solid #F5BA16;
    }
    .tabs-header {
      box-shadow: none;
    }
    .tab-content {
      padding-top: 50px;
      background-color: transparent;
      height: 100%;
    }
  }
}


