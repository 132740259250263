body,
html {
	height: 100%;
	transition: .5s;
}

.row {
	max-width: 1440px;

	&.wide {
		max-width: 1640px;
	}

	&.narrow {
		max-width: 1240px;
	}

	// next to to .column
	.column-content-center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media (max-width: $breakpoint-small) {
		padding: 0 10px;
	}
}

.BrainhubCarousel {
	position: relative;
	.BrainhubCarousel__trackContainer {
		overflow: hidden;

		.BrainhubCarousel__track {
			display: flex;
			height: 300px;

			@media screen and (min-width: $breakpoint-small) {
				height: 400px;
			}

			@media screen and (min-width: $breakpoint-medium) {
				height: 600px;
			}

			@media screen and (min-width: $breakpoint-large) {
				height: 800px;
			}

			@media screen and (min-width: $breakpoint-huge) {
				height: 1000px;
			}

			.BrainhubCarouselItem {
				display: flex;
				img {
					width: 100%;
				}
			}
		}
	}
}

.BrainhubCarousel.buttons-overlay:before {
	position: absolute;
	content: '';
	display: block;
	background: rgba(20,20,20,.7);
	z-index: 1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.carousel-overlay {
	position: absolute;
	z-index: 8;
	width: 100%;
	// left: 50%;
	// -webkit-transform: translateX(-50%);
	// transform: translateX(-50%);

	.heading-cta {
		.vehicle-section {
			padding-bottom: 40px;

			.vehicle-section-header-wrapper {
				margin: 30px 10% 50px;
				border-bottom: 1px solid $color-white;

				@media (max-width: $breakpoint-medium) {
					margin-top: 10px;
					margin-bottom: 10px;
				}
			}

			.vehicle-section-header {
				display: inline-block;
				width: 290px;
				margin: 5px 5px 0 5px;
				padding-top: 15px;
				padding-bottom: 10px;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				background: #F2F2F2;
				font-weight: bold;
				text-transform: uppercase;
				cursor: pointer;
				opacity: 0.6;
				vertical-align: bottom;
				
				&-selected {
					background: $color-white;
					color: $color-primary;
					opacity: 1;
				}

				&:hover {
					background: $color-primary-hover;
					color: $color-white;
					opacity: 1;
				}

				@media (max-width: $breakpoint-small) {
					width: 178px;
					padding: 5px;
					font-size: 16px;
					height: 60px;
				}

				@media (max-width: $breakpoint-mobile) {
					width: 100px;
					font-size: 9px;
					padding: 6px;
					height: auto;
				}
			}
		}
	}

	@media (max-width: $breakpoint-medium) {
		.section-heading {
			margin-top: 90px;
		}

		.heading-cta {
			margin-top: 30px;
			button, a  {
				width: 250px;
				margin-top: 10px;
				font-size: 16px;
				max-height: 50px;
				padding-top: 5px;
			}
		}
	}

	@media (min-width: $breakpoint-small) {
		top: 100px;
		.heading-title {
			font-size: 45px;
		}
	}

	@media (min-width: $breakpoint-medium) {
		top: 200px;

		.heading-cta {
			button, a  {
				width: 400px;
			}
		}
	}

	@media (min-width: $breakpoint-huge) {
		top: 300px;
		
		.heading-title {
			font-size: 65px;
		}

		.heading-subtitle {
			font-size: 30px;
			margin-top: 20px;
		}

		.heading-cta { 
			button, a {
				width: 600px;
				margin: 0 40px;
				font-size: 30px;
			}
		}
		
	}

	h1, .button {
		color: $color-white;
		margin: 0 20px;
	}
}

p.margin-bottom-small {
	margin-bottom: 0.5em;
}

.phone-fields {
	display: flex;
	max-width: 100%;
	
	div:first-child {
		max-width: 180px;
		
		input {
			padding: 0;
    		text-align: center;
		}
	}
}

.contract-popup {
	.popup-box {
		width: 95%;
		height: 80%;
	}
}

div.popup.delict-popup {
	div.popup-wrapper {
		div.popup-box {
			padding: 30px 60px;
		}
	}
}

.rt-tr.rt-row-selected {
    background-color: $color-primary-hover !important;
}